import {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import styles from "./settingsBotomSection.module.scss";
import {useFormik} from "formik";
import * as Yup from "yup";
import PasswordInput from "@source/components/UI/inputs/passwordInput/passwordInput";
import FileInput from "@source/components/UI/inputs/fileInput/fileInput";
import UserController from "@source/api/controllers/userController";
import useFetching from "@source/hooks/useFetching";
import userStore from "@source/store/userStore";
import AuthController from "@source/api/controllers/authController";
import Button from "@source/components/UI/buttons/button/button";
import {observer} from "mobx-react-lite";
import fileController from "@source/api/controllers/fileController";
import Notification from "@components/snackMessage/notification";
import {Role} from "@source/utils/models/adminModels";
import {useFirebaseLogEvent} from "@source/utils/hooks/useFirebaseLogEvent";
import nursingHomeStore from "@store/nursingHomeStore";

const SettingsBottomSection = observer((props: SettingsBottomSectionProps) => {
  const [updateProfilePhoto, updateProfilePhotoLoading, updateProfilePhotoError] = useFetching<ReturnType<typeof UserController.updateProfilePhoto>>(UserController.updateProfilePhoto);
  const [uploadFile, isUploadFileLoading, errorFile] = useFetching<ReturnType<typeof fileController.uploadToS3>>(fileController.uploadToS3);
  const [deleteFile, isDeleteFileLoading, errorDeleteFile] = useFetching<ReturnType<typeof UserController.deleteFromS3>>(UserController.deleteFromS3);
  const [profile, isProfileLoading, profileError] = useFetching<ReturnType<typeof UserController.profile>>(UserController.profile);
  const [resetPassword, isPasswordLoading, passwordError] = useFetching<ReturnType<typeof AuthController.resetPassword>>(AuthController.resetPassword);
  
  const router = useHistory();
  const [logEvent] = useFirebaseLogEvent();

  const [file, setFile] = useState<File | null>(null);
  const [preview, setPreview] = useState<string | null>(null);

  useEffect(() => {
    setPreview(userStore.getUserInfo()?.photo || null)
  }, [userStore.loggedUser])

  const validateSchema = Yup.object({
    oldPassword: Yup.string().required("Old password is required"),
    newPassword: Yup.string()
      .max(32, "Must be less than 32 characters")
      .required("New password is required")
  });

  const mainFormik = useFormik<IChangePasswordForm>({
    validationSchema: validateSchema,
    validateOnChange: true,
    validateOnBlur: true, 
    initialValues: {
      oldPassword: '',
      newPassword: '',
    },
    onSubmit: async () => {
      await onPasswordReset();
      mainFormik.resetForm();
    }
  });

  const onSetFile = (file: File | null) => {
    setFile(file);

    if (file) {
      onUpload(file)
    }
  }

  const updateProfileInfo = async () => {
    const profileInfo = await profile();

    if(profileInfo) {
      userStore.setUserInfo(profileInfo)
    }
  }

  const onUpload = async (file: File) => {
    const fileTypeArr = file.type.split('/');
    const fileType = fileTypeArr[1] === "svg+xml" ? "svg" : fileTypeArr[1];

    const formData = new FormData();

    formData.append("photo", new Blob([file]), `${file.name}.${fileType}`);
    await updateProfilePhoto(formData);
    await updateProfileInfo();

    logEvent('settings__change_photo', {
      home: nursingHomeStore.getActiveNursingHome()?.name || "",
      organization: nursingHomeStore.getActiveNursingHome()?.organization.name || "",
    })
    new Notification().success("Photo has been edited");
  }

  const onDeleteFile = async () => {
    const res = await deleteFile();;
    await updateProfileInfo();

    logEvent('settings__delete_photo', {
      home: nursingHomeStore.getActiveNursingHome()?.name || "",
      organization: nursingHomeStore.getActiveNursingHome()?.organization.name || "",
    })
    new Notification().success("Photo has been deleted");
    return res;
  }

  const onPasswordReset = async () => {
    const passwordRes = await resetPassword(mainFormik.values.oldPassword, mainFormik.values.newPassword);

    if (passwordRes) {
      logEvent('settings__change_password', {
        home: nursingHomeStore.getActiveNursingHome()?.name || "",
        organization: nursingHomeStore.getActiveNursingHome()?.organization.name || "",
      })

      new Notification().success("Password has been changed");
    }

    if (typeof passwordRes === "undefined") {
      alert('Wrong password')
    }
  }

  const isAgencyNurse = () => {
    return userStore.getUserInfo()?.account.role === Role.AGENCY_NURSE;
  }

  const isAAC = () => {
    return userStore.getUserInfo()?.account?.organization?._id === '656ee31d478f7f2f67e91b5f';
  }

  const isManager = () => {
    return userStore.getUserInfo()?.account.role && [Role.HOME_ADMIN, Role.ORGANIZATION_ADMIN].includes(userStore.getUserInfo()!.account.role as Role)
  }

  const {errors, values, touched, handleBlur, handleChange, handleSubmit, setFieldValue} = mainFormik;

  return(
    <div className={styles.settingsBottomSection}>
      {
        isAgencyNurse() ?
          <></>
          :
          <div className={styles.passwords}>
            <span className={styles.title}>Change password</span>
            
            <form className={styles.passwordForm} onSubmit={handleSubmit}>
              <PasswordInput id="oldPassword" label="Old Password"
                className={styles.input}
                value={values.oldPassword} 
                handleChange={handleChange}
                eyeIcon={false}
                />

              <PasswordInput id="newPassword" label="New Password"
                className={styles.input}
                value={values.newPassword} 
                handleChange={handleChange}
                eyeIcon={false}
                />

              {
                mainFormik.values.oldPassword && 
                mainFormik.values.newPassword ?
                <Button
                  className={styles.btn}
                  size="small"
                  event="submit"
                  isLoading={isPasswordLoading}
                  >
                    Save changes
                  </Button>
                :
                <></>
              }
            </form>
          </div>
      }
      
      <div className={styles.photo}>
        {
          isAgencyNurse() ? 
            <span className={styles.title}>Photo</span>
            :
            <></>
        }
        {
          isAAC() && !isManager() ?
              <></>
              :
              <FileInput selectedFile={file} setSelectedFile={onSetFile} onDeleteFile={onDeleteFile} preview={preview} loading={updateProfilePhotoLoading || isUploadFileLoading || isProfileLoading || isDeleteFileLoading}/>
        }
      </div>
    </div>
  )
})

export default SettingsBottomSection;

interface SettingsBottomSectionProps {

}

export interface IChangePasswordForm {
  oldPassword: string,
  newPassword: string,
}

interface IInitialValue {
  name: string,
  surname: string,
  email: string,
  employeeId: string,
  role: string,
  position: string,
}