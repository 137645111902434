import { memo, useCallback, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import useFetching from "@source/hooks/useFetching";
import { LogoType, ROUTES } from "@source/utils/utils";
import TextInput from "@source/components/UI/inputs/textInput/textInput";
import Button from "@source/components/UI/buttons/button/button";
import authBackLines from "@source/assets/imgs/authBackLines.png";
import Logo from "@source/components/UI/logo/logo";
import Notification from "@source/components/snackMessage/notification";
import userStore from "@source/store/userStore";
import styles from "./ForgotPasswordPage.module.scss";
import EmployeeController from "@source/api/controllers/employeeController";
import { useHistory } from "react-router-dom";
import done from "@source/assets/imgs/done.png";
import { useFirebaseLogEvent } from "@source/utils/hooks/useFirebaseLogEvent";

const ForgotPasswordPage = memo(() => {
  const [forgot, isLoading, error] = useFetching<ReturnType<typeof EmployeeController.forgotPassword>>(EmployeeController.forgotPassword);
  const [isSend, setIsSend] = useState(false);
  const router = useHistory();
  const [logEvent] = useFirebaseLogEvent();

  const validateSchema = Yup.object({
    email: Yup.string().email("Email is invalid").required("Email is required"),
  });

  const formik = useFormik({
    validationSchema: validateSchema,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {
      email: '',
    },
    onSubmit: async (formValues) => {
      userStore.setLoginEmail(formValues.email);
      const response = await forgot(formValues);

      if(response) {
        logEvent('forgot_password__send_letter', {
          email: formValues.email,
        })
        new Notification().success('Success');
        setIsSend(true);
      }
    }
  });

  const onGoToLoginClick = useCallback(() => {
    router.push(ROUTES.LoginPage);
  }, [router])

  const {errors, values, touched, handleBlur, handleChange, handleSubmit} = formik;
  return (
    <div className={styles.forgot}>
      <div className={styles.forgot__inner}>
        <Logo className={styles.forgot__inner_logo} type={LogoType.White}/>

        <form className={styles.forgot__form} onSubmit={handleSubmit}>
          {!isSend
            ? <>
                <div className={styles.forgot__form_title}>Forgot password</div>
                <div className={styles.forgot__form_subTitle}>Please enter your email address and we’ll send you an email with a link to reset your password.</div>
              
                <TextInput id="email" label="Email"
                        placeholder="Enter your email"
                        className={styles.forgot__form_input}
                        value={values.email}
                        touched={touched.email}
                        error={errors.email} 
                        handleChange={handleChange} 
                        handleBlur={handleBlur}
                        spellCheck={false}
                />

                <Button event="submit" className={styles.forgot__form_btn} 
                      isLoading={isLoading}>Send email</Button>
              </>
            : <>
                <img className={styles.done} src={done} alt="done"/>
                <div className={styles.forgot__form_subTitle}>Please check your email for instructions to reset your password</div>
              </>}

          <div onClick={onGoToLoginClick} className={styles.goToLogin}>Back to login</div>
        </form>

      </div>

      <img className={styles.forgot_back} src={authBackLines} alt="lines"/>
    </div>
  )
})

export default ForgotPasswordPage;