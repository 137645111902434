import { useEffect } from "react";

interface IUpdatesLoadingOptions {
  nursingHomeId?: string;
  permission?: boolean;
  additionalRequestData?: any;
}

export default function useUpdatesLoading(request: any, setData: any, addData: any, options: IUpdatesLoadingOptions, page: number = 0) {
  const { nursingHomeId, permission, additionalRequestData } = options;

  useEffect(() => {
    if (permission) {
      let data: any = {
        nursingHomeId,
        page,
      };

      if (additionalRequestData) {
        data = { ...additionalRequestData, ...data }
      }

      request(data)
        .then(data => {
          if (page) {
            addData(data);
          } else {
            setData(data);
          }
        });
    }
  }, [page, nursingHomeId, additionalRequestData]);
}
