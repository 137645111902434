import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import styles from "./residentTab.module.scss";
import iconDownload from '@source/assets/icons/iconDownload.svg';
import Svg from "@source/components/UI/svg/svg";
import ResidentCardInfo from "./residentCardInfo/residentCardInfo";
import ResidentCardContacts from "./residentCardContacts/residentCardContacts";
import HistoryCard from "@source/components/historyCard/historyCard";
import useFetching from "@source/hooks/useFetching";
import ResidentController from "@source/api/controllers/residentController";
import {
  IDownloadResidentUpdateHistoryRequest,
  IGetResidentUpdatesResponse,
  ResidentIntegrationService
} from "@source/api/models/residentModel";
import nursingHomeStore from "@source/store/nursingHomeStore";
import Loader from "@source/components/UI/loader/loader";
import { observer } from "mobx-react-lite";
import residentsStore from "@source/store/residentsStore";
import userStore from "@store/userStore";
import { HomePermissionsEnum } from "@source/api/models/permissionsModels";
import BlockNewUpdatesModal from "@components/blockNewUpdatesModal/blockNewUpdatesModal";
import NewUpdatesDisabledModal from "@components/newUpdatesDisabledModal/newUpdatesDisabledModal";
import DownloadUpdateHistoryModal from "@components/downloadUpdateHistoryModal/downloadUpdateHistoryModal";
import FileSaver from 'file-saver';
import moment from "moment";
import { useFirebaseLogEvent } from "@source/utils/hooks/useFirebaseLogEvent";
import useUpdatesLoading from "@source/hooks/useUpdatesLoading";
import { DEFAULT_UPDATES_LIMIT } from "@source/api/services/residentService";
import {ContactTypeEnum} from "@source/api/models/allUsersModels";

const residentTab = observer((props: ResidentTabProps) => {
  const {} = props;
  const [getResidents, isGetResidentsLoading, getResidentEerror] = useFetching<ReturnType<typeof ResidentController.getResidents>>(ResidentController.getResidents);
  const [residentLoad, isResidentLoading, residentError] = useFetching<ReturnType<typeof ResidentController.getResident>>(ResidentController.getResident);
  const [getUpdates, isUpdateLoading, updateError] = useFetching<ReturnType<typeof ResidentController.getResidentUpdates>>(ResidentController.getResidentUpdates);
  const [downloadResidentUpdateHistory, downloadResidentUpdateHistoryLoading, downloadResidentUpdateHistoryError] = useFetching<ReturnType<typeof ResidentController.downloadResidentUpdateHistory>>(ResidentController.downloadResidentUpdateHistory);
  const { residentId } = useParams<{residentId: string}>();
  const router = useHistory();
  const [logEvent] = useFirebaseLogEvent();

  const [downloadUpdatesModal, setDownloadUpdatesModal] = useState(false)
  const [updatesPage, setUpdatesPage] = useState<number>(0);
  const [requestData, setRequestData] = useState<any>({
    nursingHomeId: nursingHomeStore.getId(),
    residentId,
  });
  
  useUpdatesLoading(
    getUpdates,
    (data: IGetResidentUpdatesResponse) => residentsStore.setActiveResidentData(data),
    (data: IGetResidentUpdatesResponse) => residentsStore.addActiveResidentData(data),
    {
      permission: userStore.isPermission(HomePermissionsEnum.VIEW_UPDATES),
      additionalRequestData: requestData,
    },
    updatesPage,
  );

  const getNewPageNumber = () => Math.ceil(residentsStore.activeResidentUpdates.length / DEFAULT_UPDATES_LIMIT);

  const getActiveResidentPrimaryContact = () => {
    return residentsStore.activeResident?.familyRelations.find((familyRelation) => familyRelation.contactType === ContactTypeEnum.primary);
  }

  useEffect(() => {
    if(residentId !== residentsStore?.activeResidentUpdates[0]?.resident?._id) {
      residentsStore.setActiveResidentData({ updates: [], totalCount: 0 })
    }
    let isMounted = true;
    
    const fetchResident = async () => {
      const residentsRes = await residentLoad({nursingHomeId: nursingHomeStore.getId(), residentId});

      if( residentsRes) {
        if (isMounted) {
          residentsStore.setActiveResident(residentsRes);
          if(
              !getActiveResidentPrimaryContact() &&
              residentsStore.activeResident?.integration?.hasPrimaryWithoutEmail &&
              residentsStore.activeResident?.integration?.service === ResidentIntegrationService.E_CASE
          ) {
            residentsStore.setModalBlockNewUpdatesResident(residentsStore.activeResident);
          }
        }
      }

      if(!residentsStore.getResidents()?.length) {
        const residentsRes = await getResidents({nursingHomeId: nursingHomeStore.getId()});

        if (residentsRes) {
          residentsStore.setResidents(residentsRes);
        }
      }
    }
    fetchResident();

    return () => {
      isMounted = false;
      residentsStore.setActiveResident(null);
    }
  }, [])

  useEffect(() => {
    document.addEventListener('scroll', scrollHandler, { capture: true });
    return function() {
      document.removeEventListener('scroll', scrollHandler, { capture: true });
    }
  }, [])
  
  const scrollHandler = (e) => {
    if (e.target.scrollHeight - (e.target.scrollTop + window.innerHeight) < 100 && residentsStore.activeResidentUpdates.length < residentsStore.activeResidentUpdatesTotalCount) {
      setUpdatesPage(getNewPageNumber());
    }    
  }

  const onTabUpdateDelete = async (updateId: string) => {
    residentsStore.deleteActiveResidentUpdates(updateId);
    setUpdatesPage(0);
  }

  const toggleDownloadUpdatesModal = () => {
    setDownloadUpdatesModal(!downloadUpdatesModal);
  }

  const onDownloadUpdateHistory = async (req: IDownloadResidentUpdateHistoryRequest) => {
    const downloadResidentUpdateHistoryRes = await downloadResidentUpdateHistory(nursingHomeStore.activeNursingHome?._id, residentsStore.activeResident?._id, req);

    if (downloadResidentUpdateHistoryRes) {
      logEvent('admin__residents__download_updates_history', {
        home: nursingHomeStore.getActiveNursingHome()?.name || "",
        organization: nursingHomeStore.getActiveNursingHome()?.organization.name || "",
      })
      
      let blob =  new Blob([downloadResidentUpdateHistoryRes], { type: req.type })

      FileSaver.saveAs(blob, `report_${moment().format("DD.MM.YYYY")}.${req.type}`)
    }
  }

  return(
    <div className={styles.residentTab}>
      {
        residentsStore.getModalBlockNewUpdatesResident() ?
        <BlockNewUpdatesModal/>
        :
        <></>
      }

      {
        residentsStore.getModalNewUpdatesDisabledResident() ?
        <NewUpdatesDisabledModal />
        :
        <></>
      }

      <DownloadUpdateHistoryModal open={downloadUpdatesModal} setOpen={toggleDownloadUpdatesModal} onDownload={onDownloadUpdateHistory} isLoading={downloadResidentUpdateHistoryLoading}/>

      <div className={styles.cards}>
        {isResidentLoading 
          ? 
          <div className={styles.loader}>
            <Loader width={50} height={50} borderWidth={4}/>
          </div>
          : residentsStore.activeResident 
            ? <>
                <ResidentCardInfo />
                <ResidentCardContacts />
              </>
            : <></>
        }
      </div>

      {
        userStore.isPermission(HomePermissionsEnum.VIEW_UPDATES) ?
        <div className={styles.history}>
          <div className={styles.header}>
            <span className={styles.title}>Update history</span>

            {
              userStore.isPermission(HomePermissionsEnum.DOWNLOAD_UPDATE_HISTORY) ?
                <div className={styles.downloadHistory} onClick={() => setDownloadUpdatesModal(!downloadUpdatesModal)}>
                  <Svg className={styles.icon} width={24} height={24} src={iconDownload} color="#333333"/>

                  <span className={styles.text}>Download history</span>
                </div>
                :
                <></>
              }
          </div>

          <div className={styles.list}>
            {
              residentsStore.activeResidentUpdates && residentsStore.activeResidentUpdates.map((update, key) => (
                <div className={styles.item} key={key}><HistoryCard residentStatus={residentsStore.getActiveResident()?.status} update={update} onTabUpdateDelete={onTabUpdateDelete}/></div>
              ))
            }
            {
              isUpdateLoading
                ? <div className={styles.loader}>
                    <Loader width={50} height={50} borderWidth={4}/>
                  </div> 
                : <></>
            }
          </div>
        </div>
        :
        <></>
      }
    </div>
  )
})

export default residentTab;

interface ResidentTabProps {

}
