import nursingHomeStore from '@source/store/nursingHomeStore';
import { HomeSocketEnum } from '@source/utils/enums';
import updatesStore from '@store/updatesStore';
import { useEffect, useRef } from 'react'; 
import io, { Socket } from "socket.io-client";
import residentsStore from '@store/residentsStore';

export const URL = process.env.REACT_APP_API_URL_SHORT;

export default function useSocketHome(): [()=> void] {
  const socketRef = useRef<Socket | null>(null)

  useEffect(() => {
    socketRef.current = io(URL as string, { path: "/api/websocket/home", auth: {
      token: `Bearer ${localStorage.getItem('accessToken')}`
    }});
  
    socketRef.current.onAny((event: HomeSocketEnum, data) => {
      if(event === HomeSocketEnum.CONNECT) {
      }
      
      if(event === HomeSocketEnum.UPDATE_HAS_BEEN_READ) {
        if (data) {
          residentsStore.markResidentUpdateRead(data);
          residentsStore.markArchiveResidentUpdateRead(data);
          updatesStore.markUpdateRead(data);
        }
      }
    });
  
    socketRef.current.on(HomeSocketEnum.ERROR, (data) => { console.log('error', data) });
    
    return () => {
      socketRef.current?.disconnect()
    }
  }, [])

  const onHome = () => {
    socketRef.current?.emit('home:connect', { homeId: nursingHomeStore.activeNursingHome?._id })
  }

  return [onHome];
}