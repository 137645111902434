import ReqError from "@source/api/reqError";
import { api } from "@source/api/http";
import {IGetSurveyRes, IPostSurveyReq, IPostSurveyRes} from "@source/api/models/surveyModels";

export default class SurveyService {
    static async getSurvey() {
        try {
            return (await api.get<IGetSurveyRes>("survey")).data
        } catch (e: any) {
            throw ReqError.getFamilyMembersError(e.response)
        }
    }

    static async updateSurveyStatus(req: IPostSurveyReq) {
        try {
            return (await api.post<IPostSurveyRes>(`survey/submit`, req)).data
        } catch (e: any) {
            throw ReqError.getFamilyMembersError(e.response)
        }
    }
}