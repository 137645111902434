import ReqError from "@source/api/reqError";
import { api } from "@source/api/http";
import {
  IResetPasswordRequest,
  IChangeEmailRequest,
  IResetPasswordResponse,
  IChangeEmailResponse
} from "../models/familyMemberModels";

export default class FamilyMemberService {
  static async resetPassword(body: IResetPasswordRequest) {
    try {
      return(await api.post<IResetPasswordResponse>("auth/family-member/forgot-password/reset", body)).data;
    } catch (e: any) {
      throw ReqError.resetFamilyMemberPasswordError(e.response)
    }
  }

  static async changeEmail(body: IChangeEmailRequest, familyMemberId: string) {
    try {
      return(await api.post<IChangeEmailResponse>(`auth/family-member/${familyMemberId}/change-email`, body)).data;
    } catch (e: any) {
      throw ReqError.changeFamilyMemberEmailError(e.response)
    }
  }
}