import { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import styles from "./HomePage.module.scss";
import SideBar from "@source/pages/HomePage/sideBar/sideBar";
import HomeRouter from "@source/pages/HomePage/homeRouter/homeRouter";
import { observer } from "mobx-react-lite";
import AppHeader from "@source/components/appHeader/appHeader";
import useFetching from "@source/hooks/useFetching";
import UserController from "@source/api/controllers/userController";
import userStore from "@source/store/userStore";
import NursingHomeController from "@source/api/controllers/nursingHomeController";
import nursingHomeStore from "@source/store/nursingHomeStore";
import { api } from "@source/api/http";
import { IGetAvailableNursingHomesRequest, IGetNursingHomesRequest } from "@source/api/models/nursingHomeModels";
import ExpiredModal from "@components/expiredModal/expiredModal";
import { Role } from "@source/utils/models/adminModels";
import { ROUTES, SUPER_ADMIN_PANEL_ROUTES } from "@source/utils/utils";
import NewResidentsModal from "@components/newResidentsModal/newResidentsModal";
import PopupSurvey from "@components/popupSurvey/popupSurvey";

const HomePage = observer((props: HomePageProps) => {
  const {} = props;

  // const [onHome] = useSocketHome();
  
  const [isSideBar, setIsSideBar] = useState(true);
  const router = useHistory();
  
  const toggleSideBar = useCallback(() => {
    setIsSideBar(!isSideBar);
  }, [isSideBar])

  const [profile, isLoading, error] = useFetching<ReturnType<typeof UserController.profile>>(UserController.profile);
  const [setTimezone, setTimezoneIsLoading,setTimezoneError] = useFetching<ReturnType<typeof UserController.setTimezone>>(UserController.setTimezone);
  const [getAvailableNursingHomes, getAvailableNursingHomesIsLoading, getAvailableNursingHomesError] = useFetching<ReturnType<typeof NursingHomeController.getAvailableNursingHomes>>(NursingHomeController.getAvailableNursingHomes);
  // useEffect(() => {
  //   setTimeout(() => {
  //     userStore.setExpiredModal(true)
  //   }, 0)
  // }, [])

  useEffect(() => {
    const refreshToken = localStorage.getItem('refreshToken');

    const getProfile = async () => {
      const profileInfo = await profile();

      if(profileInfo) {
        userStore.setUserInfo(profileInfo);
        
        if (profileInfo?.account.role === Role.SUPER_ADMIN) {
          if (userStore.getLocalStorageOrganizationId()) {
            userStore.setOrganizationId(userStore.getLocalStorageOrganizationId());
          } else {
            router.push(SUPER_ADMIN_PANEL_ROUTES.Organisations);
          }
        } else {
          userStore.setOrganizationId(profileInfo.account.organization._id);
        }
      }
  
      if (userStore.getLocalStorageOrganizationId() || profileInfo?.account.role !== Role.SUPER_ADMIN) {
        const getAvailableNursingHomesReq: IGetAvailableNursingHomesRequest = {
          organizationId: userStore.getOrganizationId(), 
        }
  
        const availableNursingHomesRes = await getAvailableNursingHomes(getAvailableNursingHomesReq);
  
        if (availableNursingHomesRes) {
          nursingHomeStore.setNursingHomes({
            nursingHomes: availableNursingHomesRes,
            totalCount: availableNursingHomesRes.length
          });
    
          const activeNursingHome = availableNursingHomesRes.find((userNursingHome) => userNursingHome._id === nursingHomeStore.getLocalStorageActiveNursingHome()) || availableNursingHomesRes[0];
          nursingHomeStore.setActiveNursingHome(activeNursingHome);
        
          if (nursingHomeStore.activeNursingHome) {
            api.defaults.params = {
              ...api.defaults.params,
              nursingHomeId: nursingHomeStore.activeNursingHome._id,
            }
          }
        }
    
        await setTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone)
      }
    }

    if(refreshToken && !userStore.getId()) {
      getProfile();
    }
  }, [])

  return(
    <div className={styles.home}>
      <PopupSurvey />
      {
        userStore.getExpiredModal() ?
          <ExpiredModal/>
          :
          <></>
      }
      <NewResidentsModal/>

      <div className={styles.content}>
        <div className={`${styles.sideBar} ${!isSideBar && styles.sideBar_hidden}`}>
          <SideBar open={isSideBar} toggleSideBar={toggleSideBar}/>
        </div>
        
        <div className={`${styles.routerOutlet} ${!isSideBar && styles.routerOutlet_hidden}`}>
          <div className={styles.header}>
            <AppHeader/>
          </div>

          <div className={styles.router}>
            {
              isLoading && getAvailableNursingHomesIsLoading && !userStore.getUserInfo() && !nursingHomeStore.nursingHomes.length ?
              <></>
              :
              <HomeRouter/>
            }
          </div>
        </div>
      </div>
    </div>
  )
})

export default HomePage;

interface HomePageProps {

}