import { useCallback, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import useFetching from "@source/hooks/useFetching";
import { useHistory } from "react-router-dom";
import { HOME_ROUTES, LogoType, ROUTES, SUPER_ADMIN_PANEL_ROUTES } from "@source/utils/utils";
import styles from "./LoginPage.module.scss";
import TextInput from "@source/components/UI/inputs/textInput/textInput";
import PasswordInput from "@source/components/UI/inputs/passwordInput/passwordInput";
import Button from "@source/components/UI/buttons/button/button";
import authBackLines from "@source/assets/imgs/authBackLines.png";
import Logo from "@source/components/UI/logo/logo";
import AuthController from "@source/api/controllers/authController";
import { ILogInRequest } from "@source/api/models/authModels";
import Notification from "@source/components/snackMessage/notification";
import userStore from "@source/store/userStore";
import InteractionObserver from "@source/api/interactionObserver";
import UserController from "@source/api/controllers/userController";
import NursingHomeController from "@source/api/controllers/nursingHomeController";
import { HomeStatus, IGetAvailableNursingHomesRequest, IGetNursingHomesRequest } from "@source/api/models/nursingHomeModels";
import nursingHomeStore from "@store/nursingHomeStore";
import { api } from "@source/api/http";
import LoginTechSupportModal from "@components/loginTechSupportModal/loginTechSupportModal";
import LoginTechSupportSentModal from "@components/loginTechSupportSentModal/loginTechSupportSentModal";
import { Role } from "@source/utils/models/adminModels";
import { observer } from "mobx-react-lite";
import { LOCAL_STORAGE_FROM_AGENCY_SET_PASSWORD_PAGE } from "@source/utils/models/userModels";
import { AuthActions } from "@source/api/models/userModels";
import { useFirebaseLogEvent } from "@source/utils/hooks/useFirebaseLogEvent";
import LoginTwoFactorAuthModal from "@components/loginTwoFactorAuthModal/loginTwoFactorAuthModal";

interface ILogInForm extends ILogInRequest {

}

const LoginPage = observer((props: LoginPageProps) => {
  const {} = props;
  const [login, isLoading, error] = useFetching<ReturnType<typeof AuthController.login>>(AuthController.login);
  const [profile, profileIsLoading, profileError] = useFetching<ReturnType<typeof UserController.profile>>(UserController.profile);
  const [setTimezone, setTimezoneIsLoading,setTimezoneError] = useFetching<ReturnType<typeof UserController.setTimezone>>(UserController.setTimezone);
  const [getAvailableNursingHomes, getAvailableNursingHomesIsLoading, getAvailableNursingHomesError] = useFetching<ReturnType<typeof NursingHomeController.getAvailableNursingHomes>>(NursingHomeController.getAvailableNursingHomes);
  
  const router = useHistory();
  const [logEvent] = useFirebaseLogEvent();

  const validateSchema = Yup.object({
    email: Yup.string().email("Email is invalid").required("Email is required"),
    password: Yup.string()
      .max(32, "Must be less than 32 characters")
      .required("Password is required")
  });

  const [techSupportModal, setTechSupportModal] = useState(false);
  const [techSupportSentModal, setTechSupportSentModal] = useState(false);
  const [twoFactorModal, setTwoFactorModal] = useState(false);

  const getProfile = async () => {
    const profileInfo = await profile();

    if(profileInfo) {
      userStore.setUserInfo(profileInfo);
      
      if (profileInfo?.account.role !== Role.SUPER_ADMIN) {
        userStore.setOrganizationId(profileInfo.account.organization._id);
      }
    }

    if (profileInfo?.account.role !== Role.SUPER_ADMIN) {
      const getAvailableNursingHomesReq: IGetAvailableNursingHomesRequest = {
        organizationId: userStore.getOrganizationId(), 
      }

      const availableNursingHomesRes = await getAvailableNursingHomes(getAvailableNursingHomesReq);

      if (availableNursingHomesRes) {
        nursingHomeStore.setNursingHomes({
          nursingHomes: availableNursingHomesRes,
          totalCount: availableNursingHomesRes.length
        });
  
        const activeNursingHome = availableNursingHomesRes.find((userNursingHome) => userNursingHome._id === nursingHomeStore.getLocalStorageActiveNursingHome()) || availableNursingHomesRes[0];
        nursingHomeStore.setActiveNursingHome(activeNursingHome);
      
        if (nursingHomeStore.activeNursingHome) {
          api.defaults.params = {
            ...api.defaults.params,
            nursingHomeId: nursingHomeStore.activeNursingHome._id,
          }
        }
      }

  
      await setTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone)
    }
  }

  const formik = useFormik<ILogInForm>({
    validationSchema: validateSchema,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {
      email: '',
      password: ''
    },
    onSubmit: async (formValues) => {
      userStore.setLoginEmail(formValues.email);
      const response = await login(formValues);

      if(response) {
        if (response.action === AuthActions.LOGIN_AS_AGENCY) {
          logEvent('agency_login__login_as_agency', {
            email: userStore.getUserInfo()?.email || "",
          })

          userStore.setAgencyUserEmailPassword(formValues);
          router.push(ROUTES.AgencyLoginNamePage);
        } else if (response.action === AuthActions.NEED_ENABLE_MFA) {
          setTwoFactorModal(true);
        }
        else if (response.action === AuthActions.MFA_AUTH) {
          userStore.setMfaLogin({...formValues});
          router.push(ROUTES.TwoFactorLoginPage)
        } else {
          localStorage.setItem("refreshToken", response.refreshToken);
          localStorage.setItem("accessToken", response.accessToken);

          await getProfile();
          new Notification().success('You’re successfully logged in');
          const isFromSetPasswordPage = localStorage.getItem(LOCAL_STORAGE_FROM_AGENCY_SET_PASSWORD_PAGE);
  
          if (userStore.getUserInfo()?.account.role === Role.SUPER_ADMIN) {
            logEvent('organization_admin__login', {
              email: userStore.getUserInfo()?.email || "",
            })

            router.push(SUPER_ADMIN_PANEL_ROUTES.Organisations);
          } else {
            logEvent('login', {
              email: userStore.getUserInfo()?.email || "",
              organization: userStore.getUserInfo()?.account.organization.name || "",
            })

            if (isFromSetPasswordPage) {
              router.push(isFromSetPasswordPage)
              localStorage.removeItem(LOCAL_STORAGE_FROM_AGENCY_SET_PASSWORD_PAGE);
            } else {
              router.push(HOME_ROUTES.Residents);
            }
          }

          new InteractionObserver();
          userStore.resetLoginEmail();
        }
      }
    }
  });

  const onForgotPasswordClick = useCallback(() => {
    router.push(ROUTES.ForgotPasswordPage);
  }, [router])

  const onNeedHelp = () => {
    setTechSupportModal(!techSupportModal);
  }

  const {errors, values, touched, handleBlur, handleChange, handleSubmit} = formik;
  return(
    <div className={styles.login}>
      <LoginTechSupportModal open={techSupportModal} setOpen={() => {setTechSupportModal(!techSupportModal)}}  setSentModal={() => {setTechSupportSentModal(!techSupportSentModal)}}/> 
      <LoginTechSupportSentModal open={techSupportSentModal} setOpen={() => {setTechSupportSentModal(!techSupportSentModal)}}/> 
      <LoginTwoFactorAuthModal open={twoFactorModal} setOpen={() => {setTwoFactorModal(!twoFactorModal)}} email={values.email}/> 
      
      <div className={styles.login__inner}>
        <Logo className={styles.login__inner_logo} type={LogoType.White}/>

        <form className={styles.login__form} onSubmit={handleSubmit}>
          <div className={styles.login__form_title}>Welcome to Brenna!</div>
          {/* <div className={styles.login__form_subTitle}>
            You’ve been invited to the Brenna App.<br/>
            Please create password.
          </div> */}
        
          <TextInput id="email" label="Email"
                  placeholder="Enter your email"
                  className={styles.login__form_input}
                  value={values.email}
                  touched={touched.email}
                  error={errors.email} 
                  handleChange={handleChange} 
                  handleBlur={handleBlur} spellCheck={false}/>

          <PasswordInput id="password" label="Password"
                  placeholder="Enter your password"
                  className={styles.login__form_input}
                  value={values.password} 
                  touched={touched.password}
                  error={errors.password}
                  handleChange={handleChange} 
                  handleBlur={handleBlur}/>

          <Button event="submit" className={styles.login__form_btn} 
                isLoading={isLoading || setTimezoneIsLoading || profileIsLoading || getAvailableNursingHomesIsLoading}>Login</Button>

          <div onClick={onForgotPasswordClick} className={styles.forgot}>Forgot password?</div>
        </form>

        <span className={styles.login__needHelp} onClick={onNeedHelp}>Need help? Click here</span>
      </div>

      <img className={styles.login_back} src={authBackLines} alt="lines"/>
    </div>
  )
})

export default LoginPage;

interface LoginPageProps {

}