import React, { ChangeEvent, MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell , { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import styles from "./selectOrganizationTable.module.scss";
import useFetching from '@source/hooks/useFetching';
import nursingHomeStore from '@source/store/nursingHomeStore';
import Loader from '@source/components/UI/loader/loader';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { HOME_ROUTES } from '@source/utils/utils';
import { Order } from '@source/utils/models/adminModels';
import EnhancedTableHead from './tableHead/tableHead';
import NursingHomeController from '@source/api/controllers/nursingHomeController';
import { HeadCellOrganizations, IGetAvailableNursingHomesRequest, IGetOrganizationsReq, IGetOrganizationsResponse, IOrganization } from '@source/api/models/nursingHomeModels';
import userStore from '@store/userStore';
import { api } from "@source/api/http";
import { LOCAL_STORAGE_FROM_AGENCY_SET_PASSWORD_PAGE } from '@source/utils/models/userModels';
import { useFirebaseLogEvent } from '@source/utils/hooks/useFirebaseLogEvent';
import EnhancedTableToolbar from './tableToolbar/tableToolbar';
import {IResident} from "@source/api/models/residentModel";

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  whiteSpace: "nowrap",
  fontFamily: "Poppins",
  fontSize: "14px",
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#fffff',
    color: "#333333",
    lineHeight: '24px',
  },
  [`&.${tableCellClasses.body}`]: {
    lineHeight: '32px',
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: "#ffffff",
  'th': {
    padding: '16px 16px',
  },
  'td': {
    borderBottom: '1px solid #F2F4F8',
    padding: '16px 16px',
  },
  '&:last-child td, &:last-child th': {
    border: '0',
  },
}));

export const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  height: "620px",
  borderRadius: "20px",
  '&::-webkit-scrollbar': {
    backgroundColor: '#ffffff',
    width: '6px',
    height: '6px'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#DDE2F2',
    borderRadius: "5px",
  },
}));

const SelectOrganizationTable = observer(() => {
  const headCells: HeadCellOrganizations[] = useMemo(() => {
    return [
        {
          id: 'name',
          disablePadding: false,
          label: 'Name',
        },
        {
          id: 'description',
          disablePadding: false,
          label: 'Description',
        },
    ];
  }, []);

  const [organizations, setOrganizations] = useState<IOrganization[]>([]);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof Omit<IOrganization, 'integration'>>("name");
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');
  const [isFiltersDirty, setIsFiltersDirty] = useState(false);

  const [getOrganizations, getOrganizationsIsLoading, getOrganizationsError] = useFetching<ReturnType<typeof NursingHomeController.getOrganizations>>(NursingHomeController.getOrganizations);
  const [getAvailableNursingHomes, getAvailableNursingHomesIsLoading, getAvailableNursingHomesError] = useFetching<ReturnType<typeof NursingHomeController.getAvailableNursingHomes>>(NursingHomeController.getAvailableNursingHomes);

  const router = useHistory();
  const focusRef = useRef<HTMLTableRowElement>(null);
  const [logEvent] = useFirebaseLogEvent();

  useEffect(() => {
    async function getOrganizationsFunc() {
      const getOrganizationsReq: IGetOrganizationsReq = {
        searchString: search,
        limit: 25,
        page: page,
      }

      const getOrganizationsRes = await getOrganizations(getOrganizationsReq);

      if (getOrganizationsRes) {
        setOrganizations(getOrganizationsRes.organizations);
      }
    }

    getOrganizationsFunc();

    return () => {
    }
  }, [])

  useEffect(() => {
    async function getOrganizationsFunc() {
      const getOrganizationsReq: IGetOrganizationsReq = {
        searchString: search,
        limit: 25,
        page: 0,
      }
      
      setPage(0);

      const getOrganizationsRes = await getOrganizations(getOrganizationsReq);

      if (getOrganizationsRes) {
        setOrganizations(getOrganizationsRes.organizations);
      }
    }

    if (search || isFiltersDirty) {
      const timer = setTimeout(() => {
        getOrganizationsFunc();
      }, 500)
  
      return () => {
        clearTimeout(timer)
      }
    }
  }, [search])

  const onSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);

    if (!isFiltersDirty) {
      setIsFiltersDirty(true);
    }
  }, [isFiltersDirty])
  
  const descendingComparator = useCallback(function <T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }, []);

  type getComparatorType<Key extends keyof any> = (a: { [key in Key]: number | string | boolean }, b: { [key in Key]: number | string | boolean }) => number;
  
  const getComparator = useCallback(function<Key extends keyof any>(order: Order, orderBy: Key): getComparatorType<Key> {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }, []);

  const handleRequestSort = useCallback((event: MouseEvent<unknown>, property: keyof IOrganization) => {
    if(property !== "_id" && property !== 'integration') {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    }
  }, [orderBy, order]);

  const handleChangePage = async (event: unknown, newPage: number) => {
    if (getOrganizationsIsLoading) {
      return;
    }

    setPage(newPage);

    const getOrganizationsReq = {
      searchString: search,
      limit: 25,
      page: newPage,
    }

    const getOrganizationsRes = await getOrganizations(getOrganizationsReq);

    if (getOrganizationsRes) {
      setOrganizations(getOrganizationsRes.organizations);
    }
  };

  const createSortHandler = (property: keyof IOrganization) => (event: React.MouseEvent<unknown>) => {
    handleRequestSort(event, property);
  };

  const onSelectOrganization = async (organization: IOrganization) => {
    userStore.setOrganizationId(organization._id);
    await getNursingHomes();

    logEvent('organization_admin__select_organization', {
      organization: organization.name,
    })

    const isFromSetPasswordPage = localStorage.getItem(LOCAL_STORAGE_FROM_AGENCY_SET_PASSWORD_PAGE);

    if (isFromSetPasswordPage) {
      router.push(isFromSetPasswordPage)
      localStorage.removeItem(LOCAL_STORAGE_FROM_AGENCY_SET_PASSWORD_PAGE)
    } else {
      router.push(HOME_ROUTES.Residents);
    }
  }

  const getNursingHomes = async () => {
    const getAvailableNursingHomesReq: IGetAvailableNursingHomesRequest = {
      organizationId: userStore.getOrganizationId(), 
    }

    const availableNursingHomesRes = await getAvailableNursingHomes(getAvailableNursingHomesReq);

    if (availableNursingHomesRes) {
      nursingHomeStore.setNursingHomes({
        nursingHomes: availableNursingHomesRes,
        totalCount: availableNursingHomesRes.length
      });

      const activeNursingHome = availableNursingHomesRes.find((userNursingHome) => userNursingHome._id === nursingHomeStore.getLocalStorageActiveNursingHome()) || availableNursingHomesRes[0];
      nursingHomeStore.setActiveNursingHome(activeNursingHome);
    
      if (nursingHomeStore.activeNursingHome) {
        api.defaults.params = {
          ...api.defaults.params,
          nursingHomeId: nursingHomeStore.activeNursingHome._id,
        }
      }
    }
  }
  
  return (
    <div className={styles.adminTable}>
      <EnhancedTableToolbar
        // numSelected={selected.length} 
        search={search} 
        rowsTotalCount={nursingHomeStore.adminNursingHomesTotalCount}
        onSearchChange={onSearchChange}
      />

      <StyledTableContainer>
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
        >
          <EnhancedTableHead
            // numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            // onSelectAllClick={handleSelectAllClick}
            rowCount={nursingHomeStore.adminNursingHomes.length}
            createSortHandler={createSortHandler}
            headCells={headCells}
          />

          <TableBody>
            {!getOrganizationsIsLoading && organizations ? 
              <>
                {organizations
                  .slice()
                  .sort(getComparator(order, orderBy))
                  .map((row, index) => {
                    return (
                      <StyledTableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row._id}
                        onClick={() => onSelectOrganization(row)}
                      >
                        <StyledTableCell>{row.name}</StyledTableCell>
                        <StyledTableCell>{row.description}</StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
              </>
              :
              <StyledTableRow>
                <StyledTableCell colSpan={1} rowSpan={11} align="center">
                  <div className={styles.loader}>
                    <Loader width={40} height={40} borderWidth={4}/>
                  </div>
                </StyledTableCell>
              </StyledTableRow>
            }
            </TableBody>
        </Table>
      </StyledTableContainer>

      <TablePagination
        className={styles.pagination}
        rowsPerPageOptions={[]}
        component="div"
        count={organizations.length}
        rowsPerPage={25}
        page={page} 
        onPageChange={handleChangePage}
      />
    </div>
  );
});

export default SelectOrganizationTable;

