import {useCallback, useEffect, useMemo, useState} from "react";
import { useHistory, useLocation } from "react-router-dom";
import styles from "./settingsTab.module.scss";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextInput from "@source/components/UI/inputs/textInput/textInput";
import SettingsBottomSection from "./settingsBotomSection/settingsBotomSection";
import userStore from "@source/store/userStore";
import Button from "@source/components/UI/buttons/button/button";
import useFetching from "@source/hooks/useFetching";
import UserController from "@source/api/controllers/userController";
import { observer } from "mobx-react-lite";
import iconConfirmed from '@source/assets/icons/iconConfirmed.svg';
import Notification from "@components/snackMessage/notification";
import { IUpdateProfileInfoReq } from "@source/api/models/userModels";
import { Role, RoleName } from "@source/utils/models/adminModels";
import { useFirebaseLogEvent } from "@source/utils/hooks/useFirebaseLogEvent";
import nursingHomeStore from "@store/nursingHomeStore";
import AutocompleteInput, {ISelectItem} from "@components/UI/inputs/autocompleteInput/autocompleteInput";

const SettingsTab = observer((props: SettingsTabProps) => {
  const updateItems: Array<ISelectItem> = useMemo(() => (
      userStore.getUserInfo()?.account?.nursingHomes?.map((nursingHome) => {
        return {
          id: nursingHome._id,
          title: nursingHome.name,
          value: nursingHome._id
        }
      }) || []
  ), [userStore.organizationId]);

  const getItems = useCallback(() => {
    return updateItems.map((item) => item.title)
  }, [])

  const [updateProfile, isUpdateProfileLoading, profileError] = useFetching<ReturnType<typeof UserController.updateProfile>>(UserController.updateProfile);
  const [profile, isLoading, error] = useFetching<ReturnType<typeof UserController.profile>>(UserController.profile);
  const [selectedPriorityHome, setSelectedPriorityHome] = useState<{ _id: string, name: string } | null>(userStore.getUserInfo()?.account.nursingHomes[0]!);

  const [autocompleteInputValue, setAutocompleteInputValue] = useState('');
  const [autocompleteInputItems, setAutocompleteInputItems] = useState(updateItems);

  const router = useHistory();
  const location = useLocation();
  const [logEvent] = useFirebaseLogEvent();

  const initialValue = userStore.getUserInfo();

  const validateSchema = Yup.object({
    firstName: Yup.string().required("Name is required"),
    lastName: Yup.string().required("Surname is required"),
  });

  const mainFormik = useFormik<ISettingProfileForm>({
    validationSchema: validateSchema,
    validateOnChange: true,
    validateOnBlur: true, 
    initialValues: {
      firstName: initialValue?.firstName || '',
      lastName: initialValue?.lastName || '',
      email: initialValue?.email || '',
      phoneNumber: initialValue?.phoneNumber || '',
      role: initialValue?.account.role || '',
      position: initialValue?.account.position || '',
      priorityHome: initialValue?.account?.nursingHomes[0]!,
    },
    onSubmit: async () => {
      await onUpdateProfile();
    }
  });

  useEffect(() => {
    const getProfile = async () => {
      const profileInfo = await profile();

      if(profileInfo) {
        const {firstName, lastName, email, account, phoneNumber} = profileInfo;
        userStore.setUserInfo(profileInfo);

        mainFormik.setValues({
          firstName,
          lastName,
          email,
          phoneNumber,
          priorityHome: account?.priorityHome || account?.nursingHomes[0],
          role: account.role,
          position: account.position,
        })
      }
    }

    if (!initialValue) {
      getProfile();
    }
  }, [])

  const onUpdateProfile = async () => {
    const updateProfileInfo: IUpdateProfileInfoReq = {
      firstName: mainFormik.values.firstName, 
      lastName: mainFormik.values.lastName,
      phoneNumber: mainFormik.values.phoneNumber,
      accountInfo: {
        priorityHome: selectedPriorityHome?._id,
      }
    }
    
    const updateRes = await updateProfile(updateProfileInfo);

    if (updateRes) {
      logEvent('settings__change_info', {
        home: nursingHomeStore.getActiveNursingHome()?.name || "",
        organization: nursingHomeStore.getActiveNursingHome()?.organization.name || "",
      })

      new Notification().success("Profile info has been edited");
      userStore.setUserInfo(updateRes);
      setSelectedPriorityHome(userStore.getUserInfo()?.account?.priorityHome || userStore.getUserInfo()?.account?.nursingHomes[0]!)
    }
  }

  const isShowSaveChangesButton = () => {
    const priorityHome = userStore.getUserInfo()?.account?.priorityHome?._id || userStore.getUserInfo()?.account?.nursingHomes[0]?._id;
    return mainFormik.values.firstName !== userStore.getUserInfo()?.firstName ||
      mainFormik.values.lastName !== userStore.getUserInfo()?.lastName ||
      mainFormik.values.phoneNumber !== userStore.getUserInfo()?.phoneNumber ||
        selectedPriorityHome?._id !== priorityHome
  }

  const isAgencyNurse = () => {
    return initialValue?.account.role === Role.AGENCY_NURSE
  }

  const isSuperAdmin = () => {
    return initialValue?.account.role === Role.SUPER_ADMIN
  }

  const onAutocompleteInputInternalInputChange = useCallback((event: any, newInputValue: string) => {
    if (!newInputValue) {
      setAutocompleteInputItems(updateItems);
    }

    setAutocompleteInputValue(newInputValue)
  }, [getItems, setAutocompleteInputItems, setAutocompleteInputValue]);

  const onAutocompleteIputChange = useCallback((event: any, newValue: ISelectItem | null) => {
    const foundedHome = userStore.getUserInfo()?.account?.nursingHomes?.find((home) => home._id === newValue?.value)

    setSelectedPriorityHome(foundedHome || null);
  }, [])

  const {errors, values, touched, handleBlur, handleChange, handleSubmit, setFieldValue} = mainFormik;

  return(
    <div className={styles.settings}>
      <form className={styles.profileForm} onSubmit={handleSubmit}>
          <div className={styles.row}>
            <TextInput id="firstName" label="Name"
              placeholder="Name"
              className={styles.input}
              value={values.firstName} 
              handleChange={handleChange}
              disabled={isAgencyNurse()}
            />

            <TextInput id="lastName" label="Surname"
              placeholder="Surname"
              className={styles.input}
              value={values.lastName} 
              handleChange={handleChange}
              disabled={isAgencyNurse()}
            />
          </div>

          {
            isAgencyNurse() ?
              <></>
              :
              <div className={styles.row}>
                <div className={styles.email}>
                  <TextInput id="email" label="Email"
                    className={`${styles.input} ${styles.input_disabled}`}
                    value={values.email} 
                    handleChange={handleChange} disabled spellCheck={false}/>

                    {
                      initialValue?.isConfirmed ?
                      <img className={styles.confirmedIcon} src={iconConfirmed} alt="confirmed"/>
                      :
                      <></>
                    }
                </div>

                <TextInput id="phoneNumber" label="Mobile Number"
                  className={`${styles.input} ${styles.input_disabled}`}
                  value={values.phoneNumber} 
                  handleChange={handleChange} spellCheck={false}/>
              </div>
          }


          <div className={styles.row}>
            {
              isAgencyNurse() ?
                <TextInput id="registrationNumber" label="Registration number"
                className={`${styles.input} ${styles.input_disabled}`}
                value={initialValue?.account.agencyInfo?.registrationNumber} 
                handleChange={handleChange} disabled spellCheck={false}/>
              :
                <TextInput id="role" label="Role"
                  className={`${styles.input} ${styles.input_disabled}`}
                  value={RoleName[values.role]} 
                  handleChange={handleChange} disabled spellCheck={false}/>

            }

            <TextInput id="position" label="Position"
              className={`${styles.input} ${styles.input_disabled}`}
              value={values.position} 
              handleChange={handleChange} disabled/>
          </div>

        {
          !isSuperAdmin() ?
              <div className={styles.row}>
                <AutocompleteInput id="selectedPriorityHome" label="Priority Home"
                                   placeholder="Select one or type something"
                                   className={`${styles.input} ${styles.input_disabled}`}
                                   items={updateItems}
                                   value={selectedPriorityHome ? updateItems.find((updateItem) => updateItem.value === selectedPriorityHome._id) || null : null}
                                   handleChange={onAutocompleteIputChange}
                                   inputValue={autocompleteInputValue}
                                   inputItems={updateItems}
                                   onInputChange={onAutocompleteInputInternalInputChange}
                />
              </div>
              :
              <></>
        }

          {
            isShowSaveChangesButton() && !isAgencyNurse() ?
            <div className={`${styles.row} ${styles.row_btn}`}>
              <Button 
                className={styles.newUpdateBtn}
                size="small"
                event="submit"
                isLoading={isUpdateProfileLoading}
              >
                Save changes
              </Button>
            </div>
            :
            <></>
          }
      </form>

      {
        isAgencyNurse() ?
          <></>
          :
          <div className={styles.line}/>
      }

      <div className={styles.bottomSection}>
        <SettingsBottomSection />
      </div>
    </div>
  )
});

export default SettingsTab;

interface SettingsTabProps {

}

export interface ISettingProfileForm {
  firstName: string,
  lastName: string,
  email: string,
  phoneNumber: string,
  role: string,
  position: string,
  priorityHome: {
    _id: string;
    name: string;
  }
}