
import Svg from "@source/components/UI/svg/svg";
import styles from "./snackbarMessage.module.scss";
import doneIcon from "@source/assets/icons/iconDone.svg";
import iconCross from "@source/assets/icons/iconCross.svg";
import attentionIcon from "@source/assets/icons/iconAttention.svg";
import { Colors } from "@source/utils/utils";

// do not use react hooks inside
const SnackbarMessage = (props: SnackbarItemProps) => {
  const {message, status} = props;

  const icons = {
    [SnackStatus.SUCCESS]: doneIcon,
    [SnackStatus.ERROR]: iconCross,
    [SnackStatus.WARNUNG]: attentionIcon,
  }

  const colors = {
    [SnackStatus.SUCCESS]: Colors.notificationSuccess,
    [SnackStatus.ERROR]: Colors.notificationError,
    [SnackStatus.WARNUNG]: Colors.notificationWarning,
  }

  const statusName = {
    [SnackStatus.SUCCESS]: 'Done!',
    [SnackStatus.ERROR]: 'Error',
    [SnackStatus.WARNUNG]: 'Warning',
  }

  return(
    <div className={styles.message}>
      <div style={{backgroundColor: colors[status]}} className={styles.line}></div>

      <div style={{backgroundColor: colors[status]}} className={styles.iconWrapper}>
        <Svg src={icons[status]} width={14} height={14}
            color='#fff' className={styles.icon}/>
      </div>

      <div className={styles.textWrapper}>
        <div className={styles.status}>{statusName[status]}</div>
        <div className={styles.text}>{message}</div>
      </div>
    </div>
  )
}

export interface SnackbarItemProps {
  status: SnackStatus,
  message: any,
}

export enum SnackStatus {
  SUCCESS='success',
  ERROR='error',
  WARNUNG='warning',
}

export default SnackbarMessage;