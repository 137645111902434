import { IEmployee, IGetEmployeeResponse } from "@source/api/models/employeeModels";
import { TableData } from "@source/utils/models/adminModels";
import { makeAutoObservable } from "mobx";

class AdminAgencyStore {
  agencyAccounts: IEmployee[] = [];
  agencyAccountsTotalCount: number = 0;
  editAgencyAccountPassword: IEmployee | null = null;
  agencyAccountForgotPassword: IEmployee | null = null;
  createAccountSuccessModal: boolean = false;

  private formatAgency(agency: IEmployee): TableData {
    const {firstName, lastName, email, phoneNumber, photo, invitations, accountType, _id, account, isDisabled, isConfirmed, loginDate} = agency;
    
    const formatedAgencyAccount = {
      name: `${firstName} ${lastName}`,
      firstName: firstName,
      lastName: lastName,
      email: email,
      phoneNumber: phoneNumber,
      photo: photo,
      accountType:accountType,
      _id: _id,
      position: account.position,
      role: account.role,
      stuffId: account.stuffId,
      invitation: invitations[0],
      homes: account.nursingHomes.map((nursingHome) => nursingHome._id),
      homesNames: account.nursingHomes.map((nursingHome) => nursingHome.name),
      homePermissionIds: account.permissions.homes?.reduce((accum, permissionHome) => ({...accum, [permissionHome.home._id]: permissionHome.permissions.concat()}), {}) || {},
      orgPermissionIds: account.permissions.organization?.permissions.concat() || [],
      isDisabled,
      isConfirmed,
      loginDate: loginDate || "",
      actions: null,
      toggle: null,
    }

    return formatedAgencyAccount;
  }

  constructor() {
    makeAutoObservable(this);
  }

  toggleCreateAccountSuccessModal() {
    this.createAccountSuccessModal = !this.createAccountSuccessModal;
  }

  getCreateAccountSuccessModal() {
    return this.createAccountSuccessModal;
  }

  setAgencyAccounts(agencyAccounts: IGetEmployeeResponse) {
    this.agencyAccounts = agencyAccounts.users;
    this.agencyAccountsTotalCount = agencyAccounts.totalCount;
  }

  setEditAgencyAccountPassword(agencyId: string | null) {
    this.editAgencyAccountPassword = this.agencyAccounts.find((agencyAccount) => agencyAccount._id === agencyId) || null;
  }

  setAgencyAccountForgotPassword(agencyId: string | null) {
    this.agencyAccountForgotPassword = this.agencyAccounts.find((agencyAccount) => agencyAccount._id === agencyId) || null;
  }

  getEditAgencyAccountPassword() {
    return this.editAgencyAccountPassword;
  }

  getAgencyAccountForgotPassword() {
    return this.agencyAccountForgotPassword;
  }

  getFormatedEditAgencyAccount(): TableData | null {
    if (this.editAgencyAccountPassword) {
      return this.formatAgency(this.editAgencyAccountPassword);
    }

    return null;
  }

  getFormatedAgencyAccounts(): TableData[] {
    return this.agencyAccounts.map((agencyAccount) => {
      return this.formatAgency(agencyAccount);
    })
  }

  getAgencyAccounts(): IEmployee[] {
    return this.agencyAccounts;
  }

  getAgencyAccountsTotalCount(): number {
    return this.agencyAccountsTotalCount;
  }

  filterIsDisabledAgencyAccounts(agencyAccountId: string) {
    this.agencyAccounts = this.agencyAccounts.filter((agencyAccount) => agencyAccount._id !== agencyAccountId);
    this.agencyAccountsTotalCount--;
  }
}

export default new AdminAgencyStore();