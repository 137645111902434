import UserService from "@source/api/services/userService";
import { ISupportReq, IUpdateProfileInfoReq } from "../models/userModels";

export default class UserController {
  static async profile() {
    return await UserService.getProfile();
  }
  
  static async updateProfile(updateInfo: IUpdateProfileInfoReq) {
    return await UserService.updateProfile(updateInfo);
  }

  static async updateProfilePhoto(req: FormData) {
    return await UserService.updateProfilePhoto(req);
  }
  
  static async deleteFromS3() {
    return await UserService.deleteFromS3();
  }

  static async setTimezone(timezone: string) {
    return await UserService.setTimezone(timezone);
  }

  static async support(req: ISupportReq) {
    return await UserService.support(req);
  }
}