import { memo, useState } from "react";
import styles from "./SetPasswordPage.module.scss";
import * as Yup from "yup";
import { useHistory, useLocation } from "react-router-dom";
import TextInput from "@source/components/UI/inputs/textInput/textInput";
import PasswordInput from "@source/components/UI/inputs/passwordInput/passwordInput";
import Button from "@source/components/UI/buttons/button/button";
import { useFormik } from "formik";
import useFetching from "@source/hooks/useFetching";
import { getHelperItems, LogoType, PasswordValidationRules, ROUTES } from "@source/utils/utils";
import { PasswordVariant } from "@source/components/UI/inputs/passwordInput/passwordHelper/passwordHelper";
import authBackLines from "@source/assets/imgs/authBackLines.png";
import Logo from "@source/components/UI/logo/logo";
import Notification from "@source/components/snackMessage/notification";
import EmployeeController from "@source/api/controllers/employeeController";
import LoginTechSupportModal from "@components/loginTechSupportModal/loginTechSupportModal";
import LoginTechSupportSentModal from "@components/loginTechSupportSentModal/loginTechSupportSentModal";
import { Role } from "@source/utils/models/adminModels";
import { useFirebaseLogEvent } from "@source/utils/hooks/useFirebaseLogEvent";

const SetPasswordPage = memo(() => {
  const [register, isLoading, error] = useFetching<ReturnType<typeof EmployeeController.verify>>(EmployeeController.verify);
  const router = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [logEvent] = useFirebaseLogEvent();

  const validateSchema = Yup.object({
    password: Yup.string()
                 .min(9, 'min 9')
                 .max(100, 'max 100')
                 .test('regex', 'Use at least 3 of the following', (value: any) => {
                     let rules = 0;
                     (new RegExp(PasswordValidationRules.NUMBER, 'g')).test(value) && rules++;
                     (new RegExp(PasswordValidationRules.UPPER_CHAR, 'g')).test(value) && rules++;
                     (new RegExp(PasswordValidationRules.LOWER_CHAR, 'g')).test(value) && rules++;
                     (new RegExp(PasswordValidationRules.SPECIAL_CHAR, 'g')).test(value) && rules++;
                     return (rules >= 3);
                   }),
    confirm: Yup.string()
                .required("Confirm Password is reqired")
                .oneOf([Yup.ref("password"), null], "The passwords do not match"),
  });

  const [techSupportModal, setTechSupportModal] = useState(false);
  const [techSupportSentModal, setTechSupportSentModal] = useState(false);

  const formik = useFormik({
    validationSchema: validateSchema,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {
      password: '',
      confirm: '',
      code: searchParams.get('code') || '',
      email: searchParams.get('email') || ''
    },
    onSubmit: async (formValues) => {
      const response = await register({
        code: formValues.code,
        email: formValues.email,
        password: formValues.password
      });

      if(response) {
        logEvent('set_password_new_account', {
          email: formValues.email,
        })
        new Notification().success('You’re successfully set new password');
        formik.resetForm();
        
        if (searchParams.get('employeeType') === Role.ROOT_AGENCY) {
          router.push(ROUTES.AgencyAccountHasBeenActivatedPage);
        } else {
          router.push(ROUTES.AccountHasBeenActivatedPage);
        }
      } 
    }
  });

  const onNeedHelp = () => {
    setTechSupportModal(!techSupportModal);
  }

  const {errors, values, touched, handleBlur, handleChange, handleSubmit} = formik;
  return(
    <div className={styles.setPass}>
      <LoginTechSupportModal open={techSupportModal} setOpen={() => {setTechSupportModal(!techSupportModal)}}  setSentModal={() => {setTechSupportSentModal(!techSupportSentModal)}}/> 
      <LoginTechSupportSentModal open={techSupportSentModal} setOpen={() => {setTechSupportSentModal(!techSupportSentModal)}}/> 

      <div className={styles.setPass__inner}>
        <Logo className={styles.setPass__inner_logo} type={LogoType.White}/>

        <form className={styles.setPass__form} onSubmit={handleSubmit}>
          <div className={styles.setPass__form_title}>Welcome to Brenna!</div>
          <div className={styles.setPass__form_subTitle}>Please create your password</div>
        
          <TextInput 
            id="email" 
            label="Email"
            value={values.email}
            className={styles.setPass__form_input}
            disabled spellCheck={false}/>

          <PasswordInput id="password" label="Password"
                  placeholder="Create password" 
                  className={styles.setPass__form_input}
                  value={values.password} 
                  touched={touched.password}
                  error={errors.password}
                  handleChange={handleChange} 
                  handleBlur={handleBlur}
                  helper helperProps={{
                    variant: PasswordVariant.tooltip,
                    toolTipItems: getHelperItems(values.password), 
                    subTitle: 'You cannot reuse your last 8 passwords.', 
                    title: 'The password must meet the following rules:'
                  }}/>

          <PasswordInput id="confirm" label="Confirm password"
                  placeholder="Re-enter your new password"
                  className={styles.setPass__form_input}
                  value={values.confirm}
                  touched={touched.confirm}
                  error={errors.confirm}
                  handleChange={handleChange} 
                  handleBlur={handleBlur} />

          <Button event="submit" className={styles.setPass__form_btn} 
                isLoading={isLoading}>Confirm</Button>
        </form>

        <span className={styles.setPass__inner__needHelp} onClick={onNeedHelp}>Need help? Click here</span>
      </div>

      <img className={styles.setPass_back} src={authBackLines} alt="lines"/>
    </div>
  )
})

export default SetPasswordPage;