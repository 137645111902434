import Button from "@components/UI/buttons/button/button";
import Modal from "@components/UI/modal/modal";
import { memo, useCallback, useState } from "react";
import styles from "./successModal.module.scss";
import done from "@source/assets/imgs/done.png";

const SuccessModal = memo((props: SuccessModalProps) => {
  const {confirmCb, children, className, text, confirmBtnInner, cb} = props;

  const [open, setOpen] = useState(false);

  const onTriggerBtnClick = useCallback(async () => {
    const response = await cb();

    if(response) {
      setOpen(true);
    }
  }, [setOpen])

  const onOkBtnClick = useCallback(async () => {
    confirmCb && confirmCb();
    setOpen(false);
  }, [confirmCb, setOpen])

  return (
    <>
      <div 
        onClick={onTriggerBtnClick} 
        className={`${styles.btn} ${className}`}
      >
        {children}
      </div>

      <Modal
        maxWidth={390}
        open={open}
      >
        <div className={styles.inner}>
          <img src={done} alt="done" className={styles.img} />

          <div className={styles.title}>{text || 'Success'}</div>
          <Button 
            className={styles.btn} 
            onClick={onOkBtnClick}
          >{confirmBtnInner || 'Ok'}</Button>
        </div>
      </Modal>
    </>
  )
})

interface SuccessModalProps {
  confirmCb?: () => void,
  className?: string,
  children: JSX.Element | JSX.Element[] | string,
  text?: string,
  confirmBtnInner?: JSX.Element | string,
  cb: () => Promise<boolean>
}

export default SuccessModal;