import { styled } from "@mui/material/styles";
import RadioLib from "@mui/material/Radio";
import { memo } from "react";
import styles from "./radio.module.scss";
import { Colors } from "@source/utils/utils";
import { FormControlLabel } from "@mui/material";

const BpIcon = styled("span")(() => ({
  width: 24,
  height: 24,
  padding: 0,
  borderRadius: "50%",
  border: `1px solid ${Colors.border}`,
  transition: "all 0.1s linear",

  "&:before": {
    content: '""',
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "block",
    width: 23,
    height: 23,
    borderRadius: "50%",
    backgroundColor: "#fff",
  },

  "input:focus ~ &": {
    borderColor: "#1C3BCF",
    transition: "all 0.1s linear",
  },
  "input:disabled ~ &": {
    transition: "all 0.1s linear",
    opacity: 0.3,
  },
}));

const BpIconError = styled("span")(() => ({
  width: 24,
  height: 24,
  padding: 0,
  borderRadius: "50%",
  border: `1px solid ${Colors.warning}`,
  transition: "all 0.1s linear",

  "&:before": {
    content: '""',
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "block",
    width: 23,
    height: 23,
    borderRadius: "50%",
    backgroundColor: "#fff",
  },

  "input:focus ~ &": {
    borderColor: "#1C3BCF",
    transition: "all 0.1s linear",
  },
  "input:disabled ~ &": {
    transition: "all 0.1s linear",
    opacity: 0.3,
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  position: "relative",
  transition: "all 0.1s linear",
  border: `1px solid ${Colors.borderActive}`,

  "&:before": {
    content: '""',
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "block",
    width: 12,
    height: 12,
    borderRadius: "50%",
    backgroundColor: Colors.borderActive,
    transition: "all 0.1s linear",
  },

  "input:disabled ~ &": {
    opacity: 0.3,
    transition: "all 0.1s linear",
  },
});

const BpErrorIcon = styled(BpIconError)({
  position: "relative",
  transition: "all 0.1s linear",
  border: `1px solid ${Colors.warning}`,

  "&:before": {
    content: '""',
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "block",
    width: 12,
    height: 12,
    borderRadius: "50%",
    backgroundColor: Colors.warning,
    transition: "all 0.1s linear",
  },

  "input:disabled ~ &": {
    opacity: 0.3,
    transition: "all 0.1s linear",
  },
});

/** You can use single radio or union of it with radioInput 
 * Prefet to use radioInput with one item
*/
export default memo((props: RadioProps) => {
  const { value, disabled, className, onChange, label, checked, labelPlace='end', name, error } = props;

  return (
    <div className={`${styles.radio} ${className}`}>
      <FormControlLabel
        sx={{
          margin: 0,
        }}
        control={
          <RadioLib
            sx={{
              width: 24,
              height: 24,
              padding: 0,
              marginLeft: labelPlace === "start" ? "16px" : "0",
              marginRight: labelPlace === "end" ? "16px" : "0",
              "&:hover": {
                bgcolor: "transparent",
              },
            }}
            name={name}
            disableRipple
            disabled={disabled}
            checked={checked}
            onChange={onChange}
            value={value}
            checkedIcon={error ? <BpErrorIcon /> : <BpCheckedIcon />}
            icon={error ? <BpIconError /> : <BpIcon />}
          />
        }
        label={<span className={styles.radio_label}>{label}</span>}
        labelPlacement={props.labelPlace}
      />
      
    </div>
  );
});

interface RadioProps extends IRadio {
  /** change handler */
  onChange?: (e: React.ChangeEvent) => void;

  /** radio checked status
   * @default false
  */
  checked?: boolean;
}

export interface IRadio {
  /** radio value */
  value: string | number | boolean;

  /** radio label */
  label?: string | number | JSX.Element;

  /** radio disabled */
  disabled?: boolean;

  /** className for container wrapping Radio component */
  className?: string;

  /** radio label placement */
  labelPlace?: 'end' | 'start' | 'top' | 'bottom',

  /** radio name (to unit under one name in radioInput) - use id in formik */
  name?: string,

  /** radio error status */
  error?: boolean,
}
