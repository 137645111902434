import { Redirect, Route, Switch, useHistory, useLocation} from "react-router-dom";
import { SUPER_ADMIN_PANEL_ROUTES } from '@source/utils/utils';
import React, { Suspense } from "react";
import Loader from "@source/components/UI/loader/loader";
import { observer } from "mobx-react-lite";
import SuperAdminOrganisationsPage from "./superAdminOrganisationsPage/superAdminOrganisationsPage";

const SuperAdminResidentsPage = React.lazy(() => import('@source/pages/AdminSuperAdminPanel/superAdminPanelRouter/superAdminResidentsPage/superAdminResidentsPage'));
const SuperAdminEmployeesPage = React.lazy(() => import('@source/pages/AdminSuperAdminPanel/superAdminPanelRouter/superAdminEmployeesPage/superAdminEmployeesPage'));
const SuperAdminFamilyMembersPage = React.lazy(() => import('@source/pages/AdminSuperAdminPanel/superAdminPanelRouter/superAdminFamilyMembersPage/superAdminFamilyMembersPage'));
const SuperAdminNursingHomesPage = React.lazy(() => import('@source/pages/AdminSuperAdminPanel/superAdminPanelRouter/superAdminNursingHomesPage/superAdminNursingHomesPage'));


const Routes = [
  {path: SUPER_ADMIN_PANEL_ROUTES.Organisations, component: SuperAdminOrganisationsPage, exact: true},
  {path: SUPER_ADMIN_PANEL_ROUTES.Residents, component: SuperAdminResidentsPage, exact: true},
  {path: SUPER_ADMIN_PANEL_ROUTES.Employees, component: SuperAdminEmployeesPage, exact: true},
  {path: SUPER_ADMIN_PANEL_ROUTES.FamilyMembers, component: SuperAdminFamilyMembersPage, exact: true},
  {path: SUPER_ADMIN_PANEL_ROUTES.NursingHomes, component: SuperAdminNursingHomesPage, exact: true},
];

const SuperAdminPanelRouter = observer(() => {
  const location = useLocation();
  const router = useHistory();


  return (
    <Suspense fallback={<Loader full width={48} height={48}/>}>
      <Switch location={location}>
        {Routes.map((route) => {
          return <Route {...route} key={route.path}/>
        })}

        <Redirect to={{ pathname: SUPER_ADMIN_PANEL_ROUTES.Organisations }} />
      </Switch>
    </Suspense>
  );
});

export default SuperAdminPanelRouter;
