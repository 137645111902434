import styles from './App.module.scss';
import { SnackbarProvider } from 'notistack';
import SnackComponent from '@source/components/snackMessage/snackComponent';
import AppInner from '@source/AppInner';
import Notification from './components/snackMessage/notification';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { useEffect } from 'react';
import CacheService from './api/services/chacheService';

function App() {

  useEffect(() => {
    if(process.env.REACT_APP_MODE === 'production') {
      const firebaseConfig = {
        apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
        projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
        messagingSenderId:  process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
        appId:  process.env.REACT_APP_FIREBASE_APPID,
        measurementId:  process.env.REACT_APP_FIREBASE_MEASUREMENTID
      };
      
      const app = initializeApp(firebaseConfig);
      const analytics = getAnalytics(app);   
    }

/*     new CacheService('photo').open().then((a) => a &&  a.clearCache()) */
  }, [])

  return (
    <div className={styles.app}>
      <SnackbarProvider
        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
        maxSnack={3}
        preventDuplicate
        autoHideDuration={6000}
        content={(key, message) => <SnackComponent id={key} message={message}/>}
        >
          <HashRouter>
            <AppInner />
          </HashRouter>
          {new Notification().mount()}
      </SnackbarProvider>
    </div>
  );
}

export default App;