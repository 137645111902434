import styles from './popupSurvey.module.scss';
import {memo, useCallback, useEffect, useState} from "react";
import Modal from "@components/UI/modal/modal";
import Button from "@components/UI/buttons/button/button";
import SurveyController from "@source/api/controllers/surveyController";
import {IGetSurveyRes} from "@source/api/models/surveyModels";
import {Typography} from "@mui/material";
import parse from 'html-react-parser';

const PopupSurvey = memo(() => {
    const [open, setOpen] = useState(false);
    const [status, setStatus] = useState<IGetSurveyRes | null>(null);
    useEffect(() => {
        const getSurveyStatus = async () => {
            const data = await SurveyController.getSurvey();
            if (!data) {
                return;
            }
            if (data.display) {
                setOpen(true);
                setStatus(data);
            }
        }
        getSurveyStatus();
    }, []);

    const onClose = useCallback(() => {
        setOpen(false);
    }, [])

    const onClickTryLater = useCallback(async () => {
        setOpen(false);
        if (!status?.survey._id ) {
            return
        }
        await SurveyController.updateSurveyStatus({
            surveyPassed: false,
            surveyId: status?.survey._id,
        });
    }, [status?.survey._id]);

    const onPassed = useCallback(async () => {
        setOpen(false);
        if (!status?.survey._id ) {
            return
        }
        await SurveyController.updateSurveyStatus({
            surveyPassed: true,
            surveyId: status?.survey._id,
        });
    }, [status?.survey._id]);

    return (
        <Modal
            open={open}
            close={onClose}
        >
            <div className={styles.content}>
                <Typography variant="h5" className={styles.title}>Brenna Staff Survey</Typography>
                <span className={styles.description}>{
                    parse(status?.text ?? '')
                }</span>
                <div className={styles.buttonContainer}>
                    <Button
                        variant="outlined"
                        className={styles.btn}
                        onClick={onClickTryLater}
                    >Remind me later</Button>
                    <Button
                        className={styles.btn}
                        onClick={onPassed}
                    >Survey completed</Button>
                </div>
            </div>
        </Modal>
    );
});

export default PopupSurvey;
