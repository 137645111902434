import styles from "./AdminSuperAdminPanel.module.scss";
import { NavLink, useHistory } from "react-router-dom";
import { HOME_ROUTES, SUPER_ADMIN_PANEL_ROUTES } from "@source/utils/utils";
import SuperAdminPanelRouter from "./superAdminPanelRouter/superAdminPanelRouter";
import { useEffect } from "react";
import { Role } from "@source/utils/models/adminModels";
import userStore from "@store/userStore";
import { observer } from "mobx-react-lite";

const AdminSuperAdminPanel = observer(() => {
  const router = useHistory();

  // useEffect(() => {
  //   if (userStore.getUserInfo()?.account.role === Role.SUPER_ADMIN) {
  //     router.push(HOME_ROUTES.Residents);
  //   }
  // }, [])

  return(
    <div className={styles.superAdminPanel}>
      <div className={styles.sideBar}>
        <NavLink 
          to={SUPER_ADMIN_PANEL_ROUTES.Organisations} 
          className={styles.nav__item} 
          activeClassName={styles.nav__item_active}
        >
          Organisations
        </NavLink>

        <NavLink 
          to={SUPER_ADMIN_PANEL_ROUTES.Residents} 
          className={styles.nav__item} 
          activeClassName={styles.nav__item_active}
        >
          Residents
        </NavLink>

        <NavLink 
          to={SUPER_ADMIN_PANEL_ROUTES.FamilyMembers} 
          className={styles.nav__item} 
          activeClassName={styles.nav__item_active}
        >
          Family members
        </NavLink>
        
        <NavLink 
          to={SUPER_ADMIN_PANEL_ROUTES.Employees} 
          className={styles.nav__item} 
          activeClassName={styles.nav__item_active}
        >
          Staff
        </NavLink>

        <NavLink 
          to={SUPER_ADMIN_PANEL_ROUTES.NursingHomes} 
          className={styles.nav__item} 
          activeClassName={styles.nav__item_active}
        >
          Homes
        </NavLink>
      </div>
      
      <div className={styles.routerOutlet}>
        <SuperAdminPanelRouter /> 
      </div>
    </div>
  )
});

export default AdminSuperAdminPanel;