import SurveyService from "@source/api/services/surveyService";
import {IPostSurveyReq} from "@source/api/models/surveyModels";

export default class SurveyController {
    static async getSurvey() {
        return await SurveyService.getSurvey();
    }

    static async updateSurveyStatus(req: IPostSurveyReq) {
        return await SurveyService.updateSurveyStatus(req);
    }
}