import { useEffect } from 'react';

const EVENT = 'click';

const useClickAway = (ref, callback) => {

  useEffect(() => {
    const listener = (event) => {
      if (!ref || !ref.current || ref.current.contains(event.target)) {
        return;
      } else {
        callback(event);
      }
    };
    document.addEventListener(EVENT, listener);
    return () => {
      document.removeEventListener(EVENT, listener);
    };
  }, [ref, callback]);
};


export default useClickAway;