class Debounce {
  enableCB: boolean = true;
  cb: Function;
  ms: number;
  isMouseEvent: boolean;
  timeout: ReturnType<typeof setTimeout> | null = null;

  constructor(cb: Function, ms: number, isMouseEvent: boolean = false) {
    this.cb = cb;
    this.ms = ms;
    this.isMouseEvent = isMouseEvent
  }

  call() {
    if(this.enableCB) {
      this.cb();
      this.enableCB = false;
      this.timeout = setTimeout(() => {this.enableCB = true}, this.ms)
    } else {
      if (!this.isMouseEvent) {
        if(this.timeout) {
          clearTimeout(this.timeout);
        }
  
        this.timeout = setTimeout(() => {this.enableCB = true}, this.ms)
      }
    }
  }
}

export default Debounce;