import { DOMAttributes, memo, MouseEvent, MouseEventHandler, useState } from "react";
import { useHistory } from "react-router-dom";
import styles from "./historyCard.module.scss";
import iconDoneAll from '@source/assets/icons/iconDoneAll.svg';
import iconDone from '@source/assets/icons/iconDone.svg';
import iconAttention from '@source/assets/icons/iconAttention.svg';
import iconDelete from '@source/assets/icons/iconDelete.svg';
import iconStar from '@source/assets/icons/iconStar.svg';
import iconCopy from '@source/assets/icons/iconCopy.svg';
import Svg from "@source/components/UI/svg/svg";
import moment from "moment";
import Button from "../UI/buttons/button/button";
import { getPriorityColors, HOME_ROUTES, stringToHslColor } from "@source/utils/utils";
import { formatMessage, formatMessageCopy, getPriorityNameById, getSectionNameById, getSubSectionNameById, getTimePrefix, getUpdateCategoryNameById } from "@source/pages/HomePage/homeRouter/updateTab/updateSchema/updateFormUtils";
import { SectionsId, SubSectionsId, UpdatePriorities, UpdatePrioritiesId } from "@source/pages/HomePage/homeRouter/updateTab/updateSchema/updateFormModels";
import ResidentController from "@source/api/controllers/residentController";
import useFetching from "@source/hooks/useFetching";
import Notification from "../snackMessage/notification";
import { isDateTimeValue } from "@pages/HomePage/homeRouter/updateTab/steps/mainStep.utils";
import CacheImg from "@components/UI/cacheImg/cacheImg";
import { IGetUpdateReadersResponse, IGetUpdateResponse, IUpdate, IUpdateFile, ResidentStatus } from "@source/api/models/residentModel";
import ConfirmModal from "@components/confirmModal/confirmModal";
import PhotoCellsRow from "@components/photoCellsRow/photoCellsRow";
import Modal from "@components/UI/modal/modal";
import { ContactTypeEnum } from "@source/api/models/allUsersModels";
import iconPdf from "@source/assets/icons/iconPdf.svg";
import iconDoneWhite from "@source/assets/icons/iconDoneWhite.svg";
import { Role, RoleName } from "@source/utils/models/adminModels";
import { useFirebaseLogEvent } from "@source/utils/hooks/useFirebaseLogEvent";
import nursingHomeStore from "@store/nursingHomeStore";

const HistoryCard = memo((props: HistoryCardProps) => {
  const [deleteUpdate, isDeleteLoading, deleteError] = useFetching<ReturnType<typeof ResidentController.deleteUpdate>>(ResidentController.deleteUpdate, () => new Notification().success('Draft has been deleted'));
  // const [createChat, createChatLoading, createChatError] = useFetching<ReturnType<typeof ChatController.createChat>>(ChatController.createChat);
  const [getUpdateReaders, getUpdateReadersLoading, getUpdateReadersError] = useFetching<ReturnType<typeof ResidentController.getUpdateReaders>>(ResidentController.getUpdateReaders);

  const { onTabUpdateDelete, isOpened, update, className, residentStatus} = props;

  const {_id, onlyForMember, categories, createdDate, createdByEmployee, resident, status, view,
    approximateTime, priority, isFamilyMemberRead, draftDate } = update;

  const router = useHistory();
  const [logEvent] = useFirebaseLogEvent();

  const [opened, setOpened] = useState(isOpened || false);
  const [updateReaders, setUpdateReaders] = useState<null | IGetUpdateReadersResponse[]>(null);
  const [readersModal, setReadersModal] = useState(false);
  const [copied, setCopiedId] = useState<string>();
  
  const onContinueClick = () => {
    logEvent('updates__click_continue_button_drafts', {
      home: nursingHomeStore.getActiveNursingHome()?.name || "",
      organization: nursingHomeStore.getActiveNursingHome()?.organization.name || "",
    });
    
    router.push({
      pathname: `${HOME_ROUTES.Residents}/${resident._id}/update/${_id}`,
    });
  };

  const isStatusValid = () => {
    return status === "complete";
  }

  const onDeleteUpdate = async () => {
    const deleteUpdateRes = await deleteUpdate({
      nursingHomeId: resident.nursingHome._id,
      residentId: resident._id,
      updateId: _id,
    });

    if (deleteUpdateRes) {
      logEvent('updates__delete_draft', {
        home: nursingHomeStore.getActiveNursingHome()?.name || "",
        organization: nursingHomeStore.getActiveNursingHome()?.organization.name || "",
      });

      onTabUpdateDelete(_id);
    }
  }

  const getCellsRowData = () => {
    return update.updateReaders.map((updateReader) => {
      const {firstName, lastName, photo} = updateReader.reader;
      return {
        photo: updateReader.reader.photo,
        abbreviatedName: `${firstName[0]?.toUpperCase() || ''}${lastName[0]?.toUpperCase() || ''}`
      }
    })
  }

  const openReadersModal = async (e: MouseEvent<HTMLHeadingElement>) => {
    e.stopPropagation();

    if (!updateReaders) {
      const getUpdateReadersRes = await getUpdateReaders({updateId: _id});
      
      if (getUpdateReadersRes) {
        setUpdateReaders(getUpdateReadersRes);
      }
    }
    
    setReadersModal(true);
  }

  const goToResidentTab = (e) => {
    e.preventDefault();
    e.stopPropagation();
    router.push(`${HOME_ROUTES.Residents}/${resident._id}`);
  }

  const getSection = () => {
    return update.categories.split('/')[0] ? getSectionNameById(update.categories.split('/')[0] as SectionsId) : "";
  }

  const getSubSection = () => {
    return update.categories.split('/')[0] ? getSubSectionNameById(update.categories.split('/')[0] as SectionsId, (update.categories.split('/')[1] || '') as SubSectionsId) : "";
  }

  function downloadURI(file: IUpdateFile) {
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = file.file;
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();
    a.remove();
  }

  const onCopyUpdate = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const copyUpdate = `Communication to family members via Brenna app - details below
${resident.firstName} ${resident.lastName}
${getUpdateCategoryNameById(categories.split('/')[0] as SectionsId, categories.split('/')[1] as SubSectionsId)}${categories.split('/')[0] === SectionsId.Other ? ` - ${view.updateCategoryName}` : ""}
${getPriorityNameById(priority as UpdatePrioritiesId)} Priority
Sent: ${moment(createdDate).format("DD MMM YYYY")} at ${moment(createdDate).format("hh:mm A")}
${formatMessageCopy(view.summary)}
${getTimePrefix(getSection() || "", getSubSection() || "", true)} ${moment(approximateTime).format("DD MMM YYYY")} at ${moment(approximateTime).format("hh:mm A")}.
Update provided by ${createdByEmployee.account.role === Role.AGENCY_NURSE ? RoleName[Role.AGENCY_NURSE] : ""} ${createdByEmployee.firstName} ${createdByEmployee.lastName}.`
    navigator.clipboard.writeText(formatMessageCopy(copyUpdate));
    setCopiedId(_id)

    setTimeout(() => {
      setCopiedId(undefined);
    }, 2000)
  }

  return(
    <div className={`${styles.historyCard} ${className}`}>
      <Modal open={readersModal} className={styles.readersModal} close={() => setReadersModal(false)} maxWidth={388}>
        <div className={styles.readers}>
          <span className={styles.title}>Update has been seen by:</span>

          <div className={styles.readerList}>
            {
              update.updateReaders.map((updateReader) => {
                const {photo, firstName, lastName} = updateReader.reader;
                
                return (
                  <div key={`${firstName}${lastName}`} className={styles.reader}>
                    <div className={styles.leftSide}>
                      <CacheImg
                        url={photo} 
                        alt="avatar"
                        className={styles.photo} 
                        imgClassName={styles.photo} 
                        skeletonProps={{variant: 'circular'}}
                      >
                        <div className={styles.noPhoto} style={{backgroundColor: resident ? stringToHslColor(firstName + lastName) : ""}}>
                          {firstName[0] + " " + lastName[0]}
                        </div>
                      </CacheImg>

                      <div className={styles.info}>
                        <div className={styles.row}>
                          <span className={styles.name}>{firstName} {lastName}</span>

                          {
                            updateReader.contactType === ContactTypeEnum.primary ?
                              <img className={styles.icon} src={iconStar} alt="star"/>
                              :
                              <></>
                          }
                        </div>

                        <div className={styles.row}>
                          <span className={styles.contact}>{updateReader.contactType.charAt(0).toUpperCase() + updateReader.contactType.slice(1)} contact</span>
                        </div>
                      </div>
                    </div>

                    <div className={styles.rightSide}>
                      <span className={styles.time}>{moment(updateReader.reading[0]).format("hh:mm A")}</span>

                      <span className={styles.date}>{moment(updateReader.reading[0]).format("DD MMM YYYY")}</span>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </Modal>

      {
        !isStatusValid() ?
          <div className={styles.status}>
            <Svg className={styles.icon} src={iconAttention} width={16} height={16} color="#ffffff"/>
          </div>
          : 
          <></>
      }

      {/* {
        isStatusValid() && unreadMessageCount && unreadMessageCount > 0 ?
          <div className={styles.ureadMessages}>{unreadMessageCount}</div>
          :
          <></>
      } */}

      <div className={styles.content} onClick={() => setOpened(!opened)}>
        <div onClick={goToResidentTab}>
          <CacheImg 
            url={resident.photo} 
            alt="avatar" 
            className={styles.photo} 
            imgClassName={styles.photo} 
            skeletonProps={{variant: 'circular'}}
          >
            <div className={styles.noPhoto} style={{backgroundColor: stringToHslColor(resident.firstName + resident.lastName)}}>
              {resident.firstName[0] + " " + resident.lastName[0]}
            </div>
          </CacheImg>
        </div>

        <div className={styles.info}>
          <div className={styles.header}>
            <div className={styles.headerLeft}>
              <div className={styles.top}>
                <div className={styles.title}>
                  <span className={styles.name} onClick={goToResidentTab}>{resident.firstName} {resident.lastName}</span>

                  {
                    !isStatusValid() ?
                    <span className={styles.draft}>(Draft by {createdByEmployee.account.role === Role.AGENCY_NURSE ? RoleName[Role.AGENCY_NURSE] : ""} {createdByEmployee.firstName} {createdByEmployee.lastName}{draftDate && `, ${moment(draftDate).format("DD MMM YYYY")}`})</span>
                    :
                    <></>
                  }
                </div>

                <div className={styles.right}>
                  {
                    isStatusValid() ?
                      <div className={styles.photoCellsRow} onClick={openReadersModal}>
                        <PhotoCellsRow photosData={getCellsRowData()}/>
                      </div>
                      :
                      <></>
                  }

                  {
                    isStatusValid() ?
                      <Svg className={styles.iconDone} src={isFamilyMemberRead ? iconDoneAll : iconDone} width={22} height={22} color="#3956C7"/>
                      :
                      <></>
                  }
                </div>
              </div>

              <div className={styles.statusRow}>
                <div className={styles.left}>
                  <span className={styles.injury}>
                    {getUpdateCategoryNameById(categories.split('/')[0] as SectionsId, categories.split('/')[1] as SubSectionsId)}
                    {categories.split('/')[0] === SectionsId.Other ? ` - ${view.updateCategoryName}` : null}
                  </span>

                  <div className={styles.line}/>

                  <div className={styles.priority}>
                    {
                      priority ?
                          <span style={{color: getPriorityColors(getPriorityNameById(priority))}}
                                className={styles.text}>{getPriorityNameById(priority)} Priority</span>
                          :
                          <></>
                    }
                  </div>

                  {
                      onlyForMember && onlyForMember.firstName && onlyForMember.lastName &&
                      <span className={styles.injury}>(Only sent to {update.onlyForMemberContactType ? update.onlyForMemberContactType : ''} {onlyForMember.firstName} {onlyForMember.lastName})</span>
                  }


                </div>
                {
                  isStatusValid() ?
                  <div className={styles.right}>
                    <span className={styles.update}>Sent: <span className={styles.date}>{moment(createdDate).isValid() ? `${moment(createdDate).format("DD MMM YYYY")} at ${moment(createdDate).format("hh:mm A")}` : ""}</span></span>
                  </div>
                  :
                  <></>
                }
              </div>
            </div>

            { 
                !isStatusValid() && residentStatus === ResidentStatus.ACTIVE ?
                <div className={styles.headerRight}>
                  <ConfirmModal
                    confirmCb={() => onDeleteUpdate()}
                    className={styles.deleteWrapper}
                    title="Are you sure you want to delete the update draft?"
                    confirmLoading={isDeleteLoading}
                  >
                    <Button className={styles.deleteBtn} variant='outlined' event="visual">
                      <Svg className={styles.iconDelete} width={20} height={20} src={iconDelete} color="#333333"/>
                    </Button>
                  </ConfirmModal>

                  <Button className={styles.continueBtn} variant='outlined' onClick={onContinueClick}>Continue</Button>
                </div>
                :
                <></>
              }
          </div>

          {
            isStatusValid() ?
            (
              opened ? 
              <div className={styles.description}>
                <p className={styles.text}>
                  {view?.summary && formatMessage(view.summary)}
                </p>

                <span className={styles.updateTime}>
                  {getTimePrefix(getSection() || "", getSubSection() || "", true)} <span className={styles.bold}>
                    {moment(approximateTime).isValid() ? `${moment(approximateTime).format("DD MMM YYYY")}${isDateTimeValue(getSection() || "") ? ` at ${moment(approximateTime).format("hh:mm A")}` : ''}.` : ""}
                    </span>
                </span>
                
                <div className={styles.bottom}>
                  <span className={styles.updator}>
                    Update provided by <span className={styles.bold}>{createdByEmployee.account.role === Role.AGENCY_NURSE ? RoleName[Role.AGENCY_NURSE] : ""} {createdByEmployee.firstName} {createdByEmployee.lastName}</span>.
                  </span>
                </div>

                {
                  update.files && update.files.length ?
                    <div className={styles.documents}>
                      {
                        update.files.map((file: IUpdateFile) => {
                          if (file.name.includes('.pdf')) {
                            return (
                              <div key={file.key} className={styles.document} onClick={() => {downloadURI(file)}}>
                                <img
                                  className={styles.icon}
                                  src={iconPdf}
                                  alt="document"
                                />
                  
                                <span className={styles.text}>{file.name}</span>
                              </div>
                            )
                          }

                          return null;
                        })
                      }
                    </div>
                    :
                    <></>
                }

                {
                  update.files && update.files.length ?
                    <div className={styles.photos}>
                      {
                        update.files.map((file: IUpdateFile) => {
                          if (!file.name.includes('.pdf')) {
                            return (
                              <img
                                key={file.key}
                                className={styles.documentPhoto}
                                src={file.file}
                                alt="photo"
                              />
                            )
                          }

                          return null;
                        })
                      }
                    </div>
                    :
                    <></>
                }

                {
                  copied ?
                      <div className={styles.copied}>
                        <img className={styles.icon} src={iconDoneWhite} alt="!"/>
                        <span className={styles.text}>Copied to clipboard!</span>
                      </div>
                      :
                      <></>
                }

                <div className={styles.copy} onClick={onCopyUpdate}>
                  <img className={styles.icon} src={iconCopy} alt="copy"/>
                  <span className={styles.text} onClick={onCopyUpdate}>Copy update</span>
                </div>
              </div>
              :
              <></>
            )
            :
            <></>
          }
        </div>
      </div>
    </div>
  )
})

export default HistoryCard;

interface HistoryCardProps {
  className?: string,
  onTabUpdateDelete: (updateId: string) => void,
  isOpened?: boolean,
  isChatIcon?: boolean,
  update: IGetUpdateResponse,
  residentStatus?: ResidentStatus,
}

