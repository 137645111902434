import styles from "./superAdminOrganisationsPage.module.scss";
import SelectOrganizationTable from "./selectOrganizationTable/selectOrganizationTable";

const SuperAdminOrganisationsPage = () => {
  return(
   <div className={styles.selectOrganization}>
      <div className={styles.table}>
        <SelectOrganizationTable />
      </div>
    </div>
  )
};

export default SuperAdminOrganisationsPage;