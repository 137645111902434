import { useCallback, useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import useFetching from "@source/hooks/useFetching";
import { useHistory } from "react-router-dom";
import { HOME_ROUTES, LogoType, ROUTES } from "@source/utils/utils";
import styles from "./AgencyLoginNamePage.module.scss";
import TextInput from "@source/components/UI/inputs/textInput/textInput";
import PasswordInput from "@source/components/UI/inputs/passwordInput/passwordInput";
import Button from "@source/components/UI/buttons/button/button";
import authBackLines from "@source/assets/imgs/authBackLines.png";
import Logo from "@source/components/UI/logo/logo";
import AuthController from "@source/api/controllers/authController";
import { ILogInRequest } from "@source/api/models/authModels";
import Notification from "@source/components/snackMessage/notification";
import userStore from "@source/store/userStore";
import InteractionObserver from "@source/api/interactionObserver";
import UserController from "@source/api/controllers/userController";
import NursingHomeController from "@source/api/controllers/nursingHomeController";
import { IGetAvailableNursingHomesRequest, IGetNursingHomesRequest } from "@source/api/models/nursingHomeModels";
import nursingHomeStore from "@store/nursingHomeStore";
import { api } from "@source/api/http";
import LoginTechSupportModal from "@components/loginTechSupportModal/loginTechSupportModal";
import LoginTechSupportSentModal from "@components/loginTechSupportSentModal/loginTechSupportSentModal";
import { Role } from "@source/utils/models/adminModels";
import { observer } from "mobx-react-lite";
import { LOCAL_STORAGE_FROM_AGENCY_SET_PASSWORD_PAGE } from "@source/utils/models/userModels";
import { AuthActions } from "@source/api/models/userModels";
import { useFirebaseLogEvent } from "@source/utils/hooks/useFirebaseLogEvent";

interface IAgencyLogInForm  {
  firstName: string,
  lastName: string,
}

const AgencyLoginNamePage = observer((props: AgencyLoginNamePageProps) => {
  const {} = props;
  const [agencyLogin, agencyLoginIsLoading, agencyLoginError] = useFetching<ReturnType<typeof AuthController.login>>(AuthController.agencyLogin);
  const [profile, profileIsLoading, profileError] = useFetching<ReturnType<typeof UserController.profile>>(UserController.profile);
  const [setTimezone, setTimezoneIsLoading,setTimezoneError] = useFetching<ReturnType<typeof UserController.setTimezone>>(UserController.setTimezone);
  const [getAvailableNursingHomes, getAvailableNursingHomesIsLoading, getAvailableNursingHomesError] = useFetching<ReturnType<typeof NursingHomeController.getAvailableNursingHomes>>(NursingHomeController.getAvailableNursingHomes);
  
  const router = useHistory();
  const [logEvent] = useFirebaseLogEvent();

  const [techSupportModal, setTechSupportModal] = useState(false);
  const [techSupportSentModal, setTechSupportSentModal] = useState(false);

  const initialValue = userStore.getAgencyUser() || null;

  useEffect(() => {
    if (!userStore.getAgencyUser().rootEmail || !userStore.getAgencyUser().rootPassword) {
      router.push(ROUTES.LoginPage)
    }
  }, [])


  const getProfile = async () => {
    const profileInfo = await profile();

    if(profileInfo) {
      userStore.setUserInfo(profileInfo);
      
      userStore.setOrganizationId(profileInfo.account.organization._id);
    }

    const getAvailableNursingHomesReq: IGetAvailableNursingHomesRequest = {
      organizationId: userStore.getOrganizationId(), 
    }

    const availableNursingHomesRes = await getAvailableNursingHomes(getAvailableNursingHomesReq);

    if (availableNursingHomesRes) {
      nursingHomeStore.setNursingHomes({
        nursingHomes: availableNursingHomesRes,
        totalCount: availableNursingHomesRes.length
      });

      const activeNursingHome = availableNursingHomesRes.find((userNursingHome) => userNursingHome._id === nursingHomeStore.getLocalStorageActiveNursingHome()) || availableNursingHomesRes[0];
      nursingHomeStore.setActiveNursingHome(activeNursingHome);
    
      if (nursingHomeStore.activeNursingHome) {
        api.defaults.params = {
          ...api.defaults.params,
          nursingHomeId: nursingHomeStore.activeNursingHome._id,
        }
      }
    }


    await setTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone)
  }

  const validateSchema = Yup.object({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
  });

  const formik = useFormik<IAgencyLogInForm>({
    validationSchema: validateSchema,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {
      firstName: initialValue?.firstName || "",
      lastName: initialValue?.lastName || ""
    },
    onSubmit: async (formValues) => {
      const response = await agencyLogin({...userStore.getAgencyUser(), ...formValues});

      if(response) {
        logEvent('agency_login__name', {
          email: userStore.getAgencyUser()?.rootEmail,
        })

        if (response.action === AuthActions.REGISTER_NEW_AGENCY) {
          userStore.setAgencyUserFirsLastNames(formValues);
          router.push(ROUTES.AgencyLoginTypeNumberPage);
        } else {
          userStore.setAgencyUserNull();
          localStorage.setItem("refreshToken", response.refreshToken);
          localStorage.setItem("accessToken", response.accessToken);

          await getProfile();
          new Notification().success('You’re successfully logged in');

          logEvent("agency_login__successful_login", {
            email: userStore.getAgencyUser()?.rootEmail,
            organization: userStore.getUserInfo()?.account.organization.name || "",
          })

          router.push(HOME_ROUTES.Residents);
  
          new InteractionObserver();
          userStore.resetLoginEmail();
        }
      }
    }
  });

  const onNeedHelp = () => {
    setTechSupportModal(!techSupportModal);
  }

  const onBack = () => {
    router.push(ROUTES.LoginPage);
    userStore.setAgencyUserNull();
  }

  const {errors, values, touched, handleBlur, handleChange, handleSubmit} = formik;
  return(
    <div className={styles.agencyLogin}>
      <span className={styles.back} onClick={onBack}>{"< Back"}</span>

      <LoginTechSupportModal open={techSupportModal} setOpen={() => {setTechSupportModal(!techSupportModal)}}  setSentModal={() => {setTechSupportSentModal(!techSupportSentModal)}}/> 
      <LoginTechSupportSentModal open={techSupportSentModal} setOpen={() => {setTechSupportSentModal(!techSupportSentModal)}}/> 

      <div className={styles.agencyLogin__inner}>
        <Logo className={styles.agencyLogin__inner_logo} type={LogoType.White}/>

        <form className={styles.agencyLogin__form} onSubmit={handleSubmit}>
          <div className={styles.agencyLogin__form_title}>Welcome to Brenna!</div>
          <div className={styles.agencyLogin__form_subTitle}>Please enter your details below</div>
        
          <TextInput id="firstName" label="First name"
                  placeholder="Your first name"
                  className={styles.agencyLogin__form_input}
                  value={values.firstName}
                  touched={touched.firstName}
                  error={errors.firstName} 
                  handleChange={handleChange} 
                  handleBlur={handleBlur}/>

          <TextInput id="lastName" label="Last name"
                  placeholder="Your last name"
                  className={styles.agencyLogin__form_input}
                  value={values.lastName}
                  touched={touched.lastName}
                  error={errors.lastName} 
                  handleChange={handleChange} 
                  handleBlur={handleBlur}/>

          <Button event="submit" className={styles.agencyLogin__form_btn} 
                isLoading={agencyLoginIsLoading || setTimezoneIsLoading || profileIsLoading || getAvailableNursingHomesIsLoading}>Confirm</Button>
        </form>

        <span className={styles.agencyLogin__needHelp} onClick={onNeedHelp}>Need help? Click here</span>
      </div>

      <img className={styles.agencyLogin_back} src={authBackLines} alt="lines"/>
    </div>
  )
})

export default AgencyLoginNamePage;

interface AgencyLoginNamePageProps {

}