import { ICloseChatRequest, ICreateChatRequest, IGetChatRequest, IGetChatsRequest, INewMessageChatRequest, IReopenChatRequest } from "@source/api/models/residentModel";
import ChatService from "../services/chatService";

export default class ChatController {
  static async getChats(req: IGetChatsRequest) {
    return await ChatService.getChats(req);
  }

  static async getChat(req: IGetChatRequest) {
    return await ChatService.getChat(req);
  }
  
  static async createChat(req: ICreateChatRequest) {
    return await ChatService.createChat(req);
  }

  static async closeChat(req: ICloseChatRequest) {
    return await ChatService.closeChat(req);
  }
  
  static async reopenChat(req: IReopenChatRequest) {
    return await ChatService.reopenChat(req);
  }
  
  static async newMessageChat(req: INewMessageChatRequest) {
    return await ChatService.newMessageChat(req);
  }
}