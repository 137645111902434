import { memo } from "react";
import styles from "./loadingPage.module.scss";
import Loader from "@source/components/UI/loader/loader";
import loadingImg from "@source/assets/imgs/loadingImg.svg";

const LoadingPage = memo(() => {
  return(
    <div className={styles.loading}>
      <div className={styles.loading__top}>
        <Loader className={styles.loading__top_loader} color="#3956C7" height={40} width={40}/>
        <div className={styles.loading__top_text}>Please wait</div>
      </div>

      <img className={styles.loading_img} src={loadingImg} alt="loading" />
    </div>
  )
})

export default LoadingPage;