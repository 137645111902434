import { useCallback, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import useFetching from "@source/hooks/useFetching";
import { useHistory } from "react-router-dom";
import { LogoType, ROUTES, SUPER_ADMIN_PANEL_ROUTES } from "@source/utils/utils";
import styles from "./TwoFactorLoginPage.module.scss";
import TextInput from "@source/components/UI/inputs/textInput/textInput";
import Button from "@source/components/UI/buttons/button/button";
import authBackLines from "@source/assets/imgs/authBackLines.png";
import Logo from "@source/components/UI/logo/logo";
import AuthController from "@source/api/controllers/authController";
import { MFALoginReq } from "@source/api/models/authModels";
import Notification from "@source/components/snackMessage/notification";
import userStore from "@source/store/userStore";
import InteractionObserver from "@source/api/interactionObserver";
import UserController from "@source/api/controllers/userController";
import NursingHomeController from "@source/api/controllers/nursingHomeController";
import LoginTechSupportModal from "@components/loginTechSupportModal/loginTechSupportModal";
import LoginTechSupportSentModal from "@components/loginTechSupportSentModal/loginTechSupportSentModal";
import { observer } from "mobx-react-lite";


const TwoFactorLoginPage = observer((props: TwoFactorLoginPageProps) => {
  const {} = props;
  const [mfaLogin, mfaLoginIsLoading, mfaLoginError] = useFetching<ReturnType<typeof AuthController.mfaLogin>>(AuthController.mfaLogin);
  const [profile, profileIsLoading, profileError] = useFetching<ReturnType<typeof UserController.profile>>(UserController.profile);
  const [setTimezone, setTimezoneIsLoading,setTimezoneError] = useFetching<ReturnType<typeof UserController.setTimezone>>(UserController.setTimezone);
  const [getAvailableNursingHomes, getAvailableNursingHomesIsLoading, getAvailableNursingHomesError] = useFetching<ReturnType<typeof NursingHomeController.getAvailableNursingHomes>>(NursingHomeController.getAvailableNursingHomes);
  
  const router = useHistory();

  const validateSchema = Yup.object({
    code: Yup.string().required("Code is required"),
  });

  const [techSupportModal, setTechSupportModal] = useState(false);
  const [techSupportSentModal, setTechSupportSentModal] = useState(false);

  const getProfile = async () => {
    const profileInfo = await profile();

    if(profileInfo) {
      userStore.setUserInfo(profileInfo);
    }
  }

  const formik = useFormik({
    validationSchema: validateSchema,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {
      code: '',
    },
    onSubmit: async (formValues) => {
      const mfaLoginStore = userStore.getMfaLogin();
      if (!mfaLoginStore) {
        return;
      }

      const mfaLoginReq: MFALoginReq = {
        email: mfaLoginStore.email,
        password: mfaLoginStore.password,
        code: formValues.code,
      }

      const response = await mfaLogin(mfaLoginReq);

      if(response) {
        localStorage.setItem("refreshToken", response.refreshToken);
        localStorage.setItem("accessToken", response.accessToken);

        await getProfile();
        userStore.setMfaLogin(null);
        new Notification().success('You’re successfully logged in');

        router.push(SUPER_ADMIN_PANEL_ROUTES.Organisations);
        new InteractionObserver();
        userStore.resetLoginEmail();
      }
    }
  });

  const onBackToLogin = useCallback(() => {
    userStore.setMfaLogin(null);
    router.push(ROUTES.LoginPage);
  }, [router])

  const onNeedHelp = () => {
    setTechSupportModal(!techSupportModal);
  }

  const {errors, values, touched, handleBlur, handleChange, handleSubmit} = formik;
  return(
    <div className={styles.login}>
      <LoginTechSupportModal open={techSupportModal} setOpen={() => {setTechSupportModal(!techSupportModal)}}  setSentModal={() => {setTechSupportSentModal(!techSupportSentModal)}}/> 
      <LoginTechSupportSentModal open={techSupportSentModal} setOpen={() => {setTechSupportSentModal(!techSupportSentModal)}}/> 
      
      <div className={styles.login__inner}>
        <Logo className={styles.login__inner_logo} type={LogoType.White}/>

        <form className={styles.login__form} onSubmit={handleSubmit}>
          <div className={styles.login__form_title}>Two-factor authentication</div>
          <div className={styles.login__form_subTitle}>
            Open the two-step authentication app on your mobile device to get your verification code
          </div>
        
          <TextInput id="code" label="Authentication code"
                  placeholder="Enter authentication code"
                  className={styles.login__form_input}
                  value={values.code}
                  touched={touched.code}
                  error={errors.code} 
                  handleChange={handleChange} 
                  handleBlur={handleBlur} spellCheck={false}/>

          <Button event="submit" className={styles.login__form_btn} 
                isLoading={mfaLoginIsLoading || setTimezoneIsLoading || profileIsLoading || getAvailableNursingHomesIsLoading}>Authenticate</Button>

          <div onClick={onBackToLogin} className={styles.forgot}>Back to login</div>
        </form>

        <span className={styles.login__needHelp} onClick={onNeedHelp}>Need help? Click here</span>
      </div>

      <img className={styles.login_back} src={authBackLines} alt="lines"/>
    </div>
  )
})

export default TwoFactorLoginPage;

interface TwoFactorLoginPageProps {

}