import CacheImg from "@components/UI/cacheImg/cacheImg";
import { stringToHslColor } from "@source/utils/utils";
import { memo } from "react";
import styles from "./photoCellsRow.module.scss";


const PhotoCellsRow = memo((props: PhotoCellsRowProps) => {
  const {photosData} = props;

  return( 
    <div className={styles.photoCellsRow}>
      {
        photosData.map((photoData) => {
          return (
            <CacheImg
              url={photoData.photo} 
              alt="avatar"
              key={photoData.abbreviatedName}
              className={styles.photo} 
              imgClassName={styles.photo} 
              skeletonProps={{variant: 'circular'}}
            >
              <div className={styles.noPhoto} style={{backgroundColor: stringToHslColor(photoData.abbreviatedName)}}>
                {photoData.abbreviatedName}
              </div>
            </CacheImg>
          )
        })
      }
    </div>
  )
})

export default PhotoCellsRow;

interface PhotoCellsRowProps {
  photosData: {
    photo?: string,
    abbreviatedName: string,
  }[]
}

