import { useSnackbar, WithSnackbarProps } from 'notistack';
import SnackbarMessage, { SnackStatus } from '@source/components/snackMessage/snackbarItem/snackbarMessage';


class Notification {
  static instance?: Notification;
  useSnackbarRef?: WithSnackbarProps;

  constructor() {
    if(!Notification.instance) {
      Notification.instance = this;
    }
  }

  mount() {
    const Notificator: React.FC = () => {
      this.useSnackbarRef = useSnackbar();
      return null;
    }

    return <Notificator />;
  }

  success(msg: string) {
    this.getInstance().addNotification(msg, SnackStatus.SUCCESS);
  }
  warning(msg: string) {
    this.getInstance().addNotification(msg, SnackStatus.WARNUNG);
  }
  error(msg: string) {
    this.getInstance().addNotification(msg, SnackStatus.ERROR);
  }

  private addNotification(msg: string, status: SnackStatus) {
    this.getInstance().useSnackbarRef?.enqueueSnackbar(SnackbarMessage({status: status, message: msg}), {preventDuplicate: true});
  }

  private getInstance() {
    if(!Notification.instance) {
      Notification.instance = this;
    }

    return Notification.instance
  }
}

export default Notification;