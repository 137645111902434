import Button from "@components/UI/buttons/button/button";
import Modal from "@components/UI/modal/modal";
import Svg from "@components/UI/svg/svg";
import {memo, useCallback, useEffect, useState} from "react";
import styles from "./confirmModal.module.scss";
import iconDelete from "@source/assets/icons/iconDelete.svg";
import Loader from "@components/UI/loader/loader";

const ConfirmModal = memo((props: ConfirmModalProps) => {
  const {children, confirmCb, rejectCb, confirmLoading, subTitle, hideIcon,
      rejectLoading, className, title, confirmBtnInner, rejectBtnInner, icon, isOpen} = props;

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (typeof isOpen !== 'undefined') {
      setOpen(isOpen);
    }
  }, [isOpen]);

  const onQuestionBtnClick = useCallback(() => {
    setOpen(true);
  }, [setOpen])

  const onConfirmBtnClick = useCallback(async () => {
    await confirmCb();
    setOpen(false);
  }, [confirmCb, setOpen])

  const onRejectBtnClick = useCallback(async () => {
    rejectCb && await rejectCb();
    setOpen(false);
  }, [rejectCb, setOpen])

  return (
    <>
      <div 
        onClick={onQuestionBtnClick} 
        className={`${styles.btn} ${className}`}
      >
        {children}
      </div>

      <Modal
        maxWidth={390}
        open={open}
      >
        <div className={styles.inner}>
          {hideIcon
            ? <div className={styles.img}>
                <Svg src={icon || iconDelete} color="#E7313C" width={30} height={30}/>
              </div>
            : <></>}

          <div className={styles.title}>{title || 'Are you sure you want to confirm?'}</div>

          {subTitle
            ? <div className={styles.subTitle}>{subTitle}</div>
            : <></>}

          <Button 
            className={styles.btn} 
            onClick={onConfirmBtnClick}
            isLoading={confirmLoading}
          >{confirmBtnInner || 'Confirm'}</Button>

          {rejectLoading
            ? <Loader />
            : <div 
                onClick={onRejectBtnClick} 
                className={styles.cancel}
              >
                {rejectBtnInner || 'Cancel'}
              </div>}
        </div>
      </Modal>
    </>
  )
})

export interface ConfirmModalProps {
  children?: JSX.Element | JSX.Element[] | string,
  className?: string,
  title?: JSX.Element | string,
  subTitle?: JSX.Element | string,
  confirmCb: (() => Promise<any>) | (() => void),
  rejectCb?: () => Promise<any>,
  confirmLoading?: boolean,
  rejectLoading?: boolean,
  confirmBtnInner?: JSX.Element | string,
  rejectBtnInner?: JSX.Element | string,
  icon?: string,
  hideIcon?: boolean,
  isOpen?: boolean
}

export default ConfirmModal;