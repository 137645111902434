import { memo } from "react";
import { InputBase, MenuItem, Select, SelectChangeEvent, styled } from "@mui/material";
import styles from "./selectInput.module.scss";
import iconArrowDown from "@source/assets/icons/iconArrowDown.svg";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    borderRadius: 8,
    backgroundColor: "#F8FAFF",
    border: '1px solid #DDE2F2',
    padding: '10px 16px',
    textAling: 'center',
    fontFamily: "Poppins",
    '&:focus': {
      borderColor: ' #DDE2F2',
    },
  },
  "& .MuiSelect-icon": {
    top: "50%",
    transform :"translateY(-50%)"
  },
  "& .MuiSelect-iconOpen": {
    transform :"rotate(180deg) translateY(50%)",
  },
}));

export default memo((props: SelectInputProps) => {
  const {value, handleChange, items, id, error, touched, placeholder, 
         label, className, disabled, required=false} = props;

  return(
    <div className={`${styles.selectInputWrapper} ${className} ${disabled ? styles.selectInputWrapper_disabled : ''}`}>
      {label
        ? <div className={styles.selectInputWrapper_label}>{label}{required ? <span>*</span> : ''}</div>
        : <></>}

      <Select
        className={`${styles.input} ${ touched && error ? styles.input_error : ''}`}
        value={value}
        id={id}
        name={id}
        variant="outlined"
        fullWidth
        disabled={disabled}
        placeholder={placeholder}
        error={Boolean(error && touched)}
        input={<BootstrapInput />}
        displayEmpty={true}
        IconComponent={(props) => <img {...props} src={iconArrowDown}/>}
        renderValue={value => value?.length ? items.find(item => item.value === value)?.title : placeholder}
        onChange={handleChange}
        MenuProps={{classes: {paper: styles.paper}}}
        >
          {items.map((item) => 
          (<MenuItem 
            sx={{":hover": { color: `${item.style?.color ? item.style.color : "#3956C7"}`}}} 
            style={item.style} 
            className='menuItem' 
            key={item.id} 
            value={item.value}
            >
              {item.title}
            </MenuItem>)
          )}
        </Select>

      {touched && error
          ? <div className={styles.selectInputWrapper_error}>{error}</div>
          : <></>}
    </div>
  )
})

interface SelectInputProps {
  /** Input value */
  value?: string,

  /** Input id */
  id?: string,
   
  /** input items */
  items: Array<ISelectItem>,

  /** handleChange function */
  handleChange: (e: SelectChangeEvent<string>) => void,

  /** Input label */
  label?: string,

  /** Input error message */
  error?: string,

  /** Input touched status */
  touched?: boolean,

  /** Input placeholder */
  placeholder?: string,
  
  /** disabled status
   * @default false
   */
  disabled?: boolean,

  /** just for view, use Yup to validate 
   * @default false
  */
  required?: boolean,

  /** className for container wrapping SelectInput component */
  className?: string,
}

export interface ISelectItem {
  title: string,
  value: string,
  id: number | string,
  style?: any
}