import Notification from "@source/components/snackMessage/notification";
import { AxiosError } from "axios";
import AuthController from "./controllers/authController";
import { getAnalytics, logEvent as firebaseLogEvent } from "firebase/analytics";
import { getApps } from "firebase/app";

export default class ReqError extends Error {
  status:number;
  errorMessage: string;

  constructor(status:number, message:string) {
    super(message);
    this.status= status;
    this.errorMessage = message;
  }

  static registrationError(error:ErrorFromService) {
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  static loginError(error:ErrorFromService) {
    if(error.data.statusCode === 401 && error.data.stringCode === LogicExceptionList.ACCESS_DENIED) {
      new Notification().warning('Session ended');
    }
    else if(error.data.statusCode === 400 && error.data.stringCode === LogicExceptionList.PASSWORD_IS_EXPIRED) {
      new Notification().warning('Password id expired');
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("accessToken");
      
      window.location.hash = '#/changePassword'
    } else {
      new Notification().error(error.data.message);
    }
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  static agencyLoginError(error:ErrorFromService) {
    if(error.data.statusCode === 401 && error.data.stringCode === LogicExceptionList.ACCESS_DENIED) {
      new Notification().warning('Session ended');
    }
    else if(error.data.statusCode === 400 && error.data.stringCode === LogicExceptionList.PASSWORD_IS_EXPIRED) {
      new Notification().warning('Password id expired');
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("accessToken");
      
      window.location.hash = '#/changePassword'
    } else {
      new Notification().error(error.data.message);
    }
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  static agencyRegisterError(error:ErrorFromService) {
    if(error.data.statusCode === 401 && error.data.stringCode === LogicExceptionList.ACCESS_DENIED) {
      new Notification().warning('Session ended');
    }
    else if(error.data.statusCode === 400 && error.data.stringCode === LogicExceptionList.PASSWORD_IS_EXPIRED) {
      new Notification().warning('Password id expired');
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("accessToken");
      
      window.location.hash = '#/changePassword'
    } else {
      new Notification().error(error.data.message);
    }
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  
  static resetPasswordError(error:ErrorFromService) {
    new Notification().error(error.data.message);

    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  static refreshError(error:ErrorFromService) {
    if (getApps().length > 0) {
      firebaseLogEvent(getAnalytics(), 'automatic_logout', {});
    }

    AuthController.logout();
    new Notification().warning('session ended');
    const preferredLoginUrl = localStorage.getItem('preferredLoginPage');
    if(preferredLoginUrl) {
      window.location.hash = `#${preferredLoginUrl}`;
    } else {
      window.location.hash = '#/login'
    }

    new Notification().error(error.data.message);

    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }

  static mfaGenerateError(error:ErrorFromService) {
    new Notification().error(error.data.message);

    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  
  static mfaVerifyError(error:ErrorFromService) {
    new Notification().error(error.data.message);

    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  
  static mfaLoginError(error:ErrorFromService) {
    new Notification().error(error.data.message);

    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  static changePasswordError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  static changeAgencyAccountPasswordError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  static resendConfirmationEmailError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }

  static getChatError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  static createChatError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  static closeChatError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  static reopenChatError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  static newMessageError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }

  static getNursingHomeError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  static createNursingHomeError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  static getAvailableNursingHomesError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  static getOrganizationsError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  
  static editNursingHomeError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }

  static editNursingHomePhotoError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }


  static createResidentError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  static editResidentError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  static deletResidentPhoto(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  static disableResident(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  static createFamilyMemberError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }

  static addFamilyMemberError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  
  static editFamilyMemberError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }

  static editFamilyMemberEmailError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  
  static updateResidentPhotoError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  static disableFamilyMember(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  static enableFamilyMember(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  static getAllUsers(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  static getResidentsError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  static getResidentsCountError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  static getDisabledResidentsError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  
  static getResidentError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  static downloadResidentUpdateHistoryError(error: AxiosError) {
    const decodedMessage = JSON.parse(new TextDecoder().decode(new Uint8Array(error.response?.data as ArrayBuffer)));
    new Notification().error(decodedMessage.message);
    
    return decodedMessage.message ? new ReqError(decodedMessage.statusCode, decodedMessage.message) : error;
  }
  static downloadHomeUpdateHistoryError(error: AxiosError) {
    const decodedMessage = JSON.parse(new TextDecoder().decode(new Uint8Array(error.response?.data as ArrayBuffer)));
    new Notification().error(decodedMessage.message);
    
    return decodedMessage.message ? new ReqError(decodedMessage.statusCode, decodedMessage.message) : error;
  }
  static createResidentContactCommentError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  static deleteResidentContactCommentError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  static createUpdateError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  static updateUploadFilesError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  static updateUploadFileError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  
  static getResidentUpdatesError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  static getUpdateError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  static deleteUpdateError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  static patchUpdateError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  static getAllUpdatesError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  static getUpdateReadersError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }

  static getUpdatesSentCountError(error:ErrorFromService) {
    new Notification().error(error.data.message);

    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }

  static getProfileError(error:ErrorFromService) {
    new Notification().error(error.data.message);

    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  static updateProfileError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  static uploadToS3Error(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  static getImgError(error:ErrorFromService) {
/*     new Notification().error(error.data.message); */
    
/*     return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error; */
    return new ReqError(error.data.statusCode, error.data.message)
  }
  static updateProfilePhotoError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  static deleteFromS3Error(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  static setTimezoneError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  static supportError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  static addNewEmployeeError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  static getNamesError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  static editEmployeeError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  static verifyEmployeeError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  static disableEmployeeError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  static enableEmployeeError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  static resetPasswordEmployeeError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  static forgotPasswordEmployeeError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  static createEmployeePhotoError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  static updateEmployeePhotoError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  static deleteEmployeePhotoError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  static getAgencyAccountsError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  static addNewAgencyAccountError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  
  static disableAgencyAccountError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  static enableAgencyAccountError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  static getAnnouncements(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  static createAnnouncement(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  static getAnnouncementPhotoUploadLink(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  static getAnnouncementDocumentUploadLink(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }

  static deleteAnnouncementPhoto(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }

  static deleteAnnouncementDocument(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }

  static deleteAnnouncement(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  static editAnnouncement(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  static createNursingHomeAccessKeys(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  static getNursingHomeAccessKeys(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  static getNursingHomeAccessKeysError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  static createNursingHomeAccessKeysError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  static updateNursingHomeAccessKeysError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }

  static resetFamilyMemberPasswordError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }

  static changeFamilyMemberEmailError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }

  static nursingHomeInactiveFamilyMembersError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  
  static getUpdatesStatistic(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }

  static getNursingHomeActualStatisticError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }

  static getActualStatisticError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }

  static getFamilyMembersActivationStatisticError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
  
  static getEntryStatisticError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }

  static uploadResidentsFileError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }

  static inviteResidentsError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }

  static uploadStaffFileError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }

  static inviteStaffError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }

  static goLiveError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }

  static setGoLiveDateError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }

  static getFamilyMembersError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }

  static getEmployeesError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }

  static getNursingHomesError(error:ErrorFromService) {
    new Notification().error(error.data.message);
    
    return error.data.message ? new ReqError(error.data.statusCode, error.data.message) : error;
  }
}


interface ErrorFromService {
  data: {message: string, statusCode: number, stringCode: LogicExceptionList}
}

export enum LogicExceptionList {
  USER_NOT_FOUND = 'user_not_found',
  ACCESS_DENIED = 'access_denied',
  INVALID_VERIFICATION_CODE = 'invalid_verification_code',
  ACCOUNT_IS_NOT_CONFIRMED = 'account_is_not_confirmed',
  EMAIL_ALREADY_EXISTS = 'email_already_exists',
  WRONG_CREDENTIALS = 'wrong_credentials',
  ACCOUNT_ALREADY_CONFIRMED = 'account_already_confirmed',
  NURSING_HOME_NOT_FOUND = 'nursing_home_not_found',
  PERMISSIONS_NOT_FOUND = 'permissions_not_found',
  RESIDENT_NOT_FOUND = 'resident_not_found',
  CATEGORY_NOT_FOUND = 'category_not_found',
  UPDATE_NOT_FOUND = 'update_not_found',
  USER_ISNT_RELATIVE_OF_RESIDENT = 'user_isnt_relative_of_resident',
  UNABLE_TO_DELETE_COMPLETED_UPDATE = 'unable_to_delete_completed_update',
  CHAT_NOT_FOUND = 'chat_not_found',
  USER_CANNOT_INTERACT_WITH_CHAT = 'user_cannot_interact_with_chat',
  CHAT_CLOSED = 'chat_closed',
  CHAT_CANNOT_START = 'chat_cannot_start',
  CHAT_ALREADY_EXISTS = 'chat_already_exists',
  TIMETABLE_EVENT_NOT_FOUND = 'timetable_not_found',
  ACCOUNT_DISABLED = 'account_disabled',
  UNAUTHORIZED = 'unauthorized',
  NOT_ENOUGH_PERMISSIONS = 'not_enough_permissions',
  INVALID_ACCESS_KEY = 'invalid_access_key',
  PASSWORD_IS_EXPIRED = 'password_is_expired',
}