import { useHistory } from "react-router-dom";
import styles from "./blockNewUpdatesModal.module.scss";
import { observer } from "mobx-react-lite";
import Modal from "@components/UI/modal/modal";
import Button from "@components/UI/buttons/button/button";
import { ContactTypeEnum } from "@source/api/models/allUsersModels";
import residentsStore from "@store/residentsStore";
import {ResidentIntegrationService} from "@source/api/models/residentModel";

const BlockNewUpdatesModal = observer((props: BlockNewUpdatesModalProps) => {
  const {} = props;
  const router = useHistory();


  const getActiveResidentPrimaryContact = () => {
    return residentsStore.getModalBlockNewUpdatesResident()?.familyRelations.find((familyRelation) => familyRelation.contactType === ContactTypeEnum.primary);
  }

  const hasPrimaryInIntegration = () => {
        return !getActiveResidentPrimaryContact() &&
            residentsStore.getModalBlockNewUpdatesResident()?.integration?.hasPrimaryWithoutEmail &&
            residentsStore.getModalBlockNewUpdatesResident()?.integration?.service === ResidentIntegrationService.E_CASE
  }

  const isChoseNotUseApp = () => {
    return ((!!getActiveResidentPrimaryContact()?.familyMember.loginDate) && getActiveResidentPrimaryContact()?.familyMember.account.isTemporaryPassword) || getActiveResidentPrimaryContact()?.familyMember.account.nonParticipant;
  }

  const isPublicTrustee = () => {
      const primary = residentsStore.getModalBlockNewUpdatesResident()?.familyRelations.find((familyRelation) => familyRelation.contactType === ContactTypeEnum.primary);
      return primary?.relationship === 'Administrator' || primary?.relationship === 'Public Trustee'
  }

  const getModalText = () => {
      if(getActiveResidentPrimaryContact()) {
          if(isPublicTrustee()) {
              return (
                  <div className={styles.prmiraryContactNotActivated}>
              <span className={styles.row}>
                Updates for {residentsStore.getModalBlockNewUpdatesResident()?.firstName} {residentsStore.getModalBlockNewUpdatesResident()?.lastName} cannot be sent via Brenna.
              </span>
                      <span className={styles.row}>
                The Primary Contact is the Public Trustee.
              </span>
                  </div>
              )
          }
          return isChoseNotUseApp() ? (
              <div className={styles.prmiraryContactNotActivated}>
                <span className={styles.row}>
                    <span className={styles.row_bold}>{getActiveResidentPrimaryContact()?.familyMember.firstName} {getActiveResidentPrimaryContact()?.familyMember.lastName}</span>, the primary contact, has chosen not to use Brenna app.
                </span>
                  <span className={styles.row}>
                Updates for <span className={styles.row_bold}>{residentsStore.getModalBlockNewUpdatesResident()?.firstName} {residentsStore.getModalBlockNewUpdatesResident()?.lastName}</span> cannot be sent via Brenna.
                </span>
                  <span className={styles.row}>
                Please call <span className={styles.row_bold}>{getActiveResidentPrimaryContact()?.familyMember.firstName}</span> on <span className={styles.row_bold}>{getActiveResidentPrimaryContact()?.familyMember.phoneNumber}</span> with any updates on <span className={styles.row_bold}>{residentsStore.getModalBlockNewUpdatesResident()?.firstName}'s</span> condition.
                </span>
              </div>
          ) : (
              <div className={styles.prmiraryContactNotActivated}>
              <span className={styles.row}>
                Unfortunately you can’t create updates until the primary contact is activated.
              </span>
                  <span className={styles.row}>
                Please, contact <span className={styles.row_bold}>{getActiveResidentPrimaryContact()?.familyMember.firstName} {getActiveResidentPrimaryContact()?.familyMember.lastName}</span> using the following number <span className={styles.row_bold}>{getActiveResidentPrimaryContact()?.familyMember.phoneNumber}</span> to remind them about activation.
              </span>
              </div>
          )
      }
      if(hasPrimaryInIntegration()) {
          return (
              <div className={styles.prmiraryContactNotActivated}>
                 <span className={styles.row}>
                    Please go to eCase for the contact details for the primary contact.
                 </span>
                  <span className={styles.row}>
                     They do not have an email address so cannot be added into Brenna
                 </span>
              </div>
          )
      }
      return (
          <div className={styles.noPrimaryContact}>
            <span className={styles.row}>
              Updates for {residentsStore.getModalBlockNewUpdatesResident()?.firstName} {residentsStore.getModalBlockNewUpdatesResident()?.lastName} cannot be sent via Brenna.
            </span>
              <span className={styles.row}>
             There is no Primary Contact in Brenna for this Resident.
            </span>
              <span className={styles.row}>
              Please add in Primary Contact details if these are available.
            </span>
          </div>
      )
  }

  return(
      <Modal open={!!residentsStore.getModalBlockNewUpdatesResident()} className={styles.modalComment} close={() => residentsStore.setModalBlockNewUpdatesResident(null)}>
        <div className={styles.modalCantCreateUpdate}>
          { getModalText() }

          <Button
              className={styles.confirmBtn}
              onClick={() => residentsStore.setModalBlockNewUpdatesResident(null)}
          >
            Confirm
          </Button>
        </div>
      </Modal>
  )
})

export default BlockNewUpdatesModal;

interface BlockNewUpdatesModalProps {

}