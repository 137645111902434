import { memo } from "react";
import styles from "./modal.module.scss";
import { Dialog } from "@mui/material";
import Svg from "@source/components/UI/svg/svg";
import closeIcon from "@source/assets/imgs/close.svg";

export default memo((props: ModalProps) => {
  const {children, open, close, className, maxWidth=600} = props;

  return(
    <Dialog
      open={!!open}
      PaperProps={{
        style: {
          position: 'relative',
          width: '100%', 
          maxWidth: maxWidth, 
          borderRadius: 16, 
          margin: '0 16px',
          backgroundColor: '#fff', 
          overflow: 'unset',
        }
      }}>
        <div className={`${styles.modal} ${className}`}>
          {children}
        </div>

        {close
          ? <div onClick={() => close()} className={styles.modal_close}>
              <Svg src={closeIcon} width={12} height={12} color="#333"/>
            </div>
          : <></>}
    </Dialog>
  )
})

interface ModalProps {
  /** Modal inner */
  children: JSX.Element | string,

  /** Modal open status
   * @default false
   */
  open: boolean,

  /** close event handler */
  close?: () => void,

  /** Modal inner maxWidth */
  maxWidth?: number | string,

  className?: string,
}