import { api, URL } from "@source/api/http";
import { IAgencyLogInRequest, IAgencyLogInResponse, IAgencyRegisterRequest, IAgencyRegisterResponse, IChangeAgencyAccountPasswordReq, ILogInRequest, ILogInResponse, IRegisterResponse, MFAGenerateResponse, MFALoginReq, MFAVerifyReq } from "@source/api/models/authModels";
import ReqError from "@source/api/reqError";
import axios from "axios";
import InteractionObserver from "../interactionObserver";

export default class AuthService {
  static async login(body: ILogInRequest) {
    try {
      const response = (await api.post<ILogInResponse>('auth/employee/login', body)).data;
      return response;
    } catch (e: any) {
      throw ReqError.loginError(e.response)
    }
  }

  static async agencyLogin(body: IAgencyLogInRequest) {
    try {
      const response = (await api.post<IAgencyLogInResponse>('auth/agency/login', body)).data;
      return response;
    } catch (e: any) {
      throw ReqError.agencyLoginError(e.response)
    }
  }

  static async agencyRegister(body: IAgencyRegisterRequest) {
    try {
      const response = (await api.post<IAgencyRegisterResponse>('auth/agency/register', body)).data;
      return response;
    } catch (e: any) {
      throw ReqError.agencyRegisterError(e.response)
    }
  }

  static async resetPassword(oldPassword: string, newPassword: string, ) {
    try {
      const res = (await api.post('auth/reset-password', {oldPassword, newPassword})).data;

      return res.data;
    } catch (e: any) {
      throw ReqError.resetPasswordError(e.response)
    }
  }

  static async changePassword(email: string, password: string, oldPassword: string) {
    try {
      const res = (await api.patch('auth/employee/reset-expired-password', {email, password, oldPassword})).data;

      return res.data;
    } catch (e: any) {
      throw ReqError.changePasswordError(e.response)
    }
  }

  static async changeAgencyAccountPassword(agencyId: string, req: IChangeAgencyAccountPasswordReq) {
    try {
      const res = (await api.post(`auth/agency/${agencyId}/reset-password`, req)).data;
      return res.data;
    } catch (e: any) {
      throw ReqError.changeAgencyAccountPasswordError(e.response)
    }
  }

  static async resendConfirmationEmail(email: string) {
    try {
      const res = (await api.post('auth/register/resend', {email})).data;

      return res.data;
    } catch (e: any) {
      throw ReqError.resendConfirmationEmailError(e.response)
    }
  }

  static async refresh(refreshToken: string) {
    try {
      const response = await axios.post(`${URL}/auth/refresh-tokens`, {
        token: refreshToken
      });
      localStorage.removeItem('accessToken');

      localStorage.setItem("refreshToken", response.data.refreshToken);
      localStorage.setItem("accessToken", response.data.accessToken);

      new InteractionObserver().updateInteractionObserversTime();
      return response.data;
    } catch (e: any) {
      throw ReqError.refreshError(e.response)
    }
  }

  static async mfaGenerate(email: string) {
    try {
      const response = (await api.post<MFAGenerateResponse>('auth/mfa/generate', { email })).data;
      return response;
    } catch (e: any) {
      throw ReqError.mfaGenerateError(e.response)
    }
  }

  static async mfaVerify(req: MFAVerifyReq) {
    try {
      const response = (await api.post<MFAGenerateResponse>('auth/mfa/verify', req)).data;
      return response;
    } catch (e: any) {
      throw ReqError.mfaVerifyError(e.response)
    }
  }

  static async mfaLogin(req: MFALoginReq) {
    try {
      const response = (await api.post<ILogInResponse>('auth/mfa/login', req)).data;
      return response;
    } catch (e: any) {
      throw ReqError.mfaLoginError(e.response)
    }
  }

    static async loginAzureAD(req: any) {
      try {
        const response = (await api.post<ILogInResponse>('auth/employee/sso/azure', req)).data;
        return response;
      } catch (e: any) {
        throw ReqError.mfaLoginError(e.response)
      }
    }
}