import {memo, useEffect, useState} from "react";
import {CircularProgress} from "@mui/material";

const LoaderComponent = memo((props: LoaderProps) => {
    const {children, isLoading, size } = props;

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (typeof isLoading !== 'undefined') {
            setLoading(isLoading);
        }
    }, [isLoading]);

    return (
        <>

            {loading ?
                <CircularProgress size={size ? size : 21}/>
                :
                <>
                    {children}
                </>
            }
        </>
    )
})

export interface LoaderProps {
    children?: JSX.Element | JSX.Element[] | string,
    className?: string,
    isLoading?: boolean
    size?: number
}

export default LoaderComponent;