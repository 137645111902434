import ReqError from "@source/api/reqError";
import { api } from "@source/api/http";
import { IGetUserResponse, ISupportReq, ISupportRes, IUpdateProfileInfoReq } from "@source/api/models/userModels";
import axios, { AxiosResponse } from "axios";

export default class UserService {
  static async getProfile() {
    try {
      return (await api.get<IGetUserResponse>('profile')).data
    } catch (e: any) {
      throw ReqError.getProfileError(e.response)
    }
  }

  static async updateProfile(updateInfo: IUpdateProfileInfoReq) {
    try {
      return (await api.patch('profile', {...updateInfo})).data
    } catch (e: any) {
      throw ReqError.updateProfileError(e.response)
    }
  }

  static async updateProfilePhoto(req: FormData): Promise<AxiosResponse<any>> {
    try {
      return(await api.patch("profile/photo", req)).data;
    } catch (e: any) {
      throw ReqError.updateProfilePhotoError(e.response)
    }
  }

  static async deleteFromS3(): Promise<AxiosResponse<any>> {
    try {
      return(await api.delete("profile/photo")).data;
    } catch (e: any) {
      throw ReqError.deleteFromS3Error(e.response)
    }
  }

  static async setTimezone(timezone: string): Promise<AxiosResponse<any>> {
    try {
      return(await api.put("profile/settings", {timezone})).data;
    } catch (e: any) {
      throw ReqError.setTimezoneError(e.response)
    }
  }

  static async support(req: ISupportReq): Promise<AxiosResponse<ISupportRes>> {
    try {
      return(await api.post("support", req)).data;
    } catch (e: any) {
      throw ReqError.supportError(e.response)
    }
  }
}