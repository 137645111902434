import { Nutritional_SubSections, Resident_Of_The_Day_SubSections, Specialist_Appointment_SubSections } from './../updateSchema/updateFormModels';
import { 
  Fall_SubSections,
  Medication_Incidents_SubSections,
  Other_SubSections,
  Pain_SubSections,
  Return_From_Hospital_SubSections,
  Sections, 
  SubSections, 
  UpdatePriorities } from "../updateSchema/updateFormModels";
import { IMainUpdateForm } from '../updateTab';

export function isSectionHasSubsection(section: Sections) {
  return section !== Sections.Fall && 
         section !== Sections.Medication_Incidents && 
         section !== Sections.Pain && 
         section !== Sections.Other &&
         section !== Sections.Return_From_Hospital &&
         section !== Sections.Nutritional &&
         section !== Sections.Resident_Of_The_Day
}

export function isUpdateHasCustomName(section: Sections | '') {
  return section === Sections.Other;
}

export function isUpdateHighPriority(priority: UpdatePriorities | "") {
  return priority === UpdatePriorities.High;
}


export enum PhoneCall {
  HAS_BEEN_CALLED = 'has_been_called',
  ATTEMPTED_TO_CALL = 'attempted_to_call',
  NOT_REQUIRED = 'not_required',
}

export const PhoneCallRadioNames = {
  [PhoneCall.HAS_BEEN_CALLED]: "Primary or second contact was called and an update provided over the phone",
  [PhoneCall.ATTEMPTED_TO_CALL]: "I attempted to call primary or second contact and couldn’t reach them",
  [PhoneCall.NOT_REQUIRED]: "Phone call not required",
}

export const PhoneCallNames = {
  [PhoneCall.HAS_BEEN_CALLED]: "Phone call made to family: Primary or second contact was called and an update provided over the phone",
  [PhoneCall.ATTEMPTED_TO_CALL]: "Phone call made to family: I attempted to call primary or second contact and couldn’t reach them",
  [PhoneCall.NOT_REQUIRED]: "Phone call not required",
}

export function getSummaryUpdateName(mainValues: IMainUpdateForm) {
  if(isUpdateHasCustomName(mainValues.update)) {
    return mainValues.viewUpdateName
  } else {
    return mainValues.subCategory
  }
}

export function getEmptySubsection(section: Sections) {
  switch (section) {
    case Sections.Fall: return Fall_SubSections.Fall;
    case Sections.Medication_Incidents: return Medication_Incidents_SubSections.Medication_Incidents;
    case Sections.Pain: return Pain_SubSections.Pain;
    case Sections.Other: return Other_SubSections.Other;
    case Sections.Return_From_Hospital: return Return_From_Hospital_SubSections.Return_From_Hospital;
    case Sections.Nutritional: return Nutritional_SubSections.Nutritional;
    case Sections.Resident_Of_The_Day: return Resident_Of_The_Day_SubSections.Resident_Of_The_Day;
  }
}

export function getUpdatePriority(section: Sections, subSection?: SubSections) {
  switch(section) {
    case Sections.Request_For_Items: return UpdatePriorities.Low;
    case Sections.Specialist_Appointment: return UpdatePriorities.Low
    case Sections.Resident_Of_The_Day: return UpdatePriorities.Low;
    case Sections.Nutritional: return UpdatePriorities.Low;
    default: return ''
  }
}

export function isDateTimeValue(section: Sections | '', subSection?: SubSections) {
  if(section === Sections.Resident_Of_The_Day) {
    return false
  }

  return true;
}