import {
  useEffect,
  useRef,
  useState,
} from "react";
import { useHistory, useParams } from "react-router-dom";
import styles from "./historyChatTab.module.scss";
import useFetching from "@source/hooks/useFetching";
import { observer } from "mobx-react-lite";
import ChatController from "@source/api/controllers/chatController";
import TextInput from "@source/components/UI/inputs/textInput/textInput";
import guestPhoto from '@source/assets/imgs/guestPhoto.svg';
import iconArrowMessage from '@source/assets/icons/iconArrowMessage.svg';
import iconMessageRead from '@source/assets/icons/iconMessageRead.svg';
import iconMessageUnread from '@source/assets/icons/iconMessageUnread.svg';
import moment from "moment";
import SearchInput from "@source/components/UI/inputs/searchInput/searchInput";
import { IFillVariant } from "@source/utils/enums";
import Svg from "@source/components/UI/svg/svg";
import { IGetChatsRequest } from "@source/api/models/residentModel";
import nursingHomeStore from "@source/store/nursingHomeStore";
import Loader from "@source/components/UI/loader/loader";
import chatsStore from "@source/store/chatsStore";
import { stringToHslColor } from "@source/utils/utils";
import userStore from "@source/store/userStore";

const HistoryChatTab = observer((props: any) => {
  // const [onChat, onNewMessage] = useSocket();

  const [newMessageChat, newMessageChatLoading, newMessageChatError] = useFetching<ReturnType<typeof ChatController.newMessageChat>>(ChatController.newMessageChat);
  const [getChats, getChatsLoading, getChatsError] = useFetching<ReturnType<typeof ChatController.getChats>>(ChatController.getChats);

  const messagesList = useRef<HTMLDivElement>(null);

  const router = useHistory();
  const params = useParams<{
    updateId: string;
    residentId: string;
    chatId: string;
  }>();

  const [newMessage, setNewMessage] = useState("");
  const [searchChat, setSearchChat] = useState("");
  const [filtersDirty, setFiltersDirty] = useState(false);

  useEffect(() => {
    return () => {
      chatsStore.setActiveChat(null);
      chatsStore.setChats(null);
    }
  }, [])

  useEffect(() => {
    let isMounted = true;

    const fetchChats = async () => {
      const request: IGetChatsRequest = {
        nursingHomeId: nursingHomeStore.getId(),
      }

      const response = await getChats(request);

      if(response) {
        if (isMounted) {
          chatsStore.setChats(response);
        }
      }
    }
    
    chatsStore.setActiveChat(null);
    resetFilters();
    fetchChats();

    return () => {
      isMounted = false;
    }
  }, [nursingHomeStore.activeNursingHome])

  useEffect(() => {
    if (chatsStore.chats && filtersDirty) {
      const fetchChats = async () => {
        const request: IGetChatsRequest = {
          nursingHomeId: nursingHomeStore.getId(),
          searchString: searchChat,
        };

        const response = await getChats(request);
  
        if (response) {
          chatsStore.setChats(response);
        }
      };

      const timer = setTimeout(() => {
        fetchChats();
      }, 500)

      return () => {
        clearTimeout(timer);
      }
    }
  }, [searchChat]);

  useEffect(() => {
    scrollToBottom();
  });

  const scrollToBottom = () => {
    messagesList.current?.scrollIntoView({ behavior: "auto" });
  };

  const sendNewMessage = () => {
    // onNewMessage(newMessage);
    setNewMessage('');
  }

  const onInputKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      if (newMessage.trim()) {
        sendNewMessage();
      }
    }
  }

  const getSenderRelationship = (sender: any) => {
    return sender.accountType === "Employee"
      ? ""
      : `(${sender?.relationship || "Son"})`;
  };

  const setActiveChat = (chat: any) => {
    // onChat(chat);
  }

  const isCoupleFamilyMembers = (chat: any) => {
    return chat.resident.familyRelations.length > 1;
  }

  const resetFilters = () => {
    setSearchChat('');
    setFiltersDirty(false);
  }

  return (
    <div className={styles.historyChat}>
      <div className={styles.sideBar}>
        <div className={styles.searchChat}>
          <SearchInput className={styles.search} value={searchChat}
            onChange={(e) => {
              setSearchChat(e.target.value)
              setFiltersDirty(true);
            }} fill={IFillVariant.Blank} 
            placeHolder="Search"/>
        </div>

        <div className={styles.chatList}>
          {
            getChatsLoading ?
              <div className={styles.loader}>
                <Loader width={40} height={40} borderWidth={4}/>
              </div>
              :
              chatsStore.chats?.length && chatsStore.chats.map((chat) => (
                <div className={`${styles.chat} ${chatsStore.activeChat && chatsStore.activeChat._id === chat._id ? styles.chat_active : ""}`} onClick={() => setActiveChat(chat)} key={chat._id}>
                  <div className={styles.content}>
                    {
                      isCoupleFamilyMembers(chat) || !chat.resident.familyRelations.length ?
                        <div className={styles.noPhoto} style={{backgroundColor: stringToHslColor(chat.resident.lastName)}}>
                          {chat.resident.lastName[0]?.toUpperCase() || ''}
                        </div>
                        :
                        chat.resident.familyRelations[0]?.familyMember.photo ? 
                          <img className={styles.photo} src={chat.resident.familyRelations[0]?.familyMember.photo} alt="senderPhoto"/>
                          :
                          <div className={styles.noPhoto} style={{backgroundColor: stringToHslColor(chat.resident.firstName + chat.resident.lastName)}}>
                            {chat.resident.familyRelations[0]?.familyMember?.firstName[0]?.toUpperCase() || ''} {chat.resident.familyRelations[0]?.familyMember?.lastName[0]?.toUpperCase() || ''}
                          </div>
                    }

                    <div className={styles.body}>
                      <div className={styles.textInfo}>
                        <span className={styles.name}>
                          {
                            chat.resident.familyRelations.length > 0 ?
                              isCoupleFamilyMembers(chat) ?
                              `${chat.resident.lastName} Family`
                              :
                              `${chat.resident.familyRelations[0].familyMember.firstName} ${chat.resident.familyRelations[0].familyMember.lastName}`
                            :
                            `${chat.resident.firstName} ${chat.resident.lastName}`
                          }
                        </span>

                        <span className={styles.lastMessage}>{chat.messages.length && chat.messages.at(-1)?.body.text || ""}</span>
                      </div>

                      <div className={styles.otherInfo}>
                        <span className={styles.date}>
                          {chat.messages.length ? moment(chat.messages[0]?.date).format('hh:mm A') : ""}
                        </span>

                        {
                          chat.unreadMessageCount > 0 ?
                            <div className={styles.unreadMessages}>{chat.unreadMessageCount}</div>
                            :
                            <></>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              ))
          }
        </div>
      </div>

      {
        chatsStore.activeChat ?
          <div className={styles.chat}>
            <div className={styles.header}>
              <div className={styles.left}>
                <div className={styles.familyMember}>
                  {
                      <div className={styles.coupleMembersPhoto}>
                        {
                          chatsStore.activeChat.resident.familyRelations.map((familyRelation, index) => (
                            familyRelation.familyMember.photo ?
                              <img className={styles.photo} src={familyRelation.familyMember.photo} alt="senderPhoto" style={{zIndex: 5 - index }} key={familyRelation._id}/>
                              :
                              <div 
                                className={styles.noPhoto} 
                                style={{backgroundColor: stringToHslColor(familyRelation.familyMember.firstName + familyRelation.familyMember.lastName), zIndex: 5 - index }} 
                                key={familyRelation._id}
                              >
                                {familyRelation.familyMember.firstName[0]?.toUpperCase() || ''} {familyRelation.familyMember.lastName[0]?.toUpperCase() || ''}
                              </div>
                          ))
                        }
                      </div>
                  }

                  <div className={styles.textInfo}>
                    <span className={styles.name}>
                      {
                        chatsStore.activeChat.resident.familyRelations.length > 0 ?
                          isCoupleFamilyMembers(chatsStore.activeChat) ?
                          `${chatsStore.activeChat.resident.lastName} Family`
                          :
                          `${chatsStore.activeChat.resident.familyRelations[0].familyMember.firstName} ${chatsStore.activeChat.resident.familyRelations[0].familyMember.lastName}`
                        :
                        `${chatsStore.activeChat.resident.firstName} ${chatsStore.activeChat.resident.lastName}`
                      }
                    </span>

                    <span className={styles.role}>
                      {
                        isCoupleFamilyMembers(chatsStore.activeChat) ?
                        `${chatsStore.activeChat.resident.familyRelations.length} participants`
                        :
                        chatsStore.activeChat.resident.familyRelations[0]?.relationship
                      }
                    </span>
                  </div>
                </div>
              </div>
              
              <div className={styles.right}>
                <div className={styles.resident}>
                  <div className={styles.textInfo}>
                    <span className={styles.name}>
                      {chatsStore.activeChat.resident.firstName} {chatsStore.activeChat.resident.lastName}
                    </span>

                    <span className={styles.place}>
                      Section <span className={styles.bold}>{chatsStore.activeChat.resident.section}</span> - Room <span className={styles.bold}>{chatsStore.activeChat.resident.room}</span>
                    </span>
                  </div>

                  {
                    chatsStore.activeChat.resident.photo ? 
                      <img className={styles.photo} src={chatsStore.activeChat.resident.photo} alt="senderPhoto"/>
                      :
                      <div className={styles.noPhoto} style={{backgroundColor: stringToHslColor(chatsStore.activeChat.resident.firstName + chatsStore.activeChat.resident.lastName)}}>
                        {chatsStore.activeChat.resident.firstName[0]?.toUpperCase() || ''} {chatsStore.activeChat.resident.lastName[0]?.toUpperCase() || ''}
                      </div>
                  }
                </div>
              </div>
            </div>

            <div className={styles.list} >
              {
                chatsStore.activeChat.messages.map((message: any) => (
                  <div className={`${styles.message} ${message.sender._id === userStore.getId() && styles.message_right}`} key={message._id}>
                    <div className={`${styles.messageContainer}`}>
                      {
                        message.sender.photo ? 
                          <img className={styles.photo} src={message.sender.photo} alt="senderPhoto"/>
                          :
                          <div className={styles.noPhoto} style={{backgroundColor: stringToHslColor(message.sender.firstName + message.sender.lastName)}}>
                            {message.sender.firstName[0]?.toUpperCase() || ''} {message.sender.lastName[0]?.toUpperCase() || ''}
                          </div>
                      }

                      <div className={styles.body}>
                        <div className={styles.text}>
                          <span className={styles.sender}>{message.sender.firstName} {message.sender.lastName} {getSenderRelationship(message.sender)}</span>
                          
                          <span className={styles.message}>{message.body.text}</span>
                        </div>

                        <div  className={styles.timeRead}>
                          <span className={styles.time}>{moment(message.dispatchTime).format("hh:mm A")}</span>

                          {
                            message.sender._id === userStore.getId() ?
                            <Svg className={styles.iconRead} width={16} src={message.status === "viewed" ? iconMessageRead : iconMessageUnread} color="#7E808F"/>
                            :
                            <></>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              }
              <div ref={messagesList}/>
            </div>

            {
              chatsStore.activeChat.status === "open" || chatsStore.activeChat.status === "reopened" ?
                <div className={styles.writeMessage}>
                  <TextInput id="newMessage" 
                    label="" placeholder="Write message here"
                    className={styles.input}
                    handleChange={(e) => {setNewMessage(e.target.value)}} 
                    value={newMessage} 
                    onKeyDown={onInputKeyDown}
                    />

                    <div className={styles.newMessage} onClick={sendNewMessage}>
                      <Svg className={styles.icon} width={16} src={iconArrowMessage} color="#ffffff"/>
                    </div>
                </div>
                :
                <></>
            }
          </div>
        :
        <></>
      }
    </div>
  );
});

export default HistoryChatTab;

interface HistoryChatTabProps {}
