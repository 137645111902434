import { IGetUpdateResponse, IGetUpdatesDataResponse, IGetReadingData } from "@source/api/models/residentModel";
import { makeAutoObservable } from "mobx";

class UpdatesStore {
  updates: IGetUpdateResponse[] = [];
  totalCount: number = 0;
  totalSent: number = 0;

  constructor() {
    makeAutoObservable(this);
  }

  setUpdatesData(data: IGetUpdatesDataResponse) {
    this.updates = data.updates;
    this.totalCount = data.totalCount;
  }

  setUpdates(updates: IGetUpdateResponse[]) {
    this.updates = updates;
  }

  addUpdatesData(data: IGetUpdatesDataResponse) {
    this.updates = this.updates.concat(data.updates);
    this.totalCount = data.totalCount;
  }
  
  markUpdateRead(data: IGetReadingData) {
    this.setUpdates(this.updates.map(update => {
      if (update._id === data.id) {
        return { ...update, isFamilyMemberRead: true, updateReaders: data.updateReaders };
      }
      return update;
    }))
  }

  getUpdates() {
    return this.updates;
  }

  setTotalSent(count: number) {
    this.totalSent = count;
  }

  getTotalSent() {
    return this.totalSent;
  }

  deleteUpdate(updateId: string) {
    this.updates = this.updates.filter(update => update._id !== updateId);
  }
}

export default new UpdatesStore();