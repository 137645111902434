import styles from "./loginTechSupportSentModal.module.scss";
import { observer } from "mobx-react-lite";
import Modal from "@components/UI/modal/modal";
import iconThankYouFeedback from "@source/assets/icons/iconThankYouFeedback.svg";

const LoginTechSupportSentModal = observer((props: LoginTechSupportSentModalProps) => {
  const { open, setOpen } = props;
  
  return(
    <Modal open={open} className={styles.loginTechSupportSentModal} maxWidth={388} close={setOpen}>
      <div className={styles.loginTechSupportSentModalContainer}>
        <div className={styles.content}>
          <img className={styles.icon} src={iconThankYouFeedback} alt="expired"/>

          <div className={styles.textInfo}> 
            <span className={styles.title}>Thank you for your request.</span>

            <span className={styles.text}>The Brenna team will be in contact soon.</span>
          </div>
        </div>
      </div>
    </Modal>
  )
})

export default LoginTechSupportSentModal;

interface LoginTechSupportSentModalProps {
  open: boolean;
  setOpen: () => void;
}
