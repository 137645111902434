import styles from './statisticTab.module.scss';
import React, {useEffect, useState} from "react";
import residentsStore from "@store/residentsStore";
import nursingHomeStore from "@store/nursingHomeStore";
import DateRangePicker from "@components/UI/inputs/dateRangePicker/dateRangePicker";
import {IFillVariant} from "@source/utils/enums";
import {useFormik} from "formik";
import * as Yup from "yup";
import useFetching from "@source/hooks/useFetching";
import StatisticController from "@source/api/controllers/statisticController";
import moment from "moment/moment";
import LoaderComponent from "@components/loader/loader";
import {observer} from "mobx-react-lite";

const StatisticTab = observer((props: any) => {
    const residentCount = residentsStore.getResidentsCount();

    const [getStats, isLoading, error] = useFetching<ReturnType<typeof StatisticController.getNumbersStatistic>>(StatisticController.getNumbersStatistic);
    const [requestData, setRequestData] = useState<any>({});

    const [filtersDirty, setFiltersDirty] = useState(false);

    const validateSchema = Yup.object({
    });

    const mainFormik = useFormik({
        validationSchema: validateSchema,
        validateOnChange: true,
        validateOnBlur: true,
        initialValues: {
            dateRange: null,
        },
        onSubmit: () => {

        }
    });

    useEffect(() => {
        const fetchStats = async () => {
            const { dateRange} = mainFormik.values;
            const req = {
                startDate: dateRange ? moment(dateRange[0]).startOf("day").toISOString() : "",
                endDate: dateRange && dateRange[1] ? moment(dateRange[1]).endOf("day").toISOString() : "",
                nursingHomeId: nursingHomeStore.activeNursingHome?._id,
            }
            if(residentCount) {
                req['residentNumbers'] = 0;
            } else {
                req['residentNumbers'] = 1;
            }

            if(dateRange) {
                req['residentNumbers'] = 1;
            }

            const resp = await getStats(req)
            setRequestData(resp);
        }
        fetchStats()
    }, [nursingHomeStore.activeNursingHome?._id, mainFormik.values])

    const onDateRangeChange = (dateRange: [Date, Date] | null) => {
        mainFormik.setFieldValue('dateRange', dateRange);

        if (!filtersDirty) {
            setFiltersDirty(true);
        }
    }

    const {errors, values, touched, handleBlur, handleChange, handleSubmit, isValid} = mainFormik;
    // @ts-ignore
    return (
        <div className={styles.statisticTab}>
            <div className={styles.filter}>
                <DateRangePicker className={styles.dateRange} value={values.dateRange} maxDate={new Date()} minDate={nursingHomeStore.activeNursingHome?.endOnboardingDate ? new Date(nursingHomeStore.activeNursingHome?.endOnboardingDate) : undefined}
                                 onChange={onDateRangeChange} fill={IFillVariant.Fill} id="dateRange"/>
                <span className={styles.textHeader}>
                    Number of days Brenna use: <span
                    className={styles.number}>{requestData ? requestData['Number of days Brenna use'] : ''}</span>
                </span>
            </div>
            <div className={styles.content}>
                <LoaderComponent isLoading={isLoading}>
                    <div className={styles.updateColumn}>
                    <span className={styles.text}>
                        Update sent to families: <span
                        className={styles.number}>{requestData ? requestData['Updates sent to families'] : ''}</span>
                    </span>
                        <span className={styles.text}>
                        Total Readings: <span
                            className={styles.number}>{requestData ? requestData['Total Readings'] : ''}</span>
                    </span>
                        <span className={styles.text}>
                        Read Ratio: <span
                            className={styles.number}>{requestData ? requestData['Read Ratio'] : ''}</span>
                    </span>
                        <span className={styles.text}>
                        Average updates per day: <span
                            className={styles.number}>{requestData ? (requestData['Updates sent to families'] / requestData['Number of days Brenna use']).toFixed(3) : ''}</span>
                    </span>
                        <span className={styles.text}>
                        Average daily updates per resident: <span
                            className={styles.number}>{requestData ? ((requestData['Updates sent to families'] / requestData['Number of days Brenna use']) / (residentCount ? residentCount.totalCount : requestData['Total Residents'])).toFixed(3) : ''}</span>
                    </span>
                    </div>
                    <div className={styles.line}></div>
                    <div className={styles.otherColumn}>
                    <span className={styles.text}>
                        Employee using Brenna: <span
                        className={styles.number}>{requestData ? requestData['Employees using Brenna'] : ''}</span>
                    </span>
                        <span className={styles.text}>
                        Resident Active: <span className={styles.number}>
                        {
                            //@ts-ignore
                            residentCount && !mainFormik?.values?.dateRange ?

                                residentCount.activeCount
                                :
                                (requestData ? requestData['Residents Active'] : '')
                        }
                        </span>
                    </span>
                        <span className={styles.text}>
                        Residents Without Primary Contact: <span
                            className={styles.number}>
                            {
                                //@ts-ignore
                                residentCount && !mainFormik?.values?.dateRange ?
                                    residentCount.totalCount - residentCount.totalCountWithPrimary
                                    :
                                    (requestData ? requestData['Residents Without Primary Contact'] : '')
                            }</span>
                    </span>
                        <span className={styles.text}>
                        Total Residents: <span
                            className={styles.number}>
                            {
                                //@ts-ignore
                                residentCount && !mainFormik?.values?.dateRange ?
                                    residentCount.totalCount
                                    :
                                    (requestData ? requestData['Total Residents'] : '')
                            }</span>
                    </span>
                        <span className={styles.text}>
                        Family Members: <span
                            className={styles.number}>{requestData ? requestData['Family Members'] : ''}</span>
                    </span>
                    </div>
                </LoaderComponent>
            </div>
        </div>
    )
        ;
})

export default StatisticTab;
