import { memo, useEffect, useRef, useState } from "react";
import Skeleton from '@mui/material/Skeleton';
import styles from "./cacheImg.module.scss";
import useCachePhoto from "@source/hooks/useCachePhoto";
import useOnScreen from "@source/hooks/useOnScreen";

const CacheImg = memo((props: CacheImgProps) => {
  const {url, className, alt, imgClassName, children, skeletonProps} = props;
  
  const ref = useRef<HTMLDivElement | null>(null);
  const isVisible = useOnScreen(ref);

  
  const [needLoad, setNeedLoad] = useState(isVisible);
  useEffect(() => {
    if(isVisible) {
      setNeedLoad(true);
    }
  }, [isVisible])

  const [isLoading, photo, error] = useCachePhoto(url, needLoad);

  return (
    <div ref={ref} className={`${styles.img} ${className}`}>
      {isLoading
        ? <Skeleton animation="wave" variant="rectangular" {...skeletonProps}>
            <div className={`${styles.photo} ${imgClassName}`}></div>
          </Skeleton>
        : photo 
          ? <img 
              src={photo} 
              className={`
                ${styles.photo} 
                ${imgClassName} 
                ${!isVisible ? styles.invisible : ''}
              `} 
              alt={alt || 'img'} />
          : children}
    </div>
  )
})

interface CacheImgProps {
  url?: string,
  className?: string,
  alt?: string,
  imgClassName?: string,
  skeletonProps?: {
    width?: number | string,
    height?: number | string,
    style?: any,
    variant?: 'circular' | 'rectangular',
    animation?: 'pulse' | 'wave' | false
  },
  children?: JSX.Element | string | never[],
}

export default CacheImg;