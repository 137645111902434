import FileController from '@source/api/controllers/fileController';
import { useEffect, useState } from "react";
import useFetching from "./useFetching";

export default function useCachePhoto(url?: string, startLoading = true): [boolean, string, string] {
  const [photo, setPhoto] = useState<string | null>(null);
  const [uploadPhoto, isLoading, error] = useFetching<ReturnType<typeof FileController.getImgFromS3>>(FileController.getImgFromS3);

  useEffect(() => {
    if(url && startLoading) {
     const fetch = async () => {
      const photo = await uploadPhoto(url);

      if(photo) {
        setPhoto(photo)
      }
     } 

     fetch();
    }
  }, [url, startLoading])

  return [isLoading, photo || '', error];
}