import { ChangeEvent, memo, useEffect, useRef, useState } from "react";
import styles from "./fileInput.module.scss";
import Button from "../../buttons/button/button";
import Svg from "../../svg/svg";
import iconUploadUserPhoto from "@source/assets/icons/iconUploadUserPhoto.svg";
import iconCloseBorder from "@source/assets/icons/iconCloseBorder.svg";
import iconTxtFormat from "@source/assets/icons/iconTxtFormat.svg";
import iconPdfFormat from "@source/assets/icons/iconPdfFormat.svg";
import Loader from "../../loader/loader";

export default memo((props: FileInputProps) => {
  const { 
    selectedFile,
    setSelectedFile,
    className,
    btnClassName,
    uploadImg,
    imgColor, 
    imgWidth, 
    imgHeight, 
    preview, 
    loading, 
    onDeleteFile, 
    acceptFormats = ".png, .jpg, .jpeg, .gif", 
    areaText,
    areaTextClassName,
    subAreaText,
    subAreaTextClassName,
    withoutPreview,
    disabled
  } = props;

  const [inputPreview, setInputPreview] = useState<string | null>(preview || null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  
  useEffect(() => {
    if (containerRef?.current) {
      containerRef.current.ondragover = containerRef.current.ondragenter = (
        e: DragEvent
      ) => {
        e.preventDefault();
      };
      containerRef.current.ondrop = (e: DragEvent) => {
        if (inputRef?.current && e.dataTransfer) {
          inputRef.current.files = e.dataTransfer.files;

          const dT = new DataTransfer();
          dT.items.add(e.dataTransfer.files[0]);

          inputRef.current.files = dT.files;
          setSelectedFile(e.dataTransfer.files[0]);

          e.preventDefault();
        }
      };
    }
    const container = containerRef.current;
    return () => {
      if (container) {
        //because when this function starts, containerRef.current will be empty
        container.ondragover = container.ondragenter = container.ondrop = null;
      }
    };
  }, [containerRef, containerRef.current, setSelectedFile]); // eslint-disable-line react-hooks/exhaustive-deps
  //need to add listeners to containerRef when containerRef.current is updated

  useEffect(() => {
    if (!selectedFile) {
      setInputPreview(null);
      return;
    }
    
    const objectUrl = URL.createObjectURL(selectedFile);
    setInputPreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  useEffect(() => {
    if (preview) {
      setInputPreview(preview)
    }
  }, [preview])

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || !e.target.files.length) {
      setSelectedFile(null);
      return;
    }
    setSelectedFile(e.target.files[0]);
  };

  const onCloseIcon = async () => {
    onDeleteFile && await onDeleteFile();
    setSelectedFile(null);
    setInputPreview(null);
  }
  
  return (
    <div ref={containerRef} className={`${className} ${styles.fileInput} ${loading && styles.fileInput_loading}`}>
      {
          inputPreview && !withoutPreview ? 
          <div className={`${styles.fileInput__avatarWrapper}`}>
              <div className={`${styles.img}`}>
                {
                  loading ?
                  (
                    <>
                      <div className={styles.blur} />

                      <div className={styles.loader}>
                        <Loader width={32} height={32} borderWidth={5} color="#FFFFFF"/>
                      </div>
                    </>
                  )
                  :
                  <></>
                }
             
                <img className={`${styles.file}`} src={inputPreview} alt="avatar"/>

                <img className={styles.closeIcon} src={iconCloseBorder}  onClick={() => onCloseIcon()}/>
              </div>

              <span className={`${styles.fileName}`}>{selectedFile?.name}</span>
            </div>
          :
          <>
            {
              loading ?
              (
                <>
                  <div className={styles.blur} />

                  <div className={styles.loader}>
                    <Loader width={32} height={32} borderWidth={5} color="#FFFFFF"/>
                  </div>
                </>
              )
              :
              <></>
            }

            <div className={styles.fileInput_icon}>
              <Svg width={imgWidth || 64} height={imgHeight || 64} src={uploadImg || iconUploadUserPhoto} color={imgColor || "#DBDCE4"} />
            </div>

            <div className={`${areaTextClassName} ${styles.fileInput_title}`}>{areaText || "Drop your image here, or"}</div>

            {
              subAreaText ?
                <div className={`${subAreaTextClassName} ${styles.fileInput_subTitle}`}>{subAreaText}</div>
                :
                <></>
            }

            <Button size="small" variant="outlined" className={`${btnClassName} ${styles.fileInput_btn}`} disabled={disabled}>
              <label className={styles.fileInput__label}>
                Choose file
                <input
                  className={styles.fileInput__label_input}
                  onChange={onInputChange}
                  type="file"
                  accept={acceptFormats}
                  ref={inputRef}
                />
              </label>
            </Button>
          </>
      }
    </div>
  );
});
interface FileInputProps {
  /** Delete file handler */
  onDeleteFile?: () => Promise<any>,

  /** Selected file 
   * @default null
  */
  selectedFile: File | null;

  /** set file state function */
  setSelectedFile: (x: File | null) => void;

  /** className for container wrapping FileInput component */
  className?: string;

  /** className for button FileInput component */
  btnClassName?: string;
  
  /** Input preview */
  preview?: string | null;

  /** Input loading status */
  loading?: boolean;
  uploadImg?: string;
  imgColor?: string;
  imgWidth?: number;
  imgHeight?: number;
  acceptFormats?: string,
  areaText?: string,
  areaTextClassName?: string,
  subAreaText?: string,
  subAreaTextClassName?: string,
  withoutPreview?: boolean,
  disabled?: boolean,
}
