import { memo, useCallback } from "react";
import styles from "./logo.module.scss";
import logoWhite from "@source/assets/imgs/logoWhite.svg";
import logoBlue from "@source/assets/imgs/logoBlue.svg";
import logoShort from "@source/assets/imgs/logoShort.svg";
import { LogoType } from "@source/utils/utils";

export default memo((props: LogoProps) => {
  const {className, type} = props;

  const getLogo = () => {
    switch(type) {
      case LogoType.White:
        return logoWhite;

      case LogoType.Blue:
        return logoBlue;

      case LogoType.Short:
          return logoShort;
          
      default:
        return logoWhite;
    }
  }

  return(
    <img className={`${styles.logo} ${className}`} src={getLogo()} alt="logo" />
  )
})

interface LogoProps {
  /** className for container wrapping Logo component */
  className?: string
  
  /** Logo type
   * @default logoWhite
   */
  type: LogoType;
}