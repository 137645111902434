import AnnouncementsController from "@source/api/controllers/announcementsController";
import Loader from "@source/components/UI/loader/loader";
import useFetching from "@source/hooks/useFetching";
import announcementsStore from "@source/store/announcementsStore";
import nursingHomeStore from "@source/store/nursingHomeStore";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import AnnouncementsCard from "./announcementsCard/announcementsCard";
import styles from "./announcementsTab.module.scss";

const AnnouncementsTab = observer((props: AnnouncementsTabProps) => {
  const {} = props;
  const [getAnnouncements, getAnnouncementsIsLoading, getAnnouncementsError] = useFetching<ReturnType<typeof AnnouncementsController.getAnnouncements>>(AnnouncementsController.getAnnouncements);

  const router = useHistory();
  
  useEffect(() => {
    const fetchAnnouncements = async () => {
      const response = await getAnnouncements(nursingHomeStore.getId());

      if (response) {
        announcementsStore.setAnnouncements(response.announcements);
      }
    };

    fetchAnnouncements();
  }, [nursingHomeStore.activeNursingHome])

  return(
    <div className={styles.announcementsTab}>
        {getAnnouncementsIsLoading
          ? <div className={styles.loader}>
              <Loader width={80} height={80} borderWidth={6}/>
            </div>
          : <div className={styles.list}>
              {announcementsStore.announcements?.length
                ? announcementsStore.announcements.map((announcement) => (
                    <AnnouncementsCard 
                      key={announcement._id} 
                      className={styles.announcement} 
                      announcement={announcement}
                    /> 
                  ))
                : <></>}
            </div>
        }
    </div>
  );
});

export default AnnouncementsTab;

interface AnnouncementsTabProps {}
