import {  IResetPasswordRequest, IChangeEmailRequest } from "../models/familyMemberModels";
import FamilyMemberService from "../services/familyMemberService";

export default class FamilyMemberController {
  static async resetPassword(body: IResetPasswordRequest) {
    return await FamilyMemberService.resetPassword(body);
  }

  static async changeEmail(body: IChangeEmailRequest, familyMemberId: string) {
    return await FamilyMemberService.changeEmail(body, familyMemberId);
  }
}
