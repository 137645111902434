const options = {
  ignoreVary: true,
  ignoreMethod: true, 
  ignoreSearch: true 
}

export default class CacheService {
  cache: Cache | null = null;
  cacheName: string;

  constructor(cacheName: string) {
    this.cacheName = cacheName;
  }

  async open() {
    if(window.caches) {
      const cache = await caches.open(this.cacheName);
      this.cache = cache;
      return this;
    }
    return false
  }

  async getCacheItem(url: string) {
    if(this.cache) {
      return await this.cache.match(url, options);
    }
  }

  async setCacheItem(url: string, item: any) {
    if(this.cache) {
      return await this.cache.put(url, new Response(item));
    }
  }

  async clearCache() {
    if(this.cache) {
      return await caches.delete(this.cacheName)
    }
  }
}

