import { memo } from "react";
import styles from "./dateRangePicker.module.scss";
import { DatePicker, Application } from 'react-rainbow-components';
import calendarIcon from "@source/assets/imgs/calendar.svg";
import Svg from '../../svg/svg';
import { Colors } from "@source/utils/utils";
import { IFillVariant } from "@source/utils/enums";
import iconClearDate from "@source/assets/icons/iconClearDate.svg";
import moment from "moment";

/** see https://react-rainbow.io/#/DatePicker for more information */
export default memo((props: DatePickerProps) => {
  const {value, onChange, className, label, id,
        disabled, maxDate, required, touched, error, placeholder, minDate } = props;

  const theme = {
    rainbow: {
        palette: {
          brand: Colors.primary01,
        },
    },
  };

  const onClearDate = () => {
    onChange(null);
  }

  return(
    <div className={`${styles.picker} ${className}`}>
      <div className={styles.picker_label}>{label}</div>

      <Application theme={theme}>
        <DatePicker
            placeholder={placeholder ? placeholder : "Date range"}
            selectionType="range"
            formatStyle="medium"
            name={id}
            id={id}
            variant="single"
            required={required}
            maxDate={maxDate}
            minDate={minDate}
            className={`
              ${styles.picker_inner} 
              ${touched && error ? styles.picker_inner_error : ''}
              ${props.fill === IFillVariant.Fill && styles.picker_inner_fill}`
            } 
            icon={<Svg src={calendarIcon} color="#333333"/>}
            value={value ? value : undefined}
            disabled={disabled}
            locale={'en-gb'}
            onChange={(value:any) => onChange(value)}
            bottomHelpText={<span className={styles.bottomHelpText}>{value && !value[1] ? `From ${moment(value[0]).format('DD.MM.YYYY')} to ∞` : "" }</span>}
        />

        {
          value ?
          <Svg className={styles.closeIcon} width={30} height={30} src={iconClearDate} color="#333333" onClick={onClearDate}/>
          :
          <></>
        }
      </Application>

      {touched && error
        ? <div className={styles.picker_error}>{error}</div>
        : <></>}
    </div>
  )
});

interface DatePickerProps {
  /** DatePicker change handler */
  onChange: (x: ([Date, Date] | null)) => void,

  /** DatePicker value ([start, end])*/
  value: [Date, Date] | [Date] | null,

  /** className for container wrapping DateInput component */
  className?: string,

  /** DatePicker label */
  label?: string,

  /** DatePicker id */
  id?: string,

  /** DatePicker fill 
   * @default IFillVariant.blank
  */
  fill?: IFillVariant,

  /** DatePicker disabled 
   * @default false
  */
  disabled?: boolean,

  /** Last day user can set */
  maxDate?: Date,

  /** First day user can set */
  minDate?: Date,

  /** Is date required */
  required?: boolean,

  /** Input error message */
  error?: string,

  /** Input touched */
  touched?: boolean,

    /** Input placeholder */
    placeholder?: string,
}
