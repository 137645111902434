import ReqError from "@source/api/reqError";
import axios, { AxiosResponse } from "axios";
import CacheService from "./chacheService";

export default class S3Service {
  static async uploadToS3(url: any, file: File): Promise<AxiosResponse<any>> {
    try {
      return (await axios.put(url, file, {
        headers: {
          "Content-Type": file.type,
        },
      })).data;
    } catch (e: any) {
      throw ReqError.uploadToS3Error(e.response)
    }
  }

  static async getImgS3(url: string) {
    try {
      const cache = await new CacheService('photo').open();
      let cacheResponse;
      if(cache) {
        cacheResponse = await cache.getCacheItem(`https://${getHost(url)}/${getFileName(url)}`);
      }

      if(cacheResponse) {
        const cacheResponseBlob = await cacheResponse?.blob();

        return URL.createObjectURL(cacheResponseBlob as any);
      } else {
        const response = (await axios.get(url, { responseType: 'blob' })).data;
        if(cache) {
          cache.setCacheItem(`https://${getHost(url)}/${getFileName(url)}`, response);
        }
        return URL.createObjectURL(response);
      }
    } catch (e: any) {
      throw ReqError.getImgError(e.response)
    }
  }
}

export function getFileName(url) {
  return url.split('/').pop().split('#')[0].split('?')[0];
}

export function getHost(url) {
  return url.split(/\/+/)[1]
}