import { useHistory } from "react-router-dom";
import styles from "./expiredModal.module.scss";
import { observer } from "mobx-react-lite";
import Modal from "@components/UI/modal/modal";
import Button from "@components/UI/buttons/button/button";
import userStore from "@store/userStore";
import iconExpired from "@source/assets/icons/iconExpired.svg";
import { useEffect, useState } from "react";
import moment from "moment";

const ExpiredModal = observer((props: ExpiredModalProps) => {
  const {} = props;
  const router = useHistory();

  const [seconds, setSeconds] = useState(119000);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (seconds > 0) {
      interval = setInterval(() => {
        setSeconds(seconds => seconds - 1000);
      }, 1000);
    } else {
      if (interval) {
        clearInterval(interval)
      }
    }
    
    return () => {
      if (interval) {
        clearInterval(interval)
      }
    };
  }, [seconds]);
  
  
  const onContinue = () => {
  }
  
  return(
    <Modal open={!!userStore.getExpiredModal()} className={styles.expiredModal} maxWidth={388}>
      <div className={styles.expiredModalContainer}>
        <div className={styles.content}>
          <img className={styles.icon} src={iconExpired} alt="expired"/>

          <div className={styles.textInfo}> 
            <span className={styles.title}>Your session is about to end</span>

            <span className={styles.text}>Please click to extend your session</span>
            
            <span className={styles.timer}>{moment(seconds).format("mm:ss")}</span>
          </div>
        </div>
        
        <Button
          className={styles.continueBtn}
          onClick={onContinue}
        >
          Continue
        </Button>
      </div>
    </Modal>
  )
})

export default ExpiredModal;

interface ExpiredModalProps {

}
