import StatisticService from "../services/statisticService";

export default class StatisticController {
  static async getUpdatesStatistic(req: string) {
    return await StatisticService.getUpdatesStatistic(req);
  }

  static async getNursingHomeActualStatistic(req: string) {
    return await StatisticService.getNursingHomeActualStatistic(req);
  }

  static async getFamilyMembersActivationStatistic(req: string) {
    return await StatisticService.getFamilyMembersActivationStatistic(req);
  }

  static async getEntryStatistic(req: string) {
    return await StatisticService.getEntryStatistic(req);
  }

  static async getEmployeeActivationStatistic(nursingHomeIds: string[]) {
    return await StatisticService.getEmployeeActivationStatistic(nursingHomeIds);
  }

  static async getResidentsStatistic(nursingHomeId: string) {
    return await StatisticService.getResidentsStatistic(nursingHomeId);
  }

  static async getNumbersStatistic(req: any) {
    return await StatisticService.getNumbersStatistic(req);
  }
}