import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import styles from "./residentsTab.module.scss";
import iconGrid from "@source/assets/icons/iconGrid.svg";
import iconLines from "@source/assets/icons/iconLines.svg";
import iconSelectResidents from "@source/assets/icons/iconSelectResidents.svg";
import iconClearDate from "@source/assets/icons/iconClearDate.svg";
import Svg from "@source/components/UI/svg/svg";
import SearchInput from "@source/components/UI/inputs/searchInput/searchInput";
import ResidentCard from "@source/components/residentCard/residentCard";
import { IFillVariant } from "@source/utils/enums";
import useFetching from "@source/hooks/useFetching";
import ResidentController from "@source/api/controllers/residentController";
import residentStore from "@source/store/residentsStore";
import Loader from "@source/components/UI/loader/loader";
import nursingHomeStore from "@source/store/nursingHomeStore";
import { observer } from "mobx-react-lite";
import BlockNewUpdatesModal from "@components/blockNewUpdatesModal/blockNewUpdatesModal";
import residentsStore from "@source/store/residentsStore";
import NewUpdatesDisabledModal from "@components/newUpdatesDisabledModal/newUpdatesDisabledModal";
import {IResident, ResidentStatus} from "@source/api/models/residentModel";
import Button from "@components/UI/buttons/button/button";
import { HOME_ROUTES } from "@source/utils/utils";
import userStore from "@store/userStore";
import { HomePermissionsEnum } from "@source/api/models/permissionsModels";
import {ISelectItem} from "@components/UI/inputs/autocompleteInput/autocompleteInput";
import {SelectChangeEvent} from "@mui/material";
import SelectInput from "@components/UI/inputs/selectInput/selectInput";
import {ContactTypeEnum} from "@source/api/models/allUsersModels";


const ResidentsTab = observer((props: ResidentsTabProps) => {
  const {} = props;
  const [resident, isLoading, error] = useFetching<ReturnType<typeof ResidentController.getResidents>>(ResidentController.getResidents);
  const [residentsCount, getResidentsCountIsLoading, getResidentsCountError] = useFetching<ReturnType<typeof ResidentController.getResidentsCount>>(ResidentController.getResidentsCount);
  const [getSections, getSectionsLoading, getSectionsError] = useFetching<ReturnType<typeof ResidentController.getSections>>(ResidentController.getSections);

  const router = useHistory();

  const [searchString, setSearchString] = useState('');
  const [selectedSection, setSelectedSection] = useState({
    title: 'All sections',
    value: 'all',
    id: '0',
  });
  const [searchStringDirty, setSearchStringDitry] = useState(false);
  const [gridVariant, setGridVariant] = useState(GridVariant.Grid);
  const [sections, setSections] = useState<ISelectItem[]>([]);

  const {} = props;

  useEffect(() => {
    return () => {
      if(residentStore.getFocusOnResident()) {
        residentStore.setFocusOnResident(null);
      }
    }
  }, [])

  useEffect(() => {
    resetFilters();

    const fetchSectionsNames = async () => {
      const sectionsRes = await getSections(nursingHomeStore.activeNursingHome?._id);

      if (sectionsRes) {
        const getSectionsResSelectItems = sectionsRes.map((section) => {
          return {
            title: section._id,
            value: section._id,
            id: section._id,
          }
        });

        setSections([
          {
            title: 'All sections',
            value: 'all',
            id: '0',
          },
          ...getSectionsResSelectItems
        ])
      }
    }

    async function getResidents() {
      const residentsRes = await resident({nursingHomeId: nursingHomeStore.getId()});

      if (residentsRes) {
        const residentsCountRes = await residentsCount(nursingHomeStore.getId());

        residentStore.setResidents(residentsRes);
        if (residentsCountRes) {
          residentStore.setResidentsCount(residentsCountRes);
        }
      }
    }

    getResidents();
    fetchSectionsNames();
  }, [nursingHomeStore.activeNursingHome])

  useEffect(() => {
    if(searchStringDirty) {
      const timer = setTimeout(() => {
        async function getResidents() {
          const residentsRes = await resident({nursingHomeId: nursingHomeStore.getId(), searchString, section: selectedSection.value });
    
          if (residentsRes) {
            residentStore.setResidents(residentsRes);
          }
        }
        getResidents();
      }, 500)

      return () => {
        clearTimeout(timer)
      }
    }
  }, [searchString, selectedSection])

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.target.value);
    
    if (!searchStringDirty) {
      setSearchStringDitry(true);
    }
  }

  const onGridVariant = (variant: GridVariant) => {
    setGridVariant(variant);
  }

  const isActiveGridVariant = (variant: GridVariant) => {
    return gridVariant === variant;
  }

  const resetFilters = () => {
    setSearchString("");
    setSearchStringDitry(false)
  };

  const isSelectableResident = () => {
    return !!residentStore.getMultipleUpdateSelectedResidents();
  }

  const onSelectResidents = () => {
    residentStore.setMultipleUpdateSelectedResidents([]);
  }

  const onCancelSelectResidents = () => {
    residentStore.setMultipleUpdateSelectedResidents(null);

  }

  const isPrimaryContactConfirmed = (resident) => {
    return resident.familyRelations.some((familyRelation) =>
        familyRelation.contactType === ContactTypeEnum.primary &&
        !familyRelation?.familyMember?.account?.isTemporaryPassword &&
        !familyRelation?.familyMember?.account?.nonParticipant &&
        familyRelation?.familyMember?.isConfirmed &&
        !familyRelation?.familyMember?.isDisabled &&
        resident.updatesEnabled
    )
  }

  const onSelectAll = () => {
    const allResidents = residentsStore.getResidents().filter((resident) => {
      const canSelect = isPrimaryContactConfirmed(resident) && resident.status === ResidentStatus.ACTIVE;
      return canSelect;
    });

    residentStore.setMultipleUpdateSelectedResidents(allResidents)
  }

  const onNewUpdateResidents = () => {
    const multipleUpdateSelectedResidents = residentStore.getMultipleUpdateSelectedResidents()

    if (multipleUpdateSelectedResidents) {
      router.push({
        pathname: `${HOME_ROUTES.Residents}/${multipleUpdateSelectedResidents[0]._id}/update`,
      });
    }
  }

  const onSelectSectionChange = (e: SelectChangeEvent<string>) => {
    const selectedStaffItem = sections.find((section) => section.value === e.target.value);

    if (selectedStaffItem) {
      setSelectedSection(
      {
            title: selectedStaffItem.title,
            value: selectedStaffItem.value,
            id: selectedStaffItem.id.toString(),
      })
    }

    if (!searchStringDirty) {
      setSearchStringDitry(true);
    }
    residentsStore.setMultipleUpdateSelectedResidents(null)
  };

  return(
    <div className={styles.residents}>
      {
        residentsStore.getModalBlockNewUpdatesResident() ?
        <BlockNewUpdatesModal/>
        :
        <></>
      }

      {
        residentsStore.getModalNewUpdatesDisabledResident() ?
        <NewUpdatesDisabledModal />
        :
        <></>
      }

      <div className={styles.filters}>
        <div className={styles.fields}>
          <SearchInput className={`${styles.search} ${!selectedSection.value && styles.search_placeholder}`} value={searchString}
                    onChange={onSearchChange} fill={IFillVariant.Fill} 
                    placeHolder="Search"/>
                <SelectInput className={`${styles.search}`} value={selectedSection.value}
                             handleChange={onSelectSectionChange} id="sectionSelect"
                             items={sections} placeholder="Section"/>

          {
            isSelectableResident() ?
              <>
                <Button className={styles.selectAll} onClick={onSelectAll}>
                  Select All
                </Button>

                <Button className={styles.cancelSelection} onClick={onCancelSelectResidents}>
                  <div className={styles.container}>
                    <img
                      className={styles.icon}
                      src={iconClearDate}
                    />

                    <span className={styles.text}>Cancel selection</span>
                  </div>
                </Button>

                <Button className={styles.newUpdate} onClick={onNewUpdateResidents} disabled={!residentStore.getMultipleUpdateSelectedResidents()?.length}>
                  {`New Update (${residentStore.getMultipleUpdateSelectedResidents()?.length})`}
                </Button>
              </>
              :
              userStore.isPermission(HomePermissionsEnum.ADD_MULTIPLE_UPDATE) ?
                <Button className={styles.selectResidents} onClick={onSelectResidents}>
                  <div className={styles.container}>
                    <img
                      className={styles.icon}
                      src={iconSelectResidents}
                    />

                    <span className={styles.text}>Multiple residents update</span>
                  </div>
                </Button>
                :
                <></>
          }
        </div>

        {
          isSelectableResident() ?
            <></>
            :
            <div className={styles.gridFormat}>
              <Svg
                className={`${styles.icon} ${isActiveGridVariant(GridVariant.Grid) && styles.icon_active}`}
                src={iconGrid}
                width={40}
                height={40}
                onClick={() => {onGridVariant(GridVariant.Grid)}}
              />

              <Svg
                className={`${styles.icon} ${isActiveGridVariant(GridVariant.Lines) && styles.icon_active}`}
                src={iconLines}
                width={40}
                height={40}
                onClick={() => {onGridVariant(GridVariant.Lines)}}
              />
            </div>
        }
      </div>

      { 
        isLoading || getResidentsCountIsLoading
          ?
            <div className={styles.loading}>
              <Loader className={styles.loading__top_loader} height={80} width={80} borderWidth={6}/>
            </div>
          : <div className={`${styles.grid} ${gridVariant === GridVariant.Lines && styles.grid_lines}`}>
              {residentStore.getResidents().map((resident: IResident) => (<ResidentCard key={resident._id} resident={resident} gridVariant={gridVariant}/>))}
            </div>
      }
    </div>
  )
})

export default ResidentsTab;

interface ResidentsTabProps {

}

export const enum GridVariant {
  Grid,
  Lines
}