import S3Service from "../services/s3Service";

export default class FileController {
  static async uploadToS3(url: any, file: File) {
    return await S3Service.uploadToS3(url, file);
  }

  static async getImgFromS3(url: string) {
    return await S3Service.getImgS3(url);
  }
}