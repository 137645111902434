import { makeAutoObservable } from "mobx";
import userStore from "./userStore";

class UserStore {
  isHaveUnreadMessages = false;
  totalUnreadMessagesCount = 0;
  chats;
  activeChat;
  
  constructor() {
    makeAutoObservable(this);
  }

  setUnreadMessagesStatus(totalUnreadMessagesCountRes: number, isHaveUnreadMessagesRes: boolean) {
    this.totalUnreadMessagesCount = totalUnreadMessagesCountRes
    this.isHaveUnreadMessages = isHaveUnreadMessagesRes;
  }

  removeTotalUnreadMessagesCount(removedTotalUnreadMessagesCount: number) {
    this.totalUnreadMessagesCount = this.totalUnreadMessagesCount - removedTotalUnreadMessagesCount;
    this.isHaveUnreadMessages = this.totalUnreadMessagesCount > 0;
  }

  addTotalUnreadMessagesCount(addedTotalUnreadMessagesCount: number) {
    this.totalUnreadMessagesCount = this.totalUnreadMessagesCount + addedTotalUnreadMessagesCount;

    if (!this.isHaveUnreadMessages) {
      this.isHaveUnreadMessages = true;
    }
  }

  setChats(chats: any[] | null) {
    this.chats = chats;
  }

  setActiveChat(chat: any) {
    this.activeChat = chat;
  }

  addActiveChatMessage(message: any) {
    const newActiveChatMessages = [
      ...this.activeChat.messages,
      message
    ];

    this.activeChat.messages = newActiveChatMessages;
  }

  makeActiveChateMessagesRead() {
    this.activeChat.messages = this.activeChat.messages.map((message) => {
      if (message.sender._id === userStore.getId() && message.status === "sent"){
        message.status = "viewed";
      }

      return message;
    })
  }

  addHomeUnreadMessage(chatId: string, message) {
    if (this.chats?.length) {
      this.chats = this.chats.map((chat) => {
        const newChat = {
          ...chat
        };
  
        if (chat._id === chatId) {
          if (chatId !== this.activeChat?._id) {
            newChat.unreadMessageCount = chat.unreadMessageCount + 1;
          };
  
          newChat.messages.push(message)
        }
  
        return newChat;
      })
    }
    
    if (chatId !== this.activeChat?._id) {
      this.addTotalUnreadMessagesCount(1);
    };
  }

  addChatUnreadMessage(message) {
    if (this.chats?.length) {
      this.chats = this.chats.map((chat) => {
        const newChat = {
          ...chat
        };
  
        if (chat._id === this.activeChat._id) {
          newChat.messages.push(message)
        }
  
        return newChat;
      })
    }
  }


  removeUnreadMessages(chatId: string, unreadMessageCount: number) {
    this.chats = this.chats.map((chat) => {
      const newChat = {
        ...chat
      };

      if (chat._id === chatId) {
        newChat.unreadMessageCount = 0;
      }

      return newChat;
    });

    this.removeTotalUnreadMessagesCount(unreadMessageCount)
  }
}

export default new UserStore();

export interface IUser  {
  _id: string,
  email: string,
  firstName: string,
  lastName: string,
  accountType: string,
  photo?: string,
}