import { makeAutoObservable } from "mobx";

class AppStore {
  updateHeaderBtn: JSX.Element | null = null

  constructor() {
    makeAutoObservable(this);
  }

  setUpdateHeaderBtn(ref: JSX.Element) {
    this.updateHeaderBtn = ref;
  }

  getUpdateHeaderBtn() {
    return this.updateHeaderBtn;
  }

  resetUpdateHeaderBtn() {
    this.updateHeaderBtn = null;
  }
}

export default new AppStore();
