import EmploeeService from "@source/api/services/employeeService";
import {
  IAddNewAgencyAccountRequest,
  IAddNewEmployeeRequest, IEditEmployeeEmailRequest,
  IEditEmployeeRequest,
  IGetAgencyAccountsRequest,
  IGetEmployeeRequest,
  IResetForgotPasswordRequest,
  IVerifyRequest
} from "@source/api/models/employeeModels";

export default class EmployeeController {
  static async addNewEmployee(employee: IAddNewEmployeeRequest) {
    return await EmploeeService.add(employee);
  }

  static async changeEmail(request: IEditEmployeeEmailRequest) {
    return await EmploeeService.changeEmail(request);
  }

  static async getNames(nursingHomeId: string) {
    return await EmploeeService.getNames(nursingHomeId);
  }
  
  static async editEmployee(employee: IEditEmployeeRequest, employeeId: string) {
    return await EmploeeService.edit(employee, employeeId);
  }

  static async getEmployees(req: IGetEmployeeRequest) {
    return await EmploeeService.get(req);
  }

  static async verify(vefiryInfo: IVerifyRequest) {
    return await EmploeeService.verify(vefiryInfo);
  }

  static async disableEmployee(employeeId: string) {
    return await EmploeeService.disableEmployee(employeeId);
  }

  static async enableEmployee(employeeId: string) {
    return await EmploeeService.enableEmployee(employeeId);
  }

  static async disableAgencyAccount(agencyId: string) {
    return await EmploeeService.disableAgencyAccount(agencyId);
  }

  static async enableAgencyAccount(agencyId: string) {
    return await EmploeeService.enableAgencyAccount(agencyId);
  }

  static async resetForgotPassword(body: IResetForgotPasswordRequest) {
    return await EmploeeService.resetPassword(body);
  }

  static async forgotPassword(body: IResetForgotPasswordRequest) {
    return await EmploeeService.sendForgotPasswordCodeToEmail(body);
  }

  static async createEmployeePhoto(req: FormData, employeeId: string) {
    return await EmploeeService.createEmployeePhoto(req, employeeId);
  }

  static async deleteEmployeePhoto(employeeId: string) {
    return await EmploeeService.deleteEmployeePhoto(employeeId);
  }

  static async getAgencyAccounts(req: IGetAgencyAccountsRequest) {
    return await EmploeeService.getAgencyAccounts(req);
  }

  static async addNewAgencyAccount(agencyAccount: IAddNewAgencyAccountRequest) {
    return await EmploeeService.addNewAgencyAccount(agencyAccount);
  }
}