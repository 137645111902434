import { memo, useEffect } from "react";
import styles from "./ChangePasswordPage.module.scss";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import TextInput from "@source/components/UI/inputs/textInput/textInput";
import PasswordInput from "@source/components/UI/inputs/passwordInput/passwordInput";
import Button from "@source/components/UI/buttons/button/button";
import { useFormik } from "formik";
import useFetching from "@source/hooks/useFetching";
import { getHelperItems, LogoType, PasswordValidationRules, ROUTES } from "@source/utils/utils";
import { PasswordVariant } from "@source/components/UI/inputs/passwordInput/passwordHelper/passwordHelper";
import authBackLines from "@source/assets/imgs/authBackLines.png";
import Logo from "@source/components/UI/logo/logo";
import Notification from "@source/components/snackMessage/notification";
import userStore from "@source/store/userStore";
import AuthController from "@source/api/controllers/authController";

const ChangePasswordPage = memo(() => {
  const [change, isLoading, error] = useFetching<ReturnType<typeof AuthController.changePassword>>(AuthController.changePassword);
  const router = useHistory();

  const validateSchema = Yup.object({
    password: Yup.string()
                 .min(9, 'min 9')
                 .max(100, 'max 100')
                 .test('regex', 'Use at least 3 of the following', (value: any) => {
                     let rules = 0;
                     (new RegExp(PasswordValidationRules.NUMBER, 'g')).test(value) && rules++;
                     (new RegExp(PasswordValidationRules.UPPER_CHAR, 'g')).test(value) && rules++;
                     (new RegExp(PasswordValidationRules.LOWER_CHAR, 'g')).test(value) && rules++;
                     (new RegExp(PasswordValidationRules.SPECIAL_CHAR, 'g')).test(value) && rules++;
                     return (rules >= 3);
                   }),
    confirm: Yup.string()
                .required("Confirm Password is reqired")
                .oneOf([Yup.ref("password"), null], "The passwords do not match"),
  });

  const formik = useFormik({
    validationSchema: validateSchema,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {
      password: '',
      oldPassword: '',
      confirm: '',
      email: userStore.getLoginEmail()
    },
    onSubmit: async (formValues) => {
      const response = await change(formValues.email, formValues.password, formValues.oldPassword);

      if(response) {
        new Notification().success('You’re successfully change your password');
        formik.resetForm();
        router.push(ROUTES.LoginPage);
      } 
    }
  });

  useEffect(() => {
    if(!userStore.getLoginEmail()) {
      new Notification().warning('We can`t deliver your email. Please enter it yourself.');
    }
  }, [])

  const {errors, values, touched, handleBlur, handleChange, handleSubmit} = formik;
  return(
    <div className={styles.changePass}>
      <div className={styles.changePass__inner}>
        <Logo className={styles.changePass__inner_logo} type={LogoType.White}/>

        <form className={styles.changePass__form} onSubmit={handleSubmit}>
          <div className={styles.changePass__form_title}>Your password is expired!</div>
          <div className={styles.changePass__form_subTitle}>You need to change password.</div>
        
          <TextInput 
            id="email" 
            label="Email"
            disabled={!!userStore.getLoginEmail()}
            value={values.email}
            className={styles.changePass__form_input}
          />

          <PasswordInput id="oldPassword" label="Current password"
                  placeholder="Enter current password"
                  className={styles.changePass__form_input}
                  value={values.oldPassword}
                  touched={touched.oldPassword}
                  error={errors.oldPassword}
                  handleChange={handleChange} 
                  handleBlur={handleBlur} 
          />

          <PasswordInput id="password" label="Password"
                  placeholder="Create password" 
                  className={styles.changePass__form_input}
                  value={values.password} 
                  touched={touched.password}
                  error={errors.password}
                  handleChange={handleChange} 
                  handleBlur={handleBlur}
                  helper helperProps={{
                    variant: PasswordVariant.tooltip,
                    toolTipItems: getHelperItems(values.password), 
                    subTitle: 'You cannot reuse your last 8 passwords.', 
                    title: 'The password must meet the following rules:'
                  }}
          />

          <PasswordInput id="confirm" label="Confirm password"
                  placeholder="Re-enter your new password"
                  className={styles.changePass__form_input}
                  value={values.confirm}
                  touched={touched.confirm}
                  error={errors.confirm}
                  handleChange={handleChange} 
                  handleBlur={handleBlur} 
          />

          <Button event="submit" className={styles.changePass__form_btn} 
                isLoading={isLoading}>Confirm</Button>
        </form>
      </div>

      <img className={styles.changePass_back} src={authBackLines} alt="lines"/>
    </div>
  )
})

export default ChangePasswordPage;