import React, { memo, useState } from "react";
import searchIcon from "@source/assets/imgs/search.svg";
import styles from "./searchInput.module.scss";
import { IFillVariant } from "@source/utils/enums";

export default memo((props: SearchInputProps) => {
  const {onChange, value, placeHolder, onBlur, onFocus, className, fill} = props;

  return(
    <div className={`${className} ${styles.search}`}>
      <input 
        className={`${styles.search__input} 
                    ${fill === IFillVariant.Fill ? styles.search__input_fill : ''}`} 
        type="text" 
        onBlur={onBlur} 
        onFocus={onFocus} 
        value={value} 
        onChange={onChange} 
        placeholder={placeHolder}
      />
      <img className={styles.search__img} src={searchIcon} alt="search"/>
    </div>
  )
});

interface SearchInputProps {
  /** Input value */
  value: string,

  /** Input placeholder */
  placeHolder?: string,

  /** change handler */
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,

  /** focus handler */
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void,

  /** blur handler */
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void,

  /** className for container wrapping SearchInput component */
  className?: string,

  /** Input fill 
   * @default IFillVariant.Blank
  */
  fill?: IFillVariant,
}

