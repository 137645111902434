import { memo, useCallback } from "react";
import styles from "./NotFoundPage.module.scss";
import errorDog from "@source/assets/imgs/errorDog.svg";
import Button from "@source/components/UI/buttons/button/button";
import { useHistory } from "react-router-dom";
import { HOME_ROUTES } from "@source/utils/utils";

const NotFoundPage = memo(() => {
  const router = useHistory();

  const onHomeBtnClick = useCallback(() => {
    router.push(HOME_ROUTES.Residents)
  }, [router])

  return(
    <div className={styles.notFound}>
      <div className={styles.inner}>
        <div className={styles.left}>
          <div className={styles.text404}>404</div>
          <div className={styles.text}>I’m Sorry, Not Found</div>
          <div className={styles.description}>Something went wrong with this page. Please go back home and take it from scratch.</div>

          <Button size="large" onClick={onHomeBtnClick} 
              className={styles.button}
              >Back to home</Button>
        </div>

        <div className={styles.right}>
          <img className={styles.errorDog} src={errorDog} alt="errorDog" />
        </div>
      </div>
    </div>
  )
})

export default NotFoundPage;