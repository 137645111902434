import { useHistory } from "react-router-dom";
import styles from "./newResidentsModal.module.scss";
import { observer } from "mobx-react-lite";
import Modal from "@components/UI/modal/modal";
import Button from "@components/UI/buttons/button/button";
import iconExpired from "@source/assets/icons/iconExpired.svg";
import { useEffect, useState } from "react";
import moment from "moment";
import nursingHomeStore from "@store/nursingHomeStore";
import useFetching from "@source/hooks/useFetching";
import NursingHomeController from "@source/api/controllers/nursingHomeController";
import residentsStore from "@store/residentsStore";
import {Avatar, List, ListItem, ListItemAvatar, ListItemText, Paper} from "@mui/material";
import CacheImg from "@components/UI/cacheImg/cacheImg";
import {stringToHslColor} from "@source/utils/utils";

const NewResidentsModal = observer((props: ExpiredModalProps) => {
  const [getNewResidentActivated, getNewResidentActivatedIsLoading, getNewResidentActivatedError] = useFetching<ReturnType<typeof NursingHomeController.getNewResidentActivated>>(NursingHomeController.getNewResidentActivated);

  useEffect(() => {
    const getNewResidents = async () => {
      const resp = await getNewResidentActivated({homeId: nursingHomeStore.activeNursingHome?._id})
      if(resp?.length !== 0) {
        nursingHomeStore.setNewResidents(resp!);
      }
    }

    if(nursingHomeStore.activeNursingHome) {
      getNewResidents();
    }
  }, [nursingHomeStore.activeNursingHome]);

  const onClose = () => {
      nursingHomeStore.setNewResidents([]);
  }
  
  return(
    <Modal open={nursingHomeStore.newResidentsActivatedModal()} className={styles.modalComment} close={onClose}>
      <div className={styles.modalCantCreateUpdate}>
        <div className={styles.prmiraryContactNotActivated}>
            <span className={styles.row_bold}>
              Great news! 🌟🌟
            </span>
            <span className={styles.row}>
              New residents are now active in Brenna
            </span>
            <span className={styles.row}>
              You can now use Brenna for these residents.
            </span>
        </div><br/>
          <Paper style={{maxHeight: 200, overflow: 'auto'}}>
              <List style={{height: '100%', overflow: 'auto' }}>
              {nursingHomeStore.newResidents.map((resident) => {
                  return (
                      <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
                          <ListItemAvatar>
                              <Avatar sx={{backgroundColor: stringToHslColor(resident.firstName + resident.lastName), objectFit: 'cover'}}>
                              <CacheImg
                                  url={resident.photo}
                                  className={styles.photo}
                                  imgClassName={styles.photo}
                                  skeletonProps={{variant: 'circular'}}
                              >
                                  <div className={styles.noPhoto} style={{ backgroundColor: stringToHslColor(resident.firstName + resident.lastName) }}>
                                      {resident.firstName[0]?.toUpperCase() || ''} {resident.lastName[0]?.toUpperCase() || ''}
                                  </div>
                              </CacheImg>
                              </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                              primaryTypographyProps={{
                                  fontWeight: 400,
                                  fontSize: '20px',
                                  lineHeight: '24px',
                                  color: '#333333'
                              }}
                              secondaryTypographyProps={{
                                  fontWeight: 400,
                                  fontSize: '16px',
                                  lineHeight: '20px',
                                  color: '#333333'
                              }}
                              primary={resident.firstName + ' ' + resident.lastName}
                              secondary={'Section: ' + resident.section + ' | Room: ' + resident.room}
                          />
                      </ListItem>
                  )
              })}
              </List>
          </Paper>
        <Button
            className={styles.confirmBtn}
            onClick={() => onClose()}
        >
          Confirm
        </Button>
      </div>
    </Modal>
  )
})

export default NewResidentsModal;

interface ExpiredModalProps {

}
