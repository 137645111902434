import { memo } from "react";
import styles from "./passwordHelper.module.scss";

export enum PasswordVariant {
  tooltip, embedded
}

const PasswordHelper = memo((props: PasswordHelperProps) => {
  const {toolTipItems, title, subTitle, variant=PasswordVariant.tooltip} = props;

  const getList = (items:Array<IPasswordHelperItem>) => {
    return(
      <ul className={styles.helperTooltip__ul}>
        {items.map((item) => {
          if(!item.subItems) {
            return <li className={item.valid ? styles.valid : ''} key={item.title}>{item.title}</li>
          } else {
            return (
              <>
                <li className={item.valid ? styles.valid : ''} key={item.title}>{item.title}</li>
                {getList(item.subItems)}
              </>
            )
          }
        })}
      </ul>
    )
  }

  return(
    <div className={`${styles.helperTooltip} 
                     ${variant === PasswordVariant.tooltip ? styles.helperTooltip_tooltip : ''}`}>
      <div className={styles.helperTooltip__inner}>
        <div className={styles.helperTooltip__title}>{title}</div>

        {getList(toolTipItems)}

        {subTitle
          ? <div className={styles.helperTooltip__subTitle}>{subTitle}</div>
          : <></>}
      </div>
    </div>
  )
})

export default PasswordHelper;

export interface PasswordHelperProps {
  toolTipItems: Array<IPasswordHelperItem>,
  title?: string,
  subTitle?: string,
  variant?: PasswordVariant.tooltip | PasswordVariant.embedded
}

export interface IPasswordHelperItem {
  title: string,
  valid: boolean,
  subItems?: Array<IPasswordHelperItem>
}