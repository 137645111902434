import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import { VisibilityOff, Visibility } from '@mui/icons-material';
import React, { memo, useEffect, useRef, useState } from "react";
import PasswordHelper, { PasswordHelperProps, PasswordVariant } from "./passwordHelper/passwordHelper";
import styles from "./passwordInput.module.scss";

/** Prefer to use with formik  */
export default memo((props: PasswordProps) => {
  const {value, handleChange, label, id, error, touched, handleBlur,
         className, placeholder, required=false, helper, helperProps, eyeIcon = true} = props;

  const [isHide, setIsHide] = useState(true);
  const [focus, setFocus] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if(inputRef && inputRef?.current) {
      inputRef.current.selectionStart = inputRef.current.value.length;
      inputRef.current.selectionEnd = inputRef.current.value.length;
    }
  }, [isHide]);

  const handleMouseDownPassword = (event: React.MouseEvent) => {
    event.preventDefault();
  };

  const onInputFocus = (e:React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
    helper && setFocus(true);
  }

  const onInputBlur = (e:React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
    helper && setFocus(false)
    handleBlur && handleBlur(e);
  }

  return(
    <div className={`${styles.textInputWrapper} ${className}`}>
      {label
        ? <div className={styles.textInputWrapper_label}>{label}{required ? <span>*</span> : ''}</div>
        : <></>}

      <FormControl className={styles.input} variant="outlined"> 
        <OutlinedInput
          classes= {{
            root: styles.input__field_input,
            focused: styles.input__field_active,
            error: styles.input__field_error,
          }}
          fullWidth
          id={id}
          placeholder={placeholder}
          error={Boolean(error && touched)}
          type={isHide ? 'password' : 'text'}
          value={value}
          onBlur={onInputBlur}
          onFocus={onInputFocus}
          inputRef={inputRef}
          onChange={handleChange}
          endAdornment={
            eyeIcon ?
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setIsHide(prev => !prev)}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {isHide ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
            : 
            <></>
          }
          />

          {helper && helperProps && (focus || helperProps.variant === PasswordVariant.embedded)
            ? <PasswordHelper {...helperProps} />
            : <></>}
      </FormControl>

      {touched && error
        ? <div className={styles.textInputWrapper_error}>{error}</div>
        : <></>}
    </div>
  )
})

interface PasswordProps {
  /** Input value */
  value: string,

  /** Input id */
  id: string,

  /** Input label */
  label: string

  /** change event handler */
  handleChange: (e: React.ChangeEvent) => void,

  /** blur event handler */
  handleBlur?: (e: React.FocusEvent) => void,

  /** Innput error */
  error?: string,

  /** Input touched */
  touched?: boolean,

  /** just for view, use Yup to validate 
   * @default false
  */
  required?: boolean,

  /** className for container wrapping passwordInput component */
  className?: string,

  /** Input placeholder */
  placeholder?: string,

  /** Add helper tooltip with validation rules
   * @default false
  */
  helper?: boolean,

  /** helper tooltip props */
  helperProps?: PasswordHelperProps,

  /** Is eyeIcon need*/
  eyeIcon?: boolean,
}