import { ChangeEvent, useCallback, useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import useFetching from "@source/hooks/useFetching";
import { useHistory } from "react-router-dom";
import { HOME_ROUTES, LogoType, ROUTES } from "@source/utils/utils";
import styles from "./AgencyLoginTypeNumberPage.module.scss";
import TextInput from "@source/components/UI/inputs/textInput/textInput";
import PasswordInput from "@source/components/UI/inputs/passwordInput/passwordInput";
import Button from "@source/components/UI/buttons/button/button";
import authBackLines from "@source/assets/imgs/authBackLines.png";
import Logo from "@source/components/UI/logo/logo";
import AuthController from "@source/api/controllers/authController";
import { AgencyType, ILogInRequest } from "@source/api/models/authModels";
import Notification from "@source/components/snackMessage/notification";
import userStore from "@source/store/userStore";
import InteractionObserver from "@source/api/interactionObserver";
import UserController from "@source/api/controllers/userController";
import NursingHomeController from "@source/api/controllers/nursingHomeController";
import { IGetAvailableNursingHomesRequest, IGetNursingHomesRequest } from "@source/api/models/nursingHomeModels";
import nursingHomeStore from "@store/nursingHomeStore";
import { api } from "@source/api/http";
import LoginTechSupportModal from "@components/loginTechSupportModal/loginTechSupportModal";
import LoginTechSupportSentModal from "@components/loginTechSupportSentModal/loginTechSupportSentModal";
import { Role } from "@source/utils/models/adminModels";
import { observer } from "mobx-react-lite";
import { LOCAL_STORAGE_FROM_AGENCY_SET_PASSWORD_PAGE } from "@source/utils/models/userModels";
import { AuthActions } from "@source/api/models/userModels";
import Checkbox from '@source/components/UI/buttons/checkbox/checkbox';
import Radio from "@components/UI/buttons/radio/radio";
import { useFirebaseLogEvent } from "@source/utils/hooks/useFirebaseLogEvent";

interface IAgencyLogInTypeNumberForm  {
  agencyType: string,
  registrationNumber: string,
}

const AgencyLoginTypeNumberPage = observer((props: AgencyLoginTypeNumberPageProps) => {
  const {} = props;
  const [agencyRegister, agencyRegisterIsLoading, agencyRegisterError] = useFetching<ReturnType<typeof AuthController.agencyRegister>>(AuthController.agencyRegister);
  const [profile, profileIsLoading, profileError] = useFetching<ReturnType<typeof UserController.profile>>(UserController.profile);
  const [setTimezone, setTimezoneIsLoading,setTimezoneError] = useFetching<ReturnType<typeof UserController.setTimezone>>(UserController.setTimezone);
  const [getAvailableNursingHomes, getAvailableNursingHomesIsLoading, getAvailableNursingHomesError] = useFetching<ReturnType<typeof NursingHomeController.getAvailableNursingHomes>>(NursingHomeController.getAvailableNursingHomes);
  
  const router = useHistory();
  const [logEvent] = useFirebaseLogEvent();

  const [techSupportModal, setTechSupportModal] = useState(false);
  const [techSupportSentModal, setTechSupportSentModal] = useState(false);

  useEffect(() => {
    if (!userStore.getAgencyUser()?.rootEmail || !userStore.getAgencyUser()?.rootPassword || !userStore.getAgencyUser()?.firstName || !userStore.getAgencyUser()?.lastName) {
      router.push(ROUTES.LoginPage)
    }
  }, [])

  const getProfile = async () => {
    const profileInfo = await profile();

    if(profileInfo) {
      userStore.setUserInfo(profileInfo);
      
      userStore.setOrganizationId(profileInfo.account.organization._id);
    }

    const getAvailableNursingHomesReq: IGetAvailableNursingHomesRequest = {
      organizationId: userStore.getOrganizationId(), 
    }

    const availableNursingHomesRes = await getAvailableNursingHomes(getAvailableNursingHomesReq);

    if (availableNursingHomesRes) {
      nursingHomeStore.setNursingHomes({
        nursingHomes: availableNursingHomesRes,
        totalCount: availableNursingHomesRes.length
      });

      const activeNursingHome = availableNursingHomesRes.find((userNursingHome) => userNursingHome._id === nursingHomeStore.getLocalStorageActiveNursingHome()) || availableNursingHomesRes[0];
      nursingHomeStore.setActiveNursingHome(activeNursingHome);
    
      if (nursingHomeStore.activeNursingHome) {
        api.defaults.params = {
          ...api.defaults.params,
          nursingHomeId: nursingHomeStore.activeNursingHome._id,
        }
      }
    }


    await setTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone)
  }

  const validateSchema = Yup.object({
    registrationNumber: Yup.string().required("Registration number name is required"),
  });

  const formik = useFormik<IAgencyLogInTypeNumberForm>({
    validationSchema: validateSchema,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {
      agencyType: AgencyType.REGISTERED,
      registrationNumber: ''
    },
    onSubmit: async (formValues) => {
      const response = await agencyRegister({...userStore.getAgencyUser(), ...formValues});

      if(response) {
        logEvent('agency_login__type_registration_number', {
          email: userStore.getAgencyUser().rootEmail,
          type: formValues.agencyType
        })

        localStorage.setItem("refreshToken", response.refreshToken);
        localStorage.setItem("accessToken", response.accessToken);

        await getProfile();
        new Notification().success('You’re successfully logged in');

        logEvent("agency_login__successful_login", {
          email: userStore.getAgencyUser().rootEmail,
          organization: userStore.getUserInfo()?.account.organization.name || "",
        })

        userStore.setAgencyUserNull();
        router.push(HOME_ROUTES.Residents);

        new InteractionObserver();
        userStore.resetLoginEmail();
      }
    }
  });

  const onNeedHelp = () => {
    setTechSupportModal(!techSupportModal);
  }

  const onChecboxChange = (type: AgencyType) => {
    formik.setFieldValue("agencyType", type);
  }

  const onBack = () => {
    router.push(ROUTES.AgencyLoginNamePage);
  }

  const {errors, values, touched, handleBlur, handleChange, handleSubmit} = formik;
  return(
    <div className={styles.agencyLogin}>
      <span className={styles.back} onClick={onBack}>{"< Back"}</span>

      <LoginTechSupportModal open={techSupportModal} setOpen={() => {setTechSupportModal(!techSupportModal)}}  setSentModal={() => {setTechSupportSentModal(!techSupportSentModal)}}/> 
      <LoginTechSupportSentModal open={techSupportSentModal} setOpen={() => {setTechSupportSentModal(!techSupportSentModal)}}/> 

      <div className={styles.agencyLogin__inner}>
        <Logo className={styles.agencyLogin__inner_logo} type={LogoType.White}/>

        <form className={styles.agencyLogin__form} onSubmit={handleSubmit}>
          <div className={styles.agencyLogin__form_title}>Welcome to Brenna!</div>
          <div className={styles.agencyLogin__form_subTitle}>Please enter your details below</div>

          <div className={styles.boxes}>
            <div className={`${styles.box} ${values.agencyType === AgencyType.ENROLLED && styles.box_active}`} onClick={() => onChecboxChange(AgencyType.ENROLLED)}>
              <Radio
                className={styles.radio}
                value={AgencyType.ENROLLED}
                checked={values.agencyType === AgencyType.ENROLLED}
                onChange={() => {}}
              />

              <span className={styles.text}>Enrolled Nurse</span>
            </div>

            <div className={`${styles.box} ${values.agencyType === AgencyType.REGISTERED && styles.box_active}`} onClick={() => onChecboxChange(AgencyType.REGISTERED)}>
              <Radio
                className={styles.radio}
                value={AgencyType.REGISTERED}
                checked={values.agencyType === AgencyType.REGISTERED}
                onChange={() => {}}
              />

              <span className={styles.text}>Registered Nurse</span>
            </div>
          </div>
        

          <TextInput id="registrationNumber" label="Registration number"
                  placeholder="Your registration number"
                  className={styles.agencyLogin__form_input}
                  value={values.registrationNumber}
                  touched={touched.registrationNumber}
                  error={errors.registrationNumber} 
                  handleChange={handleChange} 
                  handleBlur={handleBlur}
                  spellCheck={false}
          />

          <Button event="submit" className={styles.agencyLogin__form_btn} 
                isLoading={agencyRegisterIsLoading || setTimezoneIsLoading || profileIsLoading || getAvailableNursingHomesIsLoading}>Confirm</Button>
        </form>

        <span className={styles.agencyLogin__needHelp} onClick={onNeedHelp}>Need help? Click here</span>
      </div>

      <img className={styles.agencyLogin_back} src={authBackLines} alt="lines"/>
    </div>
  )
})

export default AgencyLoginTypeNumberPage;

interface AgencyLoginTypeNumberPageProps {

}