import styles from "./loginTechSupportModal.module.scss";
import { observer } from "mobx-react-lite";
import Modal from "@components/UI/modal/modal";
import TextInput from "@components/UI/inputs/textInput/textInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "@components/UI/buttons/button/button";
import UserController from "@source/api/controllers/userController";
import useFetching from "@source/hooks/useFetching";
import { ISupportReq, IssueType } from "@source/api/models/userModels";
import { useFirebaseLogEvent } from "@source/utils/hooks/useFirebaseLogEvent";

const LoginTechSupportModal = observer((props: LoginTechSupportModalProps) => {
  const [supportTechSupport, supportTechSupportLoading, supportTechSupportError] = useFetching<ReturnType<typeof UserController.support>>(UserController.support);

  const [logEvent] = useFirebaseLogEvent();

  const { open, setOpen, setSentModal } = props;

  const validateSchema = Yup.object({
    fromEmail: Yup.string().email("Email is invalid").required("Email is required"),
    name: Yup.string().required("Name is required"),
    phoneNumber: Yup.string().required("Phone number is required"),
    home: Yup.string().required("Home is required"),
    content: Yup.string().required("Content is required"),
  });

  const mainFormik = useFormik({
    validationSchema: validateSchema,
    validateOnChange: true,
    validateOnBlur: true, 
    initialValues: {
      name: '',
      phoneNumber: '',
      fromEmail: '',
      home: '',
      content: ''
    },
    onSubmit: () => {
      onTechSupportSubmit();
    }
  });

  const onTechSupportSubmit = async () => {
    const { name, phoneNumber, fromEmail, home, content } = mainFormik.values;

    const supportReq: ISupportReq = {
      content,
      fromEmail,
      type: IssueType.EMPLOYEE_LOGIN_SUPPORT,
      additionalInfo: {
        name,
        phoneNumber,
        home
      }
    }

    const resSupport = await supportTechSupport(supportReq);
    if (resSupport) {
      logEvent('auth__need_help', {
        email: fromEmail,
      })
      mainFormik.resetForm();
      setOpen();
      setSentModal();
    }
  }

  const onClose = () => {
    setOpen();
    mainFormik.resetForm();
  }

  const {errors, values, touched, handleBlur, handleChange, handleSubmit, setFieldValue} = mainFormik;
  return(
    <Modal open={open} className={styles.loginTechSupportModal} maxWidth={744} close={onClose}>
      <div className={styles.loginTechSupportModalContainer}>
        <div className={styles.content}>
          <span className={styles.title}>Technical support</span>

          <span className={styles.subTitle}>Please compete the form, the Brenna team will contact you</span>

          <form className={styles.form} onSubmit={handleSubmit}>
            <TextInput id="name" label="Your name"
              placeholder="Write the full name"
              className={styles.input}
              value={values.name} 
              handleChange={handleChange}
              touched={touched.name}
              error={errors.name} 
            />

            <TextInput id="phoneNumber" label="Contact phone number"
              placeholder="XXXX - XXX - XXX"
              className={styles.input}
              value={values.phoneNumber} 
              handleChange={handleChange}
              touched={touched.phoneNumber}
              error={errors.phoneNumber}
              spellCheck={false}
            />

            <TextInput id="fromEmail" label="Contact email"
              placeholder="Write the email address"
              className={styles.input}
              value={values.fromEmail} 
              handleChange={handleChange}
              touched={touched.fromEmail}
              error={errors.fromEmail}
              spellCheck={false}
            />

            <TextInput id="home" label="Aged Care Home"
              placeholder="Write name of home"
              className={styles.input}  
              value={values.home} 
              handleChange={handleChange}
              touched={touched.home}
              error={errors.home} 
            />

            <TextInput id="content" label=""
              placeholder="Describe the problem here"
              className={styles.textArea}
              value={values.content} 
              handleChange={handleChange}
              touched={touched.content}
              error={errors.content} 
              multiline
              minRows={3}
            />

            <Button
              className={styles.button}
              size="small"
              event="submit"
              isLoading={supportTechSupportLoading}
            >
              Send
            </Button>
          </form>
        </div>
      </div>
    </Modal>
  )
})

export default LoginTechSupportModal;

interface LoginTechSupportModalProps {
  open: boolean;
  setOpen: () => void;
  setSentModal: () => void;
}
