import { memo, useState } from "react";
import TextField from '@mui/material/TextField';
import { Autocomplete, Box, Paper, styled } from "@mui/material";
import styles from "./autocompleteInput.module.scss";
import iconArrowDown from "@source/assets/icons/iconArrowDown.svg";


const BootstrapInput = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    borderRadius: 8,
    fontSize: 16,
    padding: '4px 8px',
    fontFamily: "Poppins",
    color: "#333333",
    '&:focus': {
      borderColor: '#DDE2F2',
    },
    '&:hover': {
      borderColor: '#DDE2F2',
    },
    '& ::placeholder': {
      fontSize: 16,
      color: "#333333",
      opacity: '1',
    }
  },
  '& .MuiInputBase-input': {
  },
  '& .MuiOutlinedInput-notchedOutline': {
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#DDE2F2',
    },
    '&:hover fieldset': {
      borderColor: '#e7e9f3',
    },
  },
}));

const BootstrapPaper = styled(Paper)(({ theme }) => ({
  boxShadow: "0px 0px 20px -3px rgba(216, 216, 226, 0.2)",
  borderRadius: "9px",
  '&::-webkit-scrollbar': {
    width: '22px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#DDE2F2',
    borderRadius: "5px",
    border: "10px solid rgba(0, 0, 0, 0)",
    backgroundClip: "padding-box",
  },
  "& li": { 
    fontSize: 16,
    color:"#333333",
    margin: "10px 8px",

    '&:hover': {
      color: "#3956C7",
      fontWeight: 600,
      backgroundColor: "#FFFFFF !important",
      cursor: 'pointer'
    },
   },
}));

export default memo((props: AutocompleteInputProps) => {
  const {value, handleChange, items, id, error, touched, placeholder, 
         label, className, disabled, required=false, inputValue, inputItems, onInputChange} = props;

  return(
    <div className={`${styles.selectInputWrapper} ${className} ${disabled ? styles.selectInputWrapper_disabled : ''}`}>
      {label
        ? <div className={styles.selectInputWrapper_label}>{label}{required ? <span>*</span> : ''}</div>
        : <></>}

        <Autocomplete
          className={styles.autocomplete}
          id={id}
          fullWidth
          value={value}
          onChange={handleChange}
          inputValue={inputValue}
          onInputChange={onInputChange}
          clearIcon={false}
          popupIcon={<img src={iconArrowDown}/>}
          options={inputItems}
          renderInput={(params) => (
            <BootstrapInput 
              {...params} 
              className={styles.textfield} 
              InputProps={{
                ...params.InputProps,
                classes: {
                  root: styles.textfield_input,
                  focused: styles.textfield_active,
                  error: styles.textfield_error,
                  disabled: styles.textfield_disabled,
                },
              }}
              error={Boolean(error && touched)}
              placeholder={placeholder ? placeholder : ""} 
            />
          )}
          disabled={disabled}
          ListboxComponent={(props) => <BootstrapPaper {...props}/>}
          getOptionLabel={(option) => option.title}
          renderOption={(props, option) => (
            <Box component="li" {...props}>
              {option.title}
            </Box>
          )}
        />
      
      {touched && error
          ? <div className={styles.textInputWrapper_error}>{error}</div>
          : <></>}
    </div>
  )
})

interface AutocompleteInputProps {
  value: ISelectItem | null,
  id: string,
  items: ISelectItem[],
  subCategoryItems?: Array<ISelectItem>,
  handleChange: (event: any, newValue: ISelectItem | null) => void,
  label?: string,
  error?: string,
  touched?: boolean,
  placeholder?: string,
  disabled?: boolean,
  required?: boolean,
  className?: string,
  inputValue: string, 
  inputItems: ISelectItem[], 
  onInputChange: (event: any, newInputValue: string) => void,
}

export interface ISelectItem {
  title: string,
  value: string,
  id: number | string
}