import React from 'react';
import styles from "./tableHead.module.scss";
import { Order } from '@source/utils/models/adminModels';
import { styled } from '@mui/material/styles';
import { Box, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { StyledTableCell, StyledTableRow } from '../selectOrganizationTable';
import Checkbox from '@source/components/UI/buttons/checkbox/checkbox';
import { visuallyHidden } from '@mui/utils';
import { HeadCellOrganizations, IOrganization } from '@source/api/models/nursingHomeModels';

const StyledTableHead = styled(TableHead)(({ theme }) => ({
    borderBottom: "16px solid #F8FAFF",
}));
  
interface EnhancedTableHeadProps {
    // numSelected: number;
    // onSelectAllClick: (event: any) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
    createSortHandler: (property: keyof IOrganization) => (event: React.MouseEvent<unknown>) => void,
    headCells: HeadCellOrganizations[],
}
  
function EnhancedTableHead(props: EnhancedTableHeadProps) {
    const { order, orderBy, rowCount, createSortHandler, headCells } = props;
  
    return (
      <StyledTableHead className={styles.tableHead}>
        <StyledTableRow>
          {/* <StyledTableCell>
            <Checkbox
              value={"checked"}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
          </StyledTableCell > */}
          {headCells.map((headCell) => (
            <StyledTableCell 
              key={headCell.id}
              align={'left'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </StyledTableCell >
          ))}
        </StyledTableRow>
      </StyledTableHead>
    );
}

export default EnhancedTableHead;