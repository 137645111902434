import { memo, useCallback, useEffect, useState } from "react";
import styles from "./ResetPasswordPage.module.scss";
import * as Yup from "yup";
import { useHistory, useLocation } from "react-router-dom";
import TextInput from "@source/components/UI/inputs/textInput/textInput";
import PasswordInput from "@source/components/UI/inputs/passwordInput/passwordInput";
import Button from "@source/components/UI/buttons/button/button";
import { useFormik } from "formik";
import useFetching from "@source/hooks/useFetching";
import { getHelperItems, LogoType, PasswordValidationRules, ROUTES } from "@source/utils/utils";
import { PasswordVariant } from "@source/components/UI/inputs/passwordInput/passwordHelper/passwordHelper";
import authBackLines from "@source/assets/imgs/authBackLines.png";
import Logo from "@source/components/UI/logo/logo";
import Notification from "@source/components/snackMessage/notification";
import FamilyMemberController from "@source/api/controllers/familyMemberController";
import EmployeeController from "@source/api/controllers/employeeController";
import { AccountType } from "@source/utils/models/userModels";
import done from "@source/assets/imgs/tick.svg";
import Svg from "@components/UI/svg/svg";
import { useFirebaseLogEvent } from "@source/utils/hooks/useFirebaseLogEvent";

const ResetPasswordPage = memo(() => {
  const [resetMember, isLoadingMember, errorMember] = useFetching<ReturnType<typeof FamilyMemberController.resetPassword>>(FamilyMemberController.resetPassword);
  const [resetStaff, isLoadingStaff, errorStaff] = useFetching<ReturnType<typeof EmployeeController.resetForgotPassword>>(EmployeeController.resetForgotPassword);
  const [isReset, setIsReset] = useState(false);
  const router = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [logEvent] = useFirebaseLogEvent();

  useEffect(() => {
    if(!searchParams.get('code') && searchParams.get('email')) {
      new Notification().error('Wrong link, click button in the email again');
      router.push(ROUTES.LoginPage);
    }
  }, [])

  const validateSchema = Yup.object({
    password: Yup.string()
                 .min(6, 'min 9 characters')
                 .max(100, 'max 100 characters')
                 .test('regex', 'Use at least 2 of the following rules', (value: any) => {
                     let rules = 0;
                     (new RegExp(PasswordValidationRules.NUMBER, 'g')).test(value) && rules++;
                     (new RegExp(PasswordValidationRules.UPPER_CHAR, 'g')).test(value) && rules++;
                     (new RegExp(PasswordValidationRules.LOWER_CHAR, 'g')).test(value) && rules++;
                     return (rules >= 2);
                   }),
    confirm: Yup.string()
                .required("Confirm Password is reqired")
                .oneOf([Yup.ref("password"), null], "The passwords do not match"),
  });

  const formik = useFormik({
    validationSchema: validateSchema,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {
      password: '',
      confirm: '',
      code: searchParams.get('code') || '',
      email: searchParams.get('email') || '',
      accountType: searchParams.get('accountType') || '',
    },
    onSubmit: async (formValues) => {
      const requestBody = {
        code: formValues.code,
        email: formValues.email,
        password: formValues.password
      }
      let response;
      if(formValues.accountType === AccountType.FAMILY_MEMBER) {
        response = await resetMember(requestBody);
      }
      else if(formValues.accountType === AccountType.EMPLOYEE) {
        response = await resetStaff(requestBody);
      }  else {
        new Notification().error('Wrong link, click button in the email again: can`t find "account type"');
      }

      if(response) {
        logEvent('forgot_password__reset_password', {
          email: formValues.email,
        })
        new Notification().success('You’re successfully set new password');
        formik.resetForm();
        setIsReset(true);
      } 
    }
  });

  const onGoToLoginClick = useCallback(() => {
    router.push(ROUTES.LoginPage);
  }, [router])

  const {errors, values, touched, handleBlur, handleChange, handleSubmit} = formik;
  return (
    <div className={styles.resetPass}>
      <div className={styles.resetPass__inner}>
        <Logo className={styles.resetPass__inner_logo} type={LogoType.White}/>

        <form className={styles.resetPass__form} onSubmit={handleSubmit}>

          {!isReset
            ? <>
                <div className={styles.resetPass__form_title}>Please create a new password</div>
{/*                 <div className={styles.resetPass__form_subTitle}>Set the new password for your account so you can login and access all the features</div> */}

{/*                 <TextInput 
                  id="email" 
                  label="Email"
                  value={values.email}
                  className={styles.resetPass__form_input}
                  disabled /> */}

                <PasswordInput id="password" label="New password"
                        placeholder="Create new password" 
                        className={styles.resetPass__form_input}
                        value={values.password} 
                        touched={touched.password}
                        error={errors.password}
                        handleChange={handleChange} 
                        handleBlur={handleBlur}
                        helper helperProps={{
                          variant: PasswordVariant.tooltip,
                          toolTipItems: getHelperItems(values.password), 
                          title: 'The password must meet the following rules:'
                        }}/>

                <PasswordInput id="confirm" label="Confirm new password"
                        placeholder="Re-enter your new password"
                        className={styles.resetPass__form_input}
                        value={values.confirm}
                        touched={touched.confirm}
                        error={errors.confirm}
                        handleChange={handleChange} 
                        handleBlur={handleBlur} />

                <Button event="submit" className={styles.resetPass__form_btn} 
                      isLoading={isLoadingMember || isLoadingStaff}>Confirm</Button>
              </>
            : <div className={styles.done}>
                <div className={styles.img}>
                  <Svg src={done} width={32} height={32} color="#fff"/>
                </div>
                <div className={styles.success}>Success!</div>
                <div className={styles.text}>
                  <p>Your password has been changed</p>
                  <p>Please go back to Brenna app and login</p>
                </div>
                {values.accountType === AccountType.FAMILY_MEMBER 
                  ? <></>
/*                     <Button
                      className={styles.backBtn} 
                      event="link"
                      href={process.env.REACT_APP_MOBILE_URL}
                    >Back to Brenna App</Button>  */
                  : <Button
                      onClick={onGoToLoginClick}
                      className={styles.backBtn} 
                    >Back to Brenna app</Button>}
              </div>}
        </form>
      </div>

      <img className={styles.resetPass_back} src={authBackLines} alt="lines"/>
    </div>
  )
})

export default ResetPasswordPage;