import { ICreateAnnouncementReq, IEditAnnouncementReq, IGetAnnouncementDocumentUploadLink, IGetAnnouncementPhotoUploadLink } from "../models/announcementsModels";
import AnnouncementsService from "../services/announcementsService";

export default class AnnouncementsController {
  static async getAnnouncements(nursingHomeId: string) {
    return await AnnouncementsService.getAnnouncements(nursingHomeId);
  }

  static async createAnnouncement(req: FormData) {
    return await AnnouncementsService.createAnnouncement(req);
  }

  static async editAnnouncement(req: IEditAnnouncementReq, announcementId: string) {
    return await AnnouncementsService.editAnnouncement(req, announcementId);
  }

  static async getAnnouncementPhotoUploadLink(req: FormData, announcementId: string) {
    return await AnnouncementsService.getAnnouncementPhotoUploadLink(req, announcementId);
  }

  static async getAnnouncementDocumentUploadLink(req: IGetAnnouncementDocumentUploadLink, announcementId: string) {
    return await AnnouncementsService.getAnnouncementDocumentUploadLink(req, announcementId);
  }
  
  static async deleteAnnouncementPhoto(announcementId: string) {
    return await AnnouncementsService.deleteAnnouncementPhoto(announcementId);
  }

  static async deleteAnnouncementDocument(announcementId: string) {
    return await AnnouncementsService.deleteAnnouncementDocument(announcementId);
  }

  static async deleteAnnouncement(announcementId: string) {
    return await AnnouncementsService.deleteAnnouncement(announcementId);
  }
}