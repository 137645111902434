export interface ILogInRequest {
  email: string,
  password: string
}

export interface IAgencyLogInRequest {
  email: string,
  password: string
}

export interface IAgencyRegisterRequest {
  firstName: string,
  lastName: string,
  registrationNumber: string,
  agencyType: AgencyType,
  rootEmail: string,
  rootPassword: string
}

export interface IAgencyRegisterResponse {
  refreshToken: string,
  accessToken: string,
}


export enum AgencyType {
  ENROLLED = 'enrolled',
  REGISTERED = 'registered',
}

export enum EmployeeType {
  DEFAULT = 'default',
  ROOT_AGENCY = 'root_agency',
  AGENCY = 'agency',
}

export interface IAgencyLogInResponse {
  refreshToken: string,
  accessToken: string,
  action?: string,
  message?: string
}

export interface MFAGenerateResponse {
  authURL: string,
  base32: string
}
export interface MFAVerifyReq {
  code: string,
  ownerEmail: string
}

export interface MFALoginReq {
  email: string,
  password: string,
  code: string
}
export interface ILogInResponse {
  refreshToken: string,
  accessToken: string,
  action?: string,
  message?: string
}

export interface IRegisterResponse {
  
}

export interface IRegisterRequest {
  
}

export interface IChangeAgencyAccountPasswordReq {
  oldPassword: string,
  newPassword: string,
}