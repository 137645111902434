import styles from "./dropdown.module.scss";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useState, useRef, useCallback, memo } from "react";
import arrowBottom from "@source/assets/imgs/arrowBottom.svg";
import useClickAway from "@source/hooks/useClickAway";
import Svg from "@source/components/UI/svg/svg";
import ConfirmModal, { ConfirmModalProps } from "@components/confirmModal/confirmModal";
import LoaderComponent from "@components/loader/loader";

/** Wrap ypur component with Dropdown */
export default memo((props: DropdownProps) => {
  const {items, children, arrow, className, arrowColor, isConfirmLoading, onClick, horizontalAlignPopover = "right"} = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const clickRef = useRef<null | HTMLDivElement>(null);
  const menuRef = useRef<null | HTMLDivElement>(null);

  const open = Boolean(anchorEl);

  useClickAway(clickRef, (e: any) => {
    if(!isConfirmModal && !e.target.contains(menuRef.current)) {
      setAnchorEl(null);
    }
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
    
  const handleClose = useCallback((cb: any, e) => {
    if(items.some((item) => item.isLoading !== undefined)) {
       e.stopPropagation();
       cb()
       return
    }
    if(cb) {
      setAnchorEl(null);
      cb();
      setIsConfirmModal(false);
    } else {
      setIsConfirmModal(true);
    }
  }, []);

  const handleConfirmClose = useCallback(async (item: IDropdownItem) => {
    await item.confirmModalProps?.confirmCb();
    setAnchorEl(null);
  }, []);

  const handleConfirmReject= useCallback(async (item: IDropdownItem) => {
    item.confirmModalProps?.rejectCb && await item?.confirmModalProps?.rejectCb();
    setAnchorEl(null);
  }, []);

  const getItemWrapper = (item: IDropdownItem, key: number, children: JSX.Element) => {
    if(!item.confirmModalProps) {
      return children;
    } else {
      return (
        <ConfirmModal
          key={key}
          {...item?.confirmModalProps}
          confirmLoading={isConfirmLoading}
          confirmCb={() => handleConfirmClose(item)}
          rejectCb={() => handleConfirmReject(item)}
        >
          {children}
        </ConfirmModal>
      )
    }
  }

  return (
    <div onClick={onClick} className={`${styles.dropdown} ${className}`} ref={clickRef}>
      <div className={styles.dropdown__btn} onClick={handleClick}>
        <div className={styles.dropdown__clickZone}>
          {children}
          {arrow 
            ? <div className={styles.dropdown__clickZone_arrow}
                  style={{transform: `${!open ? 'rotate(0)' : 'rotate(180deg)'}`}}>
                <Svg src={arrowBottom} color={arrowColor} 
                  width={10} height={6}/>
              </div>
            : <></>}
        </div>
      </div>

      <Menu open={open} anchorEl={anchorEl} 
        autoFocus={false}
        anchorOrigin={{
          horizontal: horizontalAlignPopover,
          vertical: "bottom"
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: horizontalAlignPopover,
        }}
        ref={menuRef}
        classes={{
          paper: styles.dropdown__menu_paper,
          list: styles.dropdown__menu_list,
        }}
        >
          {items.map((item, index) => (
            getItemWrapper(
              item,
              index,
              <MenuItem key={index} className={item?.className} 
                style={{...item.style, alignItems: 'center'}}
                onClick={(e) => handleClose(item.onClick, e)}
              >
                {item.isLoading ?
                    <LoaderComponent isLoading={item.isLoading}></LoaderComponent>
                    :
                    <></>
                }
                {
                  item.childrenImage && !item.isLoading?
                    item.childrenImage
                    :
                    <></>
                }
                {
                  item.icon && !item.isLoading?
                  <Svg width={20} height={20} src={item.icon} color={item.iconColor || "#333333"}/>
                  :
                  <></>
                }

                {
                  item.text ?
                    <span style={{marginLeft: item.icon || item.childrenImage ? '12px' : ''}}>{item.text}</span>
                    :
                    <></>
                }

                {
                  item.children ?
                    item.children
                    :
                    <></>
                }
                </MenuItem>))
            )
          }
      </Menu>
    </div>
  )
})

interface DropdownProps {
  /** Dropdown target element */
  children: JSX.Element | string,

  /** Dropdown items */
  items: Array<IDropdownItem>,

  /** Is arrow needed
   * @default false
   */
  arrow?: boolean,

  /** Arrow color
   * @default white
  */
  arrowColor?: string,

  /** className for container wrapping Dropdown component */
  className?: string,

  horizontalAlignPopover?: 'left' | 'right',

  isConfirmLoading?: boolean,
  onClick?: any
}

export interface IDropdownItem {
  onClick?: any,
  text?: string | JSX.Element,
  style?: any,
  className?: string,
  icon?: string,
  childrenImage?: JSX.Element,
  iconColor?: string,
  children?: JSX.Element,
  confirmModalProps?: ConfirmModalProps,
  isLoading?: boolean,
}