export enum LogicExceptionList {
  USER_NOT_FOUND = 'user_not_found',
  ACCESS_DENIED = 'access_denied',
  INVALID_VERIFICATION_CODE = 'invalid_verification_code',
  ACCOUNT_IS_NOT_CONFIRMED = 'account_is_not_confirmed',
  EMAIL_ALREADY_EXISTS = 'email_already_exists',
  WRONG_CREDENTIALS = 'wrong_credentials',
  ACCOUNT_ALREADY_CONFIRMED = 'account_already_confirmed',
  NURSING_HOME_NOT_FOUND = 'nursing_home_not_found',
  PERMISSIONS_NOT_FOUND = 'permissions_not_found',
  RESIDENT_NOT_FOUND = 'resident_not_found',
  CATEGORY_NOT_FOUND = 'category_not_found',
  UPDATE_NOT_FOUND = 'update_not_found',
  USER_ISNT_RELATIVE_OF_RESIDENT = 'user_isnt_relative_of_resident',
  UNABLE_TO_DELETE_COMPLETED_UPDATE = 'unable_to_delete_completed_update',
  CHAT_NOT_FOUND = 'chat_not_found',
  USER_CANNOT_INTERACT_WITH_CHAT = 'user_cannot_interact_with_chat',
  CHAT_CLOSED = 'chat_closed',
  CHAT_CANNOT_START = 'chat_cannot_start',
  CHAT_ALREADY_EXISTS = 'chat_already_exists',
  TIMETABLE_EVENT_NOT_FOUND = 'timetable_not_found',
  ACCOUNT_DISABLED = 'account_disabled',
  UNAUTHORIZED = 'unauthorized',
  NOT_ENOUGH_PERMISSIONS = 'not_enough_permissions',
  INVALID_ACCESS_KEY = 'invalid_access_key',
}