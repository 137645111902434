import { Toolbar } from '@mui/material';
import SearchInput from '@source/components/UI/inputs/searchInput/searchInput';
import React, { ChangeEvent } from 'react';
import styles from "./tableToolbar.module.scss";
import { IFillVariant } from '@source/utils/enums';

interface EnhancedTableToolbarProps {
    search: string;
    rowsTotalCount: number,
    onSearchChange: (e: ChangeEvent<HTMLInputElement>) => void,
}
  
const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
    const { search, onSearchChange } = props;
  
    return (
      <Toolbar
        className={styles.tableToolbar}
        sx={{
          padding: '20px 0 20px 14px !important',
          bgcolor: "#ffffff",
        }}
      >
        <div className={styles.filterBar}>
          <SearchInput className={styles.search} value={search}
            onChange={onSearchChange} fill={IFillVariant.Fill} 
            placeHolder="Search"
          />
        </div>
      </Toolbar>
    );
};

export default EnhancedTableToolbar;