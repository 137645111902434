import ReqError from "@source/api/reqError";
import { api } from "@source/api/http";
import { 
  ICloseChatRequest,
  ICreateChatRequest,
  IGetChatRequest,
  IGetChatsRequest,
  INewMessageChatRequest,
  IReopenChatRequest, 
} from "@source/api/models/residentModel";

export default class ChatService {
  static async getChats({nursingHomeId, searchString}: IGetChatsRequest) {
    try {
      return (await api.get(`chat/?nursingHomeId=${nursingHomeId}&searchString=${searchString || ""}`)).data
    } catch (e: any) {
      throw ReqError.getChatError(e.response)
    }
  }

  static async getChat({chatId}: IGetChatRequest) {
    try {
      return (await api.get(`chat/${chatId}`)).data
    } catch (e: any) {
      throw ReqError.getChatError(e.response)
    }
  }

  static async createChat(req: ICreateChatRequest) {
    try {
      return (await api.post(`chat`, {...req})).data
    } catch (e: any) {
      throw ReqError.createChatError(e.response)
    }
  }

  static async closeChat({chatId}: ICloseChatRequest) {
    try {
      return (await api.patch(`chat/${chatId}/close`)).data
    } catch (e: any) {
      throw ReqError.closeChatError(e.response)
    }
  }

  static async reopenChat({chatId}: IReopenChatRequest) {
    try {
      return (await api.patch(`chat/${chatId}/reopen`)).data
    } catch (e: any) {
      throw ReqError.reopenChatError(e.response)
    }
  }

  static async newMessageChat({chatId, body}: INewMessageChatRequest) {
    try {
      return (await api.post(`chat/${chatId}/message`, {body})).data
    } catch (e: any) {
      throw ReqError.newMessageError(e.response)
    }
  }
  INewMessageChatRequest
}