import { memo } from "react";
import styles from "./AccountHasBeenActivatedPage.module.scss";
import { useHistory } from "react-router-dom";
import Button from "@source/components/UI/buttons/button/button";
import { LogoType, ROUTES } from "@source/utils/utils";
import authBackLines from "@source/assets/imgs/authBackLines.png";
import iconAccounHasBeenActivated from "@source/assets/icons/iconAccounHasBeenActivated.svg";
import Logo from "@source/components/UI/logo/logo";

const AccountHasBeenActivatedPage = memo(() => {
  const router = useHistory();

  const onGoToLogin = () => {
    router.push(ROUTES.LoginPage);
  }

  return(
    <div className={styles.accountHasBeenActivated}>
      <div className={styles.accountHasBeenActivated__inner}>
        <Logo className={styles.accountHasBeenActivated__inner_logo} type={LogoType.White}/>

        <div className={styles.accountHasBeenActivated__form}>
          <img className={styles.accountHasBeenActivated__form__icon} src={iconAccounHasBeenActivated} alt="activatedIcon"/>

          <span className={styles.accountHasBeenActivated__form__text}>Your Brenna Account has been activated!</span>

          <Button event="submit" className={styles.accountHasBeenActivated__form_btn} onClick={onGoToLogin}>Go to login</Button>
        </div>
      </div>

      <img className={styles.accountHasBeenActivated_back} src={authBackLines} alt="lines"/>
    </div>
  )
})

export default AccountHasBeenActivatedPage;