import { useHistory } from "react-router-dom";
import styles from "./refreshPageModal.module.scss";
import { observer } from "mobx-react-lite";
import Modal from "@components/UI/modal/modal";
import Button from "@components/UI/buttons/button/button";
import userStore from "@store/userStore";
import {Typography} from "@mui/material";

const RefreshPageModal = observer((props: RefreshModalProps) => {
  const {} = props;
  const router = useHistory();

  const onContinue = () => {
    window.location.reload();
  }
  
  return(
    <Modal open={!!userStore.getRefreshModal()} className={styles.expiredModal} maxWidth={450} close={() => {
      userStore.setRefreshModal(false);
    }}>
      <div className={styles.expiredModalContainer}>
        <div className={styles.content}>
          <div className={styles.textInfo}>
            <span className={styles.title}>New Update Available</span>

            <span className={styles.text}>Please Click Refresh Page to use new features.</span>

            <span className={styles.text}>Close window if you need to refresh later.</span>
          </div>
        </div>

        <Button
            className={styles.continueBtn}
            onClick={onContinue}
        >
          Refresh Page
        </Button>
      </div>
    </Modal>
  )
})

export default RefreshPageModal;

interface RefreshModalProps {

}
