import { Redirect, Route, Switch, useHistory, useLocation} from "react-router-dom";
import { ROUTES, HOME_ROUTES } from '@source/utils/utils';
import React, { Suspense, useEffect } from "react";
import Loader from "@source/components/UI/loader/loader";
import ResidentsTab from "@source/pages/HomePage/homeRouter/residentsTab/residentsTab";
import HistoryTab from "@source/pages/HomePage/homeRouter/historyTab/historyTab";
import SettingsTab from "@source/pages/HomePage/homeRouter/settingsTab/settingsTab";
import residentTab from "@source/pages/HomePage/homeRouter/residentTab/residentTab";
import HistoryChatTab from "@source/pages/HomePage/homeRouter/historyChatTab/historyChatTab";
import AnnouncementsTab from "@source/pages/HomePage/homeRouter/announcementsTab/announcementsTab";
import userStore from "@store/userStore";
import useSocketHome from "@source/hooks/useSocketHome";
import { HomePermissionsEnum } from "@source/api/models/permissionsModels";
import { observer } from "mobx-react-lite";
import StatisticTab from "@pages/HomePage/homeRouter/statisticTab/statisticTab";


/*   const ResidentsTab = React.lazy(() => import('@source/pages/HomePage/homeRouter/residentsTab/residentsTab')); */
/* const HistoryTab = React.lazy(() => import('@source/pages/HomePage/homeRouter/historyTab/historyTab'));
const SettingsTab = React.lazy(() => import('@source/pages/HomePage/homeRouter/settingsTab/settingsTab'));
const residentTab = React.lazy(() => import('@source/pages/HomePage/homeRouter/residentTab/residentTab'));
const HistoryChatTab = React.lazy(() => import('@source/pages/HomePage/homeRouter/historyChatTab/historyChatTab'));
const AnnouncementsTab = React.lazy(() => import('@source/pages/HomePage/homeRouter/announcementsTab/announcementsTab')); */

const NewResidentTab = React.lazy(() => import('@source/pages/HomePage/homeRouter/newResidentTab/newResidentTab'));
const NewFamilyMemberTab = React.lazy(() => import('@source/pages/HomePage/homeRouter/newFamilyMemberTab/newFamilyMemberTab'));
const NewAnnouncementTab = React.lazy(() => import('@source/pages/HomePage/homeRouter/newAnnouncementTab/newAnnouncementTab'));
const NewNursingHomeTab = React.lazy(() => import('@source/pages/HomePage/homeRouter/newNursingHomeTab/newNursingHomeTab'));
const AdminPanelStaffTab = React.lazy(() => import('@source/pages/HomePage/homeRouter/adminPanelStaffTab/adminPanelStaffTab'));
const AdminPanelHousesTab = React.lazy(() => import('@source/pages/HomePage/homeRouter/adminPanelHousesTab/adminPanelHousesTab'));
const AdminPanelAgencyStaffTab = React.lazy(() => import('@source/pages/HomePage/homeRouter/adminPanelAgencyStaff/adminPanelAgencyStaff'));
const NewAgencyAccountTab = React.lazy(() => import('@source/pages/HomePage/homeRouter/newAgencyAccountTab/newAgencyAccountTab'));
const AdminPanelFamilyMembersTab = React.lazy(() => import('@source/pages/HomePage/homeRouter/adminPanelFamilyMembersTab/adminPanelFamilyMembersTab'));
const NewEmployeeTab = React.lazy(() => import('@source/pages/HomePage/homeRouter/newEmployeeTab/newEmployeeTab'));
const UpdateTab = React.lazy(() => import('@source/pages/HomePage/homeRouter/updateTab/updateTab'));
const ResidentsArchiveTab = React.lazy(() => import('@source/pages/HomePage/homeRouter/residentsArchiveTab/residentsArchiveTab'));
const ResidentArchiveTab = React.lazy(() => import('@source/pages/HomePage/homeRouter/residentArchiveTab/residentArchiveTab'));
const HelpTab = React.lazy(() => import('@source/pages/HomePage/homeRouter/helpTab/helpTab'));
const AgencyAccountChangePasswordTab = React.lazy(() => import('@source/pages/HomePage/homeRouter/agencyAccountChangePassword/agencyAccountChangePassword'));
const AgencyAccountForgotPasswordTab = React.lazy(() => import('@source/pages/HomePage/homeRouter/agencyAccountForgotPassword/agencyAccountForgotPassword'));
const AgencyAccountSetPasswordTab = React.lazy(() => import('@source/pages/HomePage/homeRouter/agencyAccountSetPassword/agencyAccountSetPassword'));
const ExportsTab = React.lazy(() => import('@source/pages/HomePage/homeRouter/exportsTab/exportsTab'));

const Routes = [
/*   {path: ROUTES.HomePage, component: ResidentsTab, exact: true}, */

  {path: HOME_ROUTES.Residents, component: ResidentsTab, exact: true},
  {path: HOME_ROUTES.NewResident, component: NewResidentTab, exact: true},
  {path: HOME_ROUTES.EditResident, component: NewResidentTab, exact: true},
  {path: HOME_ROUTES.AddResidentFamilyMembers, component: NewFamilyMemberTab, exact: true},
  {path: HOME_ROUTES.EditResidentFamilyMembers, component: NewFamilyMemberTab, exact: true},
  {path: HOME_ROUTES.Resident, component: residentTab, exact: true},
  {path: HOME_ROUTES.ResidentsArchive, component: ResidentsArchiveTab, exact: true},
  {path: HOME_ROUTES.ResidentArchive, component: ResidentArchiveTab, exact: true},
  {path: HOME_ROUTES.Help, component: HelpTab, exact: true},
  {path: HOME_ROUTES.UpdateDraft, component: UpdateTab, exact: true},
  {path: HOME_ROUTES.Update, component: UpdateTab, exact: true},
  {path: HOME_ROUTES.Chat, component: HistoryChatTab, exact: true},
  {path: HOME_ROUTES.History, component: HistoryTab, exact: false},
  {path: HOME_ROUTES.Settings, component: SettingsTab, exact: false},
  {path: HOME_ROUTES.Announcements, component: AnnouncementsTab, exact: true},
  {path: HOME_ROUTES.NewAnnouncement, component: NewAnnouncementTab, exact: false},
  {path: HOME_ROUTES.NewHome, component: NewNursingHomeTab, exact: false},
  {path: HOME_ROUTES.AdminPanelStaff, component: AdminPanelStaffTab, exact: true},
  {path: HOME_ROUTES.AdminPanelHouses, component: AdminPanelHousesTab, exact: true},
  {path: HOME_ROUTES.AdminPanelAgencyStaff, component: AdminPanelAgencyStaffTab, exact: true},
  {path: HOME_ROUTES.NewAgencyAccount, component: NewAgencyAccountTab, exact: true},
  {path: HOME_ROUTES.AdminPanelFamilyMembers, component: AdminPanelFamilyMembersTab, exact: true},
  {path: HOME_ROUTES.NewEmployee, component: NewEmployeeTab, exact: false},
  {path: HOME_ROUTES.AgencyAccountChangePassword, component: AgencyAccountChangePasswordTab, exact: false},
  {path: HOME_ROUTES.AgencyAccountForgotPassword, component: AgencyAccountForgotPasswordTab, exact: false},
  {path: HOME_ROUTES.AgencyAccountSetPassword, component: AgencyAccountSetPasswordTab, exact: false},
  {path: HOME_ROUTES.InformationExports, component: ExportsTab, exact: true},
  {path: HOME_ROUTES.InformationStats, component: StatisticTab, exact: true},
];

const HomeRouter = observer(() => {
  const location = useLocation();
  const router = useHistory();

  const [onHome] = useSocketHome();

  useEffect(() => {
    onHome();
  }, [])

  const getRedirectPath = () => {
    if (userStore.isPermission(HomePermissionsEnum.VIEW_RESIDENTS)) {
      return HOME_ROUTES.Residents;
    }

    if (userStore.isPermission(HomePermissionsEnum.VIEW_UPDATES)) {
      return HOME_ROUTES.History;
    }

    if (userStore.isPermission(HomePermissionsEnum.VIEW_ANNOUNCEMENT_EVENT)) {
      return HOME_ROUTES.Announcements;
    }

    return HOME_ROUTES.Settings;
  }

  return (
    <Suspense fallback={<Loader full width={48} height={48}/>}>
      <Switch location={location}>
        {Routes.map((route) => {
          if (
            (route.path === HOME_ROUTES.Residents || route.path === HOME_ROUTES.Resident) && 
            (!userStore.isPermission(HomePermissionsEnum.VIEW_RESIDENTS))
          ) {
            return (
              <Route 
                key={route.path} 
                path={route.path} 
                render={(props) => (<Redirect to={{ pathname: getRedirectPath() }} />)}
              />
            )
          }

          if (route.path === HOME_ROUTES.History && !userStore.isPermission(HomePermissionsEnum.VIEW_UPDATES)) {
            return (
              <Route 
                key={route.path} 
                path={route.path} 
                render={(props) => (<Redirect to={{ pathname: getRedirectPath() }} />)}
              />
            )
          }

          if (route.path === HOME_ROUTES.Announcements && !userStore.isPermission(HomePermissionsEnum.VIEW_ANNOUNCEMENT_EVENT)) {
            return (
              <Route 
                key={route.path} 
                path={route.path} 
                render={(props) => (<Redirect to={{ pathname: getRedirectPath() }} />)}
              />
            )
          }

          return <Route {...route} key={route.path}/>
        })}

        <Redirect to={{ pathname: ROUTES.NotFoundPage }} />
      </Switch>
    </Suspense>
  );
});

export default HomeRouter;
